import PropTypes from 'prop-types';
import React from 'react';
import {
  BooleanInput,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextInput,
  translate,
} from 'react-admin';
import { Candidate, CandidateSource, Customer } from '../../../models';
import { AutocompleteInput, ReferenceNameInput } from '../../framework';

const CandidateFiler = ({ permissions, translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="sectors" alwaysOn />
    <TextInput source="sub_sectors" alwaysOn />

    <BooleanInput source="is_top_profile" />
    <TextInput source="department_mobility" />
    <TextInput source="exact_mobility" />
    <TextInput source="professional_positions" />
    <TextInput
      label={translate('resources.candidate_customer.fields.customer_business_name')}
      source="candidateCustomers-dot-customer_business_name"
    />
    {permissions && permissions.canList('customer') && (
      <ReferenceInput
        allowEmpty
        label={translate('resources.candidate_customer.fields.customer_id')}
        reference="customer"
        sort={Customer.sortOrder}
        source="candidateCustomers-dot-customer_id"
      >
        <AutocompleteInput optionText="business_name" />
      </ReferenceInput>
    )}
    {permissions && permissions.canList('candidate_source') && (
      <ReferenceNameInput
        allowEmpty
        filterToQuery={CandidateSource.filterToQuery}
        reference="candidate_source"
        target="name"
        sort={CandidateSource.sortOrder}
        source="source"
      >
        <AutocompleteInput optionText="name" optionValue="name" />
      </ReferenceNameInput>
    )}
    <BooleanInput source="has_driving_license" />
    <SelectInput source="object_state_id" choices={Candidate.OBJECT_STATE_ID.values} />
  </Filter>
);

CandidateFiler.propTypes = {
  permissions: PropTypes.object,
  translate: PropTypes.func,
};

export default translate(CandidateFiler);
