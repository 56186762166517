export default {
  filters: {
    equals: 'Égal à',
    greaterThan: 'Supérieur à',
    lowerThan: 'Inférieur à',
    from: 'De',
    to: 'À',
    from_date: 'Depuis',
    to_date: "Jusqu'au",
  },
  autocomplete: {
    no_options: "Pas d'option disponible",
    loading: 'Chargement ...',
    create: 'Créer "%{name}"',
  },
  sex: {
    mrs: 'Madame',
    mr: 'Monsieur',
    lawyer: 'Maître',
    doctor: 'Docteur',
  },
};
