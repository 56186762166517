import PropTypes from 'prop-types';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Filter,
  NumberInput,
  ReferenceInput,
  SelectInput,
  translate,
} from 'react-admin';
import { Candidate, CandidateOrder } from '../../../models';

const CandidateOrderFitler = ({ permissions, translate, ...props }) => (
  <Filter {...props}>
    <NumberInput source="order_id" alwaysOn />
    {permissions && permissions.canList('candidate') && (
      <ReferenceInput source="candidate_id" reference="candidate" alwaysOn>
        <AutocompleteInput optionText={Candidate.getFullName} />
      </ReferenceInput>
    )}
    {permissions && permissions.canList('customer') && (
      <ReferenceInput
        alwaysOn
        label={translate('resources.customer.fields.business_name', 1)}
        reference="customer"
        source="order-dot-customer_id"
      >
        <AutocompleteInput optionText="business_name" />
      </ReferenceInput>
    )}
    <SelectInput source="object_state_id" choices={CandidateOrder.OBJECT_STATE_ID.values} />
    <BooleanInput
      label={translate('resources.candidate.fields.is_top_profile')}
      source="candidate-dot-is_top_profile"
    />
  </Filter>
);

CandidateOrderFitler.propTypes = {
  permissions: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

export default translate(CandidateOrderFitler);
