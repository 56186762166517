import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  FileField,
  FileInput,
  LongTextInput,
  ReferenceInput,
  Responsive,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  withDataProvider,
} from 'react-admin';
import compose from 'recompose/compose';
import { Candidate, Degree, ProfessionalPosition, Tag } from '../../../models';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { DateTime } from '../../../utils/datetime';
import { candidateValidators } from '../../../validators';
import { ObjectStateStepper } from '../../ObjectState';
import {
  AutocompleteInput,
  BlueBooleanInput,
  CustomCreateActions,
  EmptyField,
  ExternalReferenceArrayInput,
  PhoneMaskTextInput,
  ReferenceArrayNameInput,
  RepeatedStringInput,
  SexInput,
  TagListAutocompleteInput,
  ThousandsNumberInput,
  Toolbar,
} from '../../framework';
import { asyncValidateEmail, asyncValidatePhone } from '../asyncValidate';

class CandidateCreateForm extends React.Component {
  /**
   * Hack async validation process to show warning if phone number is already used.
   * @param {object} values the form values.
   * @param {function} dispatch the state dispatch method.
   * @param {object} props the redux form properties.
   * @param {string} blurredField the field being edited
   * @return {Promise}
   */
  asyncValidate = (values, dispatch, props, blurredField) => {
    return Promise.all([
      asyncValidatePhone(this.props, values, dispatch, props, blurredField),
      asyncValidateEmail(this.props, values, dispatch, props, blurredField),
    ]);
  };

  render() {
    const { dataProvider: _, dispatch: __, theme, ...props } = this.props;
    const { permissions } = this.props;

    return (
      <Create
        {...props}
        actions={<CustomCreateActions />}
        className="candidate"
        redirect={REDIRECT_AFTER_CREATE}
      >
        <SimpleForm
          asyncValidate={this.asyncValidate}
          className="form-inline"
          toolbar={<Toolbar />}
        >
          <Responsive
            small={
              <ObjectStateStepper
                choices={Candidate.OBJECT_STATE_ID.values}
                orientation="vertical"
                readOnly
                step={Candidate.OBJECT_STATE_ID.default}
                source="object_state_id"
              />
            }
            medium={
              <ObjectStateStepper
                choices={Candidate.OBJECT_STATE_ID.values}
                readOnly
                step={Candidate.OBJECT_STATE_ID.default}
                source="object_state_id"
              />
            }
            formClassName="ra-input__noflex"
            fullWidth
          />

          <SexInput
            formClassName="ra-input__flex-25"
            source="title"
            validate={candidateValidators.title}
          />
          <TextInput
            formClassName="ra-input__flex-25"
            source="last_name"
            validate={candidateValidators.last_name}
          />
          <TextInput
            formClassName="ra-input__flex-50"
            source="first_name"
            validate={candidateValidators.first_name}
          />
          <RepeatedStringInput
            formClassName="ra-input__flex-25"
            source="phones"
            validate={candidateValidators.phones}
          >
            <PhoneMaskTextInput />
          </RepeatedStringInput>
          <RepeatedStringInput
            formClassName="ra-input__flex-25"
            source="emails"
            validate={candidateValidators.emails}
          />
          <TagListAutocompleteInput
            formClassName="ra-input__flex-25"
            source="sectors"
            type={Tag.TYPE.SECTOR}
            validate={candidateValidators.sectors}
          />
          <TagListAutocompleteInput
            formClassName="ra-input__flex-25"
            source="sub_sectors"
            type={Tag.TYPE.SUB_SECTOR}
            validate={candidateValidators.sub_sectors}
          />
          <SelectInput
            allowEmpty
            choices={Candidate.DRIVING_LICENCE_ENUM.values}
            formClassName="ra-input__flex-25"
            source="has_driving_license"
            validate={candidateValidators.has_driving_license}
          />
          <SelectInput
            allowEmpty
            choices={Candidate.VEHICULE_ENUM.values}
            formClassName="ra-input__flex-25"
            source="vehicule"
            validate={candidateValidators.vehicule}
          />
          <BooleanInput
            formClassName="ra-input__flex-25 ra-input__v-align"
            source="registred_job_center"
            validate={candidateValidators.registred_job_center}
          />
          <BooleanInput
            formClassName="ra-input__flex-25 ra-input__v-align"
            source="is_top_profile"
            validate={candidateValidators.is_top_profile}
          />
          {permissions && permissions.canList('degree') ? (
            <ReferenceInput
              allowEmpty
              formClassName="ra-input__flex-25"
              filterToQuery={Degree.filterToQuery}
              reference="degree"
              sort={Degree.sortOrder}
              source="diploma"
              validate={candidateValidators.diploma}
            >
              <AutocompleteInput optionText="name" optionValue="name" />
            </ReferenceInput>
          ) : (
            <EmptyField source="diploma" />
          )}
          {permissions && permissions.canList('professional_position') ? (
            <ReferenceArrayNameInput
              allowEmpty
              filterToQuery={ProfessionalPosition.filterToQuery}
              formClassName="ra-input__flex-25"
              reference="professional_position"
              target="name"
              sort={ProfessionalPosition.sortOrder}
              source="professional_positions"
              validate={candidateValidators.professional_positions}
            >
              <AutocompleteInput isMulti optionText="name" optionValue="name" />
            </ReferenceArrayNameInput>
          ) : (
            <EmptyField formClassName="ra-input__flex-25" source="professional_positions" />
          )}

          <ThousandsNumberInput
            formClassName="ra-input__flex-25"
            source="salary_min"
            validate={candidateValidators.salary_min}
          />
          <BlueBooleanInput
            formClassName="ra-input__flex-25 ra-input__v-align"
            source="is_coach_job"
            validate={candidateValidators.is_coach_job}
          />
          <ExternalReferenceArrayInput
            allowEmpty
            apiUrl={Candidate.GEO_GOUV_API}
            displayInvalidValues
            filterToQuery={Candidate.departmentFilterToQuery}
            filterResults={Candidate.filterUniqueTowns}
            formClassName="ra-input__flex-25"
            reference="departements"
            target="nom"
            targetId="code"
            source="department_mobility"
            sort={Candidate.departmentSortOrder}
            validate={candidateValidators.department_mobility}
          >
            <AutocompleteInput optionText="nom" optionValue="nom" />
          </ExternalReferenceArrayInput>
          <ExternalReferenceArrayInput
            allowEmpty
            apiUrl={Candidate.GEO_GOUV_API}
            displayInvalidValues
            filterToQuery={Candidate.townFilterToQuery}
            filterResults={Candidate.filterUniqueTowns}
            formClassName="ra-input__flex-25"
            reference="communes"
            target="nom"
            targetId="code"
            source="exact_mobility"
            sort={Candidate.townSortOrder}
            validate={candidateValidators.exact_mobility}
          >
            <AutocompleteInput optionText="nom" optionValue="nom" />
          </ExternalReferenceArrayInput>
          <LongTextInput
            formClassName="ra-input__flex-25"
            source="mobility_note"
            validate={candidateValidators.mobility_note}
          />
          <LongTextInput
            formClassName="ra-input__flex-25"
            source="coach_job_comment"
            validate={candidateValidators.coach_job_comment}
          />
          <ArrayInput
            className={`array-input__simple-form-iterator theme-${theme.palette.type}`}
            formClassName="ra-input__flex-50"
            fullWidth
            source="comments"
          >
            <SimpleFormIterator>
              <DateInput
                defaultValue={new DateTime().toDateString()}
                label="resources.candidate.fields.comment.date"
                source="date"
                validate={candidateValidators.comment.date}
              />
              <LongTextInput
                label="resources.candidate.fields.comment.note"
                source="note"
                validate={candidateValidators.comment.note}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <FileInput
            formClassName="ra-input__flex-50"
            fullWidth
            multiple
            source="upload_files"
            validate={candidateValidators.upload_files}
          >
            <FileField source="src" target="_blank" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
    );
  }
}

CandidateCreateForm.propTypes = {
  theme: PropTypes.object.isRequired,
};

const enhance = compose(withTheme(), withDataProvider);

export const CandidateCreate = enhance(CandidateCreateForm);
