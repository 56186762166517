import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';

/**
 * Display switcher for user membership.
 * @param {*} props parameters for current datagrid field.
 */
class UserEditRoleMembershipButton extends React.Component {
  /**
   * Handle switch update.
   */
  onChange = () => {
    const { record } = this.props;
    this.props.handleChange(record.id);
  };

  render() {
    const { canAdd, canRemove, record, source, translate } = this.props;
    const checked = get(record, source);

    const disabled = (checked && !canRemove) || (!checked && !canAdd);

    return (
      <Tooltip
        title={translate(
          checked ? 'form.user.role.remove_membership' : 'form.user.role.add_membership',
          { name: record.name }
        )}
      >
        <Switch
          checked={checked}
          onChange={this.onChange}
          value={`${record.id}`}
          color="primary"
          disabled={disabled}
        />
      </Tooltip>
    );
  }
}

UserEditRoleMembershipButton.propTypes = {
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

UserEditRoleMembershipButton.defaultProps = {
  checked: true,
};

export default translate(UserEditRoleMembershipButton);
