import PropTypes from 'prop-types';
import React from 'react';
import { BooleanInput, DateField, FormTab, TabbedForm, TextInput } from 'react-admin';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { user } from '../../../validators';
import { CustomEditActions, EditWith, PageTitle, Toolbar } from '../../framework';
import UserEditRoleTab from './role/UserEditRoleTab';

export const UserEdit = ({ permissions, ...props }) => (
  <EditWith
    actions={<CustomEditActions />}
    title={<PageTitle {...props} />}
    undoable={false}
    relations="roles"
    {...props}
  >
    <TabbedForm redirect={REDIRECT_AFTER_EDIT} toolbar={<Toolbar />}>
      <FormTab label="form.user.tabs.general">
        <TextInput source="name" validate={user.name} />
        <TextInput source="email" validate={user.email} />
        <TextInput source="password" validate={user.password.update} type="password" />
        <TextInput source="repassword" validate={user.password.update} type="password" />
        <BooleanInput source="enabled" validate={user.enabled} />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </FormTab>

      {permissions && permissions.canList('role') && (
        <UserEditRoleTab label="form.user.tabs.roles" path="role" permissions={permissions} />
      )}
    </TabbedForm>
  </EditWith>
);

UserEdit.propTypes = {
  permissions: PropTypes.object,
};
