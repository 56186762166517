import {
  CREATE,
  crudGetOne as reactAdminCrudGetOne,
  CRUD_CREATE,
  CRUD_DELETE,
  CRUD_GET_MANY,
  CRUD_GET_ONE,
  CRUD_UPDATE,
  DELETE,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import set from 'lodash/set';
import { CREATE_MANY } from '.';

/**
 * Create a new object.
 * @param {string} resource the resource type.
 * @param {object} data the resource data.
 * @param {string} basePath the base path.
 * @param {string|bool} redirectTo the screen to redirect to, or `false` to not redirect
 * @param {bool} refreshSuccess refresh view after creation sucess.
 */
export const crudCreate = (
  resource,
  data,
  basePath,
  redirectTo = 'edit',
  refreshSuccess = false,
  options = {}
) => ({
  type: CRUD_CREATE,
  payload: { data },
  meta: {
    resource,
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: options.notification_success || 'notification.created_many',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo,
      refresh: refreshSuccess,
      basePath,
      ...(options.callbackSuccess ? { callback: options.callbackSuccess } : {}),
    },
    onFailure: {
      notification: {
        body: options.notification_failure || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

export const crudCreateMany = (
  resource,
  items,
  basePath,
  redirectTo = 'edit',
  refreshSuccess = false,
  options = {}
) => ({
  type: CRUD_CREATE,
  payload: {
    data: { objects: items },
  },
  meta: {
    resource,
    fetch: CREATE_MANY,
    onSuccess: {
      notification: {
        body: options.notification_success || 'ra.notification.created',
        level: 'info',
        messageArgs: {
          smart_count: items.length,
        },
      },
      redirectTo,
      refresh: refreshSuccess,
      basePath,
      ...(options.callbackSuccess ? { callback: options.callbackSuccess } : {}),
    },
    onFailure: {
      notification: {
        body: options.notification_failure || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

/**
 * Load an object from its ID.
 * Same behavior as react-admin crudGetOne, but handle callback.
 * @param {string} resource the resource name.
 * @param {string} id the ID of the object to load.
 * @param {string} basePath the base path.
 * @param {boolean} refresh refresh after load or not.
 * @param {object} options options for the action.
 */
export const crudGetOne = (resource, id, basePath, refresh = true, options = {}) => {
  const action = Object.assign({}, reactAdminCrudGetOne(resource, id, basePath, refresh));

  if (options.callbackSuccess) {
    set(action, 'meta.onSuccess.callback', options.callbackSuccess);
  }

  return action;
};

/**
 * Load an object from its ID, with related objects.
 * @param {string} resource the resource name.
 * @param {string} id the ID of the object to load.
 * @param {string|Array} relations the relations to get with the object.
 * @param {string|Array} methods the methods to call with the object.
 * @param {object} methods parameters for methods to call with the object.
 * @param {string|Array} count the number of results from a relationship.
 * @param {string} basePath the base path.
 * @param {object} options options for the action.
 */
export const crudGetOneWith = (
  resource,
  id,
  relations,
  methods,
  methodsParams,
  count,
  basePath,
  options = {}
) => ({
  type: CRUD_GET_ONE,
  payload: {
    id,
    data: {
      with: JSON.stringify(!Array.isArray(relations) && relations ? [relations] : relations),
      with_methods: JSON.stringify(!Array.isArray(methods) && methods ? [methods] : methods),
      method_parameters:
        methodsParams && Object.keys(methodsParams).length > 0
          ? JSON.stringify(methodsParams)
          : null,
      with_count: JSON.stringify(!Array.isArray(count) && count ? [count] : count),
    },
  },
  meta: {
    resource,
    fetch: GET_ONE,
    basePath,
    ...(options.callbackSuccess ? { onSuccess: { callback: options.callbackSuccess } } : {}),
    onFailure: {
      notification: {
        body: 'ra.notification.item_doesnt_exist',
        level: 'warning',
      },
      redirectTo: 'list',
      refresh: true,
    },
  },
});

/**
 * Delete an object.
 * @param {string} resource the resource type.
 * @param {string} id the resource ID.
 * @param {object} previousData the resource data.
 * @param {string} basePath the base path.
 * @param {string|bool} redirectTo the screen to redirect to, or `false` to not redirect
 * @param {bool} refreshSuccess refresh view after deletion sucess.
 */
export const crudDelete = (
  resource,
  id,
  previousData,
  basePath,
  redirectTo = 'list',
  refreshSuccess = false,
  options = {}
) => ({
  type: CRUD_DELETE,
  payload: { id, previousData },
  meta: {
    resource,
    fetch: DELETE,
    onSuccess: {
      notification: {
        body: options.notification_success || 'ra.notification.deleted',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo,
      refresh: refreshSuccess,
      basePath,
    },
    onFailure: {
      notification: {
        body: options.notification_failure || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

/**
 * Load a list of objects from their IDs, with related objects.
 * @param {string} resource the resource type.
 * @param {string|number|array} ids a single resource id or a list of resources ids.
 * @param {array} relations the relations to get with the objects.
 * @param {string|Array} methods the methods to call with the object.
 * @param {object} options options for the action.
 */
export const crudGetManyWith = (
  resource,
  ids,
  relations,
  methods,
  methodsParams,
  count,
  options = {}
) => ({
  type: CRUD_GET_MANY,
  payload: {
    ids: !Array.isArray(ids) && ids ? [ids] : ids,
    withCount: !Array.isArray(count) && count ? [count] : count,
    withMethods: !Array.isArray(methods) && methods ? [methods] : methods,
    withMethodsParams:
      methodsParams && Object.keys(methodsParams).length > 0 ? JSON.stringify(methodsParams) : null,
    withRelations: !Array.isArray(relations) && relations ? [relations] : relations,
  },
  meta: {
    resource,
    fetch: GET_MANY,
    ...(options.callbackSuccess ? { onSuccess: { callback: options.callbackSuccess } } : {}),
    onFailure: {
      notification: {
        body: 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

/**
 * Update an object.
 * @param {string} resource the resource type.
 * @param {string} id the resource ID.
 * @param {object} data the data to save.
 * @param {object} previousData the resource data.
 * @param {string} basePath the base path.
 * @param {string|bool} redirectTo the screen to redirect to, or `false` to not redirect
 * @param {bool} refreshSuccess refresh view after update sucess.
 * @param {*} options options for the action.
 */
export const crudUpdate = (
  resource,
  id,
  data,
  previousData,
  basePath,
  redirectTo = 'show',
  refreshSuccess = true,
  options = {}
) => ({
  type: CRUD_UPDATE,
  payload: { id, data, previousData },
  meta: {
    resource,
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: options.notification_success || 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo,
      basePath,
      refresh: refreshSuccess,
      ...(options.callbackSuccess ? { callback: options.callbackSuccess } : {}),
    },
    onFailure: {
      notification: {
        body: options.notification_failure || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});
