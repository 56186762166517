import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateField,
  FormTab,
  ReferenceManyField,
  Responsive,
  TextField,
  translate,
} from 'react-admin';
import { PermissionManager } from '../../../data-provider';
import { Order } from '../../../models';
import { BooleanField } from '../../framework';
import { ObjectStateSelect } from '../../ObjectState';
import { MobileOrderList } from '../../Order/OrderList/OrderList';

const CustomerNameField = ({ customer, ...props }) => (
  <TextField {...props} source="business_name" record={customer} />
);

CustomerNameField.propTypes = {
  customer: PropTypes.object,
  label: PropTypes.string,
};
CustomerNameField.defaultProps = {
  label: 'resources.order.fields.customer_id',
};

const CustomerEditOrdersTab = ({ translate, ...props }) => {
  const { record: customer } = props;
  const permissions = new PermissionManager();

  return (
    <FormTab {...props}>
      <ReferenceManyField
        fullWidth
        label={translate('resources.order.name', 1)}
        reference="order"
        target="customer_id"
      >
        <Responsive
          small={<MobileOrderList />}
          medium={
            <Datagrid rowClick={permissions.canEdit('order') ? 'edit' : undefined}>
              <TextField source="id" />
              <ObjectStateSelect
                source="object_state_id"
                choices={Order.ORDER_STATE_ID.values}
                readOnly
              />
              <DateField source="ordered_at" />
              <TextField source="job_title" />
              <CustomerNameField customer={customer} />
              <BooleanField source="offer_registred" />
            </Datagrid>
          }
        />
      </ReferenceManyField>
    </FormTab>
  );
};

CustomerEditOrdersTab.propTypes = {
  hasEdit: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.object,
  permissions: PropTypes.object,
  record: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

CustomerEditOrdersTab.defaultTypes = {
  label: 'form.customer.tabs.orders',
};

export default translate(CustomerEditOrdersTab);
