import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  Create,
  DateInput,
  LongTextInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { CandidateSource, Customer, ProfessionalPosition, Tag } from '../../../models';
import { User } from '../../../models/user';
import { candidateCustomer } from '../../../validators';
import {
  AutocompleteInput,
  CustomCreateActions,
  DateTimeInput,
  EmptyField,
  ReferenceNameInput,
  TagAutocompleteInput,
  Toolbar,
} from '../../framework';

const CandidateCustomerCreate = ({ permissions, ...props }) => {
  const { location } = props;

  const candidateId = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('candidate_id');
  }, [location]);

  /**
   * Get url to redirect to after creating a record.
   * @param {String} basePath
   * @param {Number} id
   * @param {Object} data the record data.
   */
  const redirect = useCallback((basePath, id, data) => {
    return `/candidate/${data.candidate_id}/candidate-customers`;
  }, []);

  const defaultValues = useMemo(
    () => ({
      candidate_id: parseInt(candidateId) || undefined,
      candidacy_date: new Date().toISOString().split('T')[0],
    }),
    [candidateId]
  );

  return (
    <Create {...props} actions={<CustomCreateActions />} className="candidate_customer">
      <SimpleForm
        className="form-inline"
        defaultValue={defaultValues}
        redirect={redirect}
        toolbar={<Toolbar />}
      >
        <DateInput
          formClassName="ra-input__flex-25"
          source="candidacy_date"
          validate={candidateCustomer.candidacy_date}
        />

        {permissions && permissions.canList('candidate_source') ? (
          <ReferenceNameInput
            allowEmpty
            filterToQuery={CandidateSource.filterToQuery}
            formClassName="ra-input__flex-75"
            reference="candidate_source"
            target="name"
            sort={CandidateSource.sortOrder}
            source="source"
            validate={candidateCustomer.source}
          >
            <AutocompleteInput optionText="name" optionValue="name" />
          </ReferenceNameInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-75" source="source" />
        )}

        <TextInput
          formClassName="ra-input__flex-25"
          source="customer_business_name"
          validate={candidateCustomer.customer_business_name}
        />

        {permissions && permissions.canList('customer') ? (
          <ReferenceInput
            allowEmpty
            formClassName="ra-input__flex-25"
            reference="customer"
            sort={Customer.sortOrder}
            source="customer_id"
            validate={candidateCustomer.customer_id}
          >
            <AutocompleteInput optionText="business_name" />
          </ReferenceInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="customer_id" />
        )}

        {permissions && permissions.canList('professional_position') ? (
          <ReferenceNameInput
            allowEmpty
            filterToQuery={ProfessionalPosition.filterToQuery}
            formClassName="ra-input__flex-25"
            reference="professional_position"
            target="name"
            sort={ProfessionalPosition.sortOrder}
            source="job_title"
            validate={candidateCustomer.job_title}
          >
            <AutocompleteInput optionText="name" optionValue="name" />
          </ReferenceNameInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="job_title" />
        )}

        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="job_location"
          type={Tag.TYPE.LOCATION}
          validate={candidateCustomer.job_location}
        />

        <DateTimeInput
          formClassName="ra-input__flex-25"
          source="sms_date"
          validate={candidateCustomer.sms_date}
        />

        <DateTimeInput
          formClassName="ra-input__flex-25"
          source="phone_meeting_date"
          validate={candidateCustomer.phone_meeting_date}
        />

        {permissions && permissions.canList('user') ? (
          <ReferenceInput
            allowEmpty
            filterToQuery={User.filterToQuery}
            formClassName="ra-input__flex-25"
            reference="user"
            sort={User.sortOrder}
            source="user_id"
            validate={candidateCustomer.user_id}
          >
            <AutocompleteInput source="name" />
          </ReferenceInput>
        ) : (
          <EmptyField
            formClassName="ra-input__flex-25"
            source="user_id"
            validate={candidateCustomer.user_id}
          />
        )}

        <LongTextInput
          formClassName="ra-input__flex-50"
          source="comment"
          validate={candidateCustomer.comment}
        />
      </SimpleForm>
    </Create>
  );
};

CandidateCustomerCreate.propTypes = {
  permissions: PropTypes.object,
};

export default CandidateCustomerCreate;
