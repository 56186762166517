const ACTIONS_SORT_ORDER = Object.freeze({
  get: 0,
  post: 1,
  put: 2,
  delete: 3,
});
const ACTION_SORT_ORDER_DEFAULT = Math.max(Object.values(ACTIONS_SORT_ORDER)) + 1;

export class Permission {
  static ACTIONS = Object.keys(ACTIONS_SORT_ORDER);

  /**
   * Sort actions for display.
   * @param {array} actions the actions to sort.
   */
  static sortActions = actions =>
    actions.sort(
      (a, b) =>
        (a in ACTIONS_SORT_ORDER ? ACTIONS_SORT_ORDER[a] : ACTION_SORT_ORDER_DEFAULT) -
        (b in ACTIONS_SORT_ORDER ? ACTIONS_SORT_ORDER[b] : ACTION_SORT_ORDER_DEFAULT)
    );
}
