import castArray from 'lodash/castArray';
import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput, ReferenceArrayNameInput } from '.';
import { PermissionManager } from '../../../data-provider';

const sort = { field: 'name', order: 'ASC' };
const filterToQuery = searchText => ({ name: searchText });

class PureTagListAutocomplete extends React.PureComponent {
  /**
   * Parse a list of strings into a list of objects, to be compatible with AutocompleteInput.
   * @param {array} value the initial value.
   * @returns {array} a list of values readable by AutocompleteInput.
   */
  format = value =>
    castArray(value)
      .filter(Boolean)
      .map(item => ({ [this.props.optionText]: item }));

  /**
   * Parse a list of Objects into a list of strings, to be compatible with model.
   * @param {array} value the AutocompleteInput value.
   * @returns {array} a list of values readable by model.
   */
  parse = value =>
    castArray(value)
      .filter(Boolean)
      .map(item => item[this.props.optionValue])
      .filter(Boolean);

  render() {
    const { optionText, optionValue, type, ...rest } = this.props;
    const filter = type ? { '=type': type } : undefined;
    const targetFilter = { type };

    const permissions = new PermissionManager();
    if (!permissions.canList(rest.reference)) {
      return null;
    }

    return (
      <ReferenceArrayNameInput
        {...rest}
        filter={filter}
        filterToQuery={filterToQuery}
        format={this.format}
        parse={this.parse}
        sort={sort}
        target={optionValue}
        targetFilter={targetFilter}
      >
        <AutocompleteInput creatable isMulti optionValue={optionValue} optionText={optionText} />
      </ReferenceArrayNameInput>
    );
  }
}

const TagListAutocompleteInput = PureTagListAutocomplete;

TagListAutocompleteInput.propTypes = {
  optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  reference: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  type: PropTypes.string,
};

TagListAutocompleteInput.defaultProps = {
  optionText: 'name',
  optionValue: 'name',
  reference: 'tag',
};

export default TagListAutocompleteInput;
