// https://github.com/marmelab/react-admin/pull/871#issuecomment-315994051
import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE, REDUX_FORM_NAME } from 'react-admin';
import { stopSubmit } from 'redux-form';
import { put, takeEvery } from 'redux-saga/effects';

function extractViolations(err) {
  return (
    err &&
    err.fields &&
    Object.keys(err.fields).reduce((acc, key) => ({ ...acc, [key]: err.fields[key][0] }), {})
  );
}

export default function* errorSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudFailure);
}

export function* crudFailure(action) {
  var json = action.payload;

  // The extractViolations function searches in the response's json
  // all the violation and output a json structure looking like this:
  // {
  //     age: "You must be at least 13 years old to use this service",
  //     username: "This username is already taken"
  // }
  const violations = extractViolations(json);
  if (violations) {
    yield put(stopSubmit(REDUX_FORM_NAME, violations));
  }
}
