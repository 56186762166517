import React from 'react';
import { TextInput } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

/**
 * Display text input with search icon as adornment.
 * @param {*} props text input properties.
 * @see SearchInput in react-admin.
 */
const TextSearchInput = props => (
  <TextInput
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export default TextSearchInput;
