import React from 'react';
import { Filter } from 'react-admin';
import { TextSearchInput } from '../../framework';

/**
 * Filters for roles list.
 * @param {*} props filters options
 */
const RoleFilter = props => (
  <Filter {...props}>
    <TextSearchInput source="name" alwaysOn />
  </Filter>
);

export default RoleFilter;
