import React from 'react';
import { SelectInput } from 'react-admin';
import { sex } from '../../../settings';

const choices = [
  { value: sex.MRS, label: 'input.sex.mrs' },
  { value: sex.MR, label: 'input.sex.mr' },
  { value: sex.LAWYER, label: 'input.sex.lawyer' },
  { value: sex.DOCTOR, label: 'input.sex.doctor' },
];

const SexInput = props => (
  <SelectInput choices={choices} optionText="label" optionValue="value" {...props} />
);

export default SexInput;
