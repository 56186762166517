import { maxLength, number } from 'react-admin';
import { date, datetime, notEmpty } from './validators';

export default {
  candidate_id: [notEmpty(), number()],
  customer_id: [number()],
  user_id: [number()],
  candidacy_date: [notEmpty(), date()],
  customer_business_name: [notEmpty()],
  job_title: [notEmpty()],
  job_location: [notEmpty()],
  source: [notEmpty(), maxLength(64)],
  sms_date: [notEmpty(), datetime()],
  phone_meeting_date: [datetime()],
  comment: [],
};
