import MuiAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Children, cloneElement, useMemo } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { TALENTO_LOGO } from '../../settings';
import CustomUserMenu from './CustomUserMenu';

import { Headroom, LoadingIndicator, toggleSidebar as toggleSidebarAction } from 'react-admin';

const styles = theme =>
  createStyles({
    logo: {
      height: 40,
    },
    logoWrapper: {
      width: 'calc(240px - 1em - 5px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoWrapperCollapsed: {
      marginLeft: '1em',
      width: 'unset',
    },
    // style retrieved from react-admin AppBar.js
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  });

const CustomAppBar = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  sidebarOpen,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => {
  const isLogoVisible = useMemo(() => width !== 'xs', [width]);

  return (
    <Headroom>
      <MuiAppBar className={className} color="secondary" position="static" {...rest}>
        <Toolbar
          disableGutters
          variant={width === 'xs' ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          {isLogoVisible && (
            <div
              className={classNames(
                classes.logoWrapper,
                !sidebarOpen && classes.logoWrapperCollapsed
              )}
            >
              <img alt="logo" className={classes.logo} src={TALENTO_LOGO} />
            </div>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            className={classNames(classes.menuButton)}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          {Children.count(children) === 0 ? (
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <LoadingIndicator />
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
    </Headroom>
  );
};

CustomAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

CustomAppBar.defaultProps = {
  userMenu: <CustomUserMenu />,
};

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
      sidebarOpen: state.admin.ui.sidebarOpen,
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles),
  withWidth()
);
export default enhance(CustomAppBar);
