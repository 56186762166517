import React from 'react';
import { BooleanInput, Filter, SearchInput, SelectInput } from 'react-admin';
import { Customer } from '../../../models';
import { TextSearchInput } from '../../framework';

export const CustomerFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput alwaysOn choices={Customer.OBJECT_STATE_ID.values} source="object_state_id" />
    <TextSearchInput source="business_name" />
    <TextSearchInput source="parent_company_name" />
    <TextSearchInput source="name" />
    <TextSearchInput source="siret" />
    <TextSearchInput source="domains" />
    <BooleanInput source="use_deposit" />
  </Filter>
);
