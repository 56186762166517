const OBJECT_STATE_ID = Object.freeze({
  WAITING: 0,
  TALENTO_INTERVIEW: 1,
  TALENTO_REVIEW: 2,
  REFUSED_TALENTO: 3,
  SENT_CUSTOMER: 4,
  CUSTOMER_INTERVIEW_PENDING: 5,
  REFUSED_CUSTOMER: 6,
  CANDIDATE_CANCEL: 7,
  DONE: 8,
  INVOICED: 9,
  CLOSED: 10,
});

const DATE_TO_STATUS = Object.freeze({
  real_meeting_date: OBJECT_STATE_ID.TALENTO_INTERVIEW,
  post_meeting_date: OBJECT_STATE_ID.TALENTO_REVIEW,
  customer_file_sent_date: OBJECT_STATE_ID.SENT_CUSTOMER,
  customer_interview_date: OBJECT_STATE_ID.CUSTOMER_INTERVIEW_PENDING,
  invoice_sent_at: OBJECT_STATE_ID.INVOICED,
});

export class CandidateOrder {
  static DEFAULT_VALUES = {
    object_state_id: 0,
  };

  static OBJECT_STATE_ID = Object.freeze({
    values: [
      {
        id: OBJECT_STATE_ID.WAITING,
        name: 'form.candidate_order.state.waiting',
        color: 'misty',
      },
      {
        id: OBJECT_STATE_ID.TALENTO_INTERVIEW,
        name: 'form.candidate_order.state.talento_interview',
        color: 'astrogranite',
      },
      {
        id: OBJECT_STATE_ID.TALENTO_REVIEW,
        name: 'form.candidate_order.state.talento_review',
        color: 'mattBlack',
      },
      {
        id: OBJECT_STATE_ID.REFUSED_TALENTO,
        name: 'form.candidate_order.state.refused_talento',
        color: 'salmon',
      },
      {
        id: OBJECT_STATE_ID.SENT_CUSTOMER,
        name: 'form.candidate_order.state.sent_customer',
        color: 'summer',
      },
      {
        id: OBJECT_STATE_ID.CUSTOMER_INTERVIEW_PENDING,
        name: 'form.candidate_order.state.customer_interview_pending',
        color: 'orange',
      },
      {
        id: OBJECT_STATE_ID.REFUSED_CUSTOMER,
        name: 'form.candidate_order.state.refused_customer',
        color: 'red',
      },
      {
        id: OBJECT_STATE_ID.CANDIDATE_CANCEL,
        name: 'form.candidate_order.state.candidate_cancel',
        color: 'energyPeak',
      },
      {
        id: OBJECT_STATE_ID.DONE,
        name: 'form.candidate_order.state.done',
        color: 'lettuce',
      },
      {
        id: OBJECT_STATE_ID.INVOICED,
        name: 'form.candidate_order.state.invoiced',
        color: 'lime',
      },
      {
        id: OBJECT_STATE_ID.CLOSED,
        name: 'form.candidate_order.state.closed',
        color: 'navyGreen',
      },
    ],
    default: CandidateOrder.DEFAULT_VALUES.object_state_id,
  });

  /**
   * Get a list of disable status transitions for a CandidateOrder.
   * @param {Object} record the candidateOrder record.
   * @returns {Array}
   */
  static getDisabledStatuses = record => {
    return Object.keys(DATE_TO_STATUS).reduce(
      (acc, key) => [...acc, ...(record && record[key] ? [] : [DATE_TO_STATUS[key]])],
      []
    );
  };
}
