import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'react-admin';
import { connect } from 'react-redux';
import CustomAppBar from './CustomAppBar';
import { darkTheme, lightTheme } from './theme';

const sanitizeRestProps = ({ contentTitle, dispatch, ...rest }) => rest;

class CustomLayout extends React.Component {
  componentDidMount() {
    this.updateTitle();
  }

  componentDidUpdate() {
    this.updateTitle();
  }

  /**
   * Update html <title> value.
   */
  updateTitle = () => {
    const { contentTitle, title } = this.props;

    const finalTitle = [title];

    if (contentTitle) {
      finalTitle.push(contentTitle);
    }

    document.title = finalTitle.join(' / ');
  };

  render() {
    return <Layout {...sanitizeRestProps(this.props)} appBar={CustomAppBar} />;
  }
}

CustomLayout.propTypes = {
  contentTitle: PropTypes.string,
};

/**
 * Get current resource name from location pathname.
 * @param {*} state the app state.
 */
function mapStateToProps(state) {
  const stateToProps = {
    theme: state.theme === 'dark' ? darkTheme : lightTheme,
  };

  const container = document.getElementById('react-admin-title');
  if (container && container.firstChild) {
    stateToProps.contentTitle = container.firstChild.textContent;
  }

  return stateToProps;
}

const enhance = connect(mapStateToProps);
export default enhance(CustomLayout);
