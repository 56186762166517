/**
 * Manipulate Date and Time objects.
 */
export class DateTime {
  /**
   * @var {Date}
   */
  datetime = undefined;

  constructor(datetime) {
    if (!datetime) {
      datetime = new Date();
    } else if (typeof datetime === 'string') {
      if (DateTime.isDateString(datetime)) {
        datetime = new Date(datetime);
        datetime.setHours(0, 0, 0, 0);
      } else if (DateTime.isTimeString(datetime)) {
        const tmp = new DateTime();
        tmp.setTime(datetime);
        datetime = tmp.datetime;
      } else {
        datetime = new Date(datetime);
      }
    }

    this.datetime = datetime;
  }

  /**
   * Set a new date.
   * @param {String} value the new date to set (yyyy-mm-dd).
   */
  setDate(value) {
    const dateParts = value.split('-');
    // month is 0-based
    this.datetime.setFullYear(dateParts[0], dateParts[1] - 1, dateParts[2]);
  }

  /**
   * Set a new time.
   * @param {String} value the new time (hh:mm or hh:mm:ss).
   */
  setTime(value) {
    this.datetime.setHours(...value.split(':'));
  }

  /**
   * Format a datetime as string.
   * Format is yyyy-mm-dd hh:mm:ss.
   */
  toString() {
    return `${this.toDateString()} ${this.toTimeString()}`;
  }

  /**
   * Get date only in string format.
   * Format is yyyy-mm-dd.
   * @returns {String}
   */
  toDateString() {
    const mm = this.datetime.getMonth() + 1; // getMonth() is zero-based
    const dd = this.datetime.getDate();
    const yyyy = this.datetime.getFullYear();

    return [yyyy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
  }

  /**
   * Get time only in string format.
   * Format is hh:mm:ss or hh:mm.
   * @param {Boolean} withSeconds set true to get time with seconds.
   * @returns {String}
   */
  toTimeString(withSeconds = true) {
    const hh = this.datetime.getHours();
    const mm = this.datetime.getMinutes();
    const parts = [(hh > 9 ? '' : '0') + hh, (mm > 9 ? '' : '0') + mm];

    if (withSeconds) {
      const ss = this.datetime.getSeconds();
      parts.push((ss > 9 ? '' : '0') + ss);
    }

    return parts.join(':');
  }

  /**
   * Check if a string is a date (hh:mm:ss or hh:mm).
   * @param {String} value the value to check.
   * @returns {Boolean}
   */
  static isDateString(value) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(value);
  }

  /**
   * Check if a string is a time (hh:mm:ss or hh:mm).
   * @param {String} value the value to check.
   * @returns {Boolean}
   */
  static isTimeString(value) {
    const regex = /^\d{2}:\d{2}(:\d{2})?$/;
    return regex.test(value);
  }

  /**
   * Check if a string is a datetime (yyyy-mm-dd or yyyy-mm-dd hh:mm:ss or yyyy-mm-dd hh:mm).
   * @param {String} value the value to check.
   * @returns {Boolean}
   */
  static isDateTimeString(value) {
    const regex = /^\d{4}-\d{2}-\d{2}(\s\d{2}:\d{2}(:\d{2})?)?$/;
    return regex.test(value);
  }

  /**
   * Check if a value is a datetime.
   * @param {*} value the value to check.
   * @returns {Boolean}
   */
  static isDateTime(value) {
    if (value instanceof Date) {
      return true;
    }

    if (typeof value === 'string') {
      return DateTime.isDateTimeString(value);
    }

    return false;
  }
}

export class DateFormatter {
  static _format = v => {
    if (!(v instanceof Date) || isNaN(v)) return;
    const pad = '00';
    const yyyy = v.getFullYear().toString();
    const MM = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
  };

  static _DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

  /**
   * Convert Date object to String
   *
   * @param {Date} v value to convert
   * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
   */
  static sanitizeValue = value => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value === null || value === '') {
      return '';
    }
    // valid dates should not be converted
    if (DateFormatter._DATE_REGEX.test(value)) {
      return value;
    }

    const finalValue = typeof value instanceof Date ? value : new Date(value);
    return DateFormatter._format(finalValue);
  };
}
