import React, { useMemo } from 'react';
import {
  Datagrid,
  DateField,
  FormTab,
  ReferenceField,
  ReferenceManyField,
  Responsive,
  SimpleList,
  TextField,
} from 'react-admin';
import { PermissionManager } from '../../../data-provider';
import { CandidateCustomerEdit } from '../../CandidateCustomer';
import { DeleteConfirmButton } from '../../framework';
import AddCandidateCustomerButton from './AddCandidateCustomerButton';

const sortOrder = { field: 'candidacy_date', order: 'DESC' };
const getValue = attr => record => record[attr];

const CandidateEditCandidateCustomersTab = props => {
  const permissions = useMemo(() => new PermissionManager(), []);

  return (
    <FormTab {...props}>
      {permissions.canCreate('candidate_customer') && <AddCandidateCustomerButton />}

      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="candidate_customer"
        sort={sortOrder}
        target="candidate_id"
      >
        <Responsive
          small={
            <SimpleList
              linkType={false}
              primaryText={getValue('customer_business_name')}
              secondaryText={getValue('job_title')}
              tertiaryText={record => <DateField record={record} source="sent_at" showTime />}
            />
          }
          medium={
            <Datagrid
              expand={<CandidateCustomerEdit title=" " noRedirect permissions={permissions} />}
            >
              <DateField source="candidacy_date" />
              <TextField source="source" />
              <TextField source="customer_business_name" />
              {permissions.canList('customer') && (
                <ReferenceField
                  allowEmpty
                  linkType={permissions.canEdit('customer') ? 'edit' : false}
                  reference="customer"
                  source="customer_id"
                >
                  <TextField source="business_name" />
                </ReferenceField>
              )}
              <TextField source="job_title" />
              <TextField source="job_location" />
              <DateField showTime source="sms_date" />
              <DateField showTime source="phone_meeting_date" />
              {permissions.canList('user') && (
                <ReferenceField allowEmpty linkType={false} reference="user" source="user_id">
                  <TextField source="name" />
                </ReferenceField>
              )}
              {permissions.canDelete('candidate_order') && (
                <DeleteConfirmButton label="" redirect={false} />
              )}
            </Datagrid>
          }
        />
      </ReferenceManyField>
    </FormTab>
  );
};

export default CandidateEditCandidateCustomersTab;
