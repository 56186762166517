import { maxLength, number, required } from 'react-admin';
import { boolean, date, datetime } from './validators';

export default {
  candidate_id: [required(), number()],
  order_id: [required(), number()],
  object_state_id: [required(), number()],
  real_meeting_date: [datetime()],
  real_meeting_employee_id: [number()],
  post_meeting_date: [datetime()],
  customer_file_sent_date: [date()],
  customer_interview_date: [date()],
  coaching_meeting_date: [datetime()],
  customer_second_interview_date: [date()],
  job_start_date: [date()],
  invoice_sent_at: [date()],
  reporting: {
    date: [date()],
    note: [],
  },
  archive_box_number: [maxLength(64)],
  references_validated: [boolean()],
  waiting_date: [datetime()],
};
