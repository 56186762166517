import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormTab,
  ImageField,
  ImageInput,
  LongTextInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { Tag } from '../../../models';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { contactValidators, customerValidators } from '../../../validators';
import {
  CustomCreateActions,
  PhoneMaskTextInput,
  SexInput,
  TagListAutocompleteInput,
  Toolbar,
} from '../../framework';

export const CustomerCreate = props => (
  <Create {...props} actions={<CustomCreateActions />}>
    <TabbedForm redirect={REDIRECT_AFTER_CREATE} toolbar={<Toolbar />}>
      <FormTab contentClassName="tab-inline" label="form.customer.tabs.general">
        <ImageInput
          formClassName="ra-input__noflex"
          source="logo"
          accept="image/*"
          validate={customerValidators.logo}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          formClassName="ra-input__flex-25"
          source="business_name"
          validate={customerValidators.business_name}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="name"
          validate={customerValidators.name}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="parent_company_name"
          validate={customerValidators.parent_company_name}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="siret"
          validate={customerValidators.siret}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="address_name"
          validate={customerValidators.address_name}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="address_line_1"
          validate={customerValidators.address_line_1}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="address_line_2"
          validate={customerValidators.address_line_2}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="address_line_3"
          validate={customerValidators.address_line_3}
        />
        <TextInput
          formClassName="ra-input__flex-25"
          source="address_zipcode"
          validate={customerValidators.address_zipcode}
        />
        <TextInput
          formClassName="ra-input__flex-75"
          source="address_city"
          validate={customerValidators.address_city}
        />
        <BooleanInput
          formClassName="ra-input__flex-25 ra-input__v-align"
          source="use_deposit"
          validate={customerValidators.use_deposit}
          defaultValue={false}
        />
        <TagListAutocompleteInput
          formClassName="ra-input__flex-25"
          reference="tag"
          source="domains"
          type={Tag.TYPE.DOMAIN}
          validate={customerValidators.domains}
        />
        <TagListAutocompleteInput
          formClassName="ra-input__flex-50"
          reference="tag"
          source="locations"
          type={Tag.TYPE.LOCATION}
          validate={customerValidators.locations}
        />
        <LongTextInput
          formClassName="ra-input__flex-50"
          source="comments"
          validate={customerValidators.comments}
        />
      </FormTab>

      <FormTab label="form.customer.tabs.contacts" path="contacts">
        <ArrayInput
          className="array-input__simple-form-iterator--inline"
          fullWidth
          source="contacts"
        >
          <SimpleFormIterator>
            <SexInput
              source="title"
              validate={contactValidators.title}
              label="resources.contact.fields.title"
            />
            <TextInput
              source="first_name"
              validate={contactValidators.first_name}
              label="resources.contact.fields.first_name"
            />
            <TextInput
              source="last_name"
              validate={contactValidators.last_name}
              label="resources.contact.fields.last_name"
            />
            <PhoneMaskTextInput
              source="phone"
              validate={contactValidators.phone}
              label="resources.contact.fields.phone"
            />
            <TextInput
              source="email"
              validate={contactValidators.email}
              label="resources.contact.fields.email"
            />
            <TextInput
              source="role"
              validate={contactValidators.role}
              label="resources.contact.fields.role"
            />
            <BooleanInput
              source="is_main"
              validate={contactValidators.is_main}
              label="resources.contact.fields.is_main"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
