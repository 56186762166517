import { createStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Link } from 'react-admin';

const styles = createStyles(theme => ({
  marginTop: {
    marginTop: '1em',
  },
}));

const AddCandidateButton = ({ classes, label, record }) => (
  <Button
    className={classes.marginTop}
    component={Link}
    label={label}
    to={{
      pathname: '/candidate_order/create',
      state: { record: { order_id: record.id } },
      search: `?order_id=${record.id}`,
    }}
  >
    <AddIcon />
  </Button>
);

AddCandidateButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  record: PropTypes.object.isRequired,
};

AddCandidateButton.defaultProps = {
  label: 'form.order.add_candidate_button',
};

export default withStyles(styles)(AddCandidateButton);
