import { createStyles, withStyles, withTheme } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  LongTextInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import compose from 'recompose/compose';
import { Candidate, CandidateOrder } from '../../../models';
import { User } from '../../../models/user';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { DateTime } from '../../../utils/datetime';
import { candidateOrderValidators } from '../../../validators';
import { ObjectStateStepper } from '../../ObjectState';
import {
  AutocompleteInput,
  CustomEditActions,
  DateTimeInput,
  EmptyField,
  Toolbar,
} from '../../framework';
import CandidateOrderPageTitle from './CandidateOrderPageTitle';

const styles = theme =>
  createStyles({
    redLabel: {
      '&> label span': {
        color: theme.custom.label.red,
      },
    },
  });

/**
 * Simple wrapper to get a correct redirection link.
 * Was redirecting to /candidate_order instead of /customer.
 */
const CustomerReferenceWrapper = ({ children, resource, ...props }) =>
  React.cloneElement(children, { ...props, basePath: `/${resource}`, resource });

const CandidateOrderEdit = ({ classes, noRedirect, permissions, theme, ...props }) => {
  const { id } = props;
  const formName = useMemo(() => `candidate_order_edit_${id}`, [id]);

  return (
    <Edit
      actions={<CustomEditActions />}
      className="candidate-order"
      title={<CandidateOrderPageTitle {...props} />}
      undoable={false}
      {...props}
    >
      <SimpleForm
        className="form-inline"
        form={formName}
        redirect={noRedirect ? false : REDIRECT_AFTER_EDIT}
        toolbar={<Toolbar />}
      >
        <FormDataConsumer source="object_state_id">
          {({ formData, ...rest }) => (
            <ObjectStateStepper
              {...rest}
              choices={CandidateOrder.OBJECT_STATE_ID.values}
              disabledSteps={CandidateOrder.getDisabledStatuses(formData)}
              form={formName}
              source="object_state_id"
              orientation="vertical"
            />
          )}
        </FormDataConsumer>

        {permissions && permissions.canList('candidate') ? (
          <ReferenceField
            source="candidate_id"
            reference="candidate"
            formClassName="ra-input__flex-25"
          >
            <FunctionField render={Candidate.getFullName} />
          </ReferenceField>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="candidate_id" />
        )}

        {permissions && permissions.canList('order') && permissions.canList('customer') ? (
          <ReferenceField
            label="resources.order.fields.customer_id"
            linkType={false}
            reference="order"
            source="order_id"
            formClassName="ra-input__flex-75"
          >
            <CustomerReferenceWrapper>
              <ReferenceField
                addLabel={false}
                linkType={permissions && permissions.canEdit('customer') ? 'edit' : false}
                reference="customer"
                source="customer_id"
              >
                <TextField source="business_name" />
              </ReferenceField>
            </CustomerReferenceWrapper>
          </ReferenceField>
        ) : (
          <EmptyField
            formClassName="ra-input__flex-75"
            label="resources.order.fields.customer_id"
            source="order_id"
          />
        )}

        <TextInput source="order_id" formClassName="ra-input__flex-25" />

        {permissions && permissions.canList('order') ? (
          <ReferenceField
            label="resources.order.fields.job_title"
            linkType={false}
            reference="order"
            source="order_id"
            formClassName="ra-input__flex-75"
          >
            <TextField source="job_title" />
          </ReferenceField>
        ) : (
          <EmptyField
            formClassName="ra-input__flex-75"
            label="resources.order.fields.job_title"
            source="order_id"
          />
        )}

        <DateTimeInput
          source="waiting_date"
          validate={candidateOrderValidators.waiting_date}
          formClassName="ra-input__flex-25"
        />

        {permissions && permissions.canList('order') ? (
          <ReferenceField
            label="resources.order.fields.location"
            linkType={false}
            reference="order"
            source="order_id"
            formClassName="ra-input__flex-75"
          >
            <TextField source="location" />
          </ReferenceField>
        ) : (
          <EmptyField
            formClassName="ra-input__flex-75"
            label="resources.order.fields.location"
            source="order_id"
          />
        )}

        <DateTimeInput
          source="real_meeting_date"
          validate={candidateOrderValidators.real_meeting_date}
          formClassName="ra-input__flex-25"
        />

        <BooleanInput
          source="references_validated"
          validate={candidateOrderValidators.references_validated}
          formClassName="ra-input__flex-75 ra-input__v-align"
        />

        {permissions && permissions.canList('user') ? (
          <ReferenceInput
            allowEmpty
            filterToQuery={User.filterToQuery}
            formClassName="ra-input__flex-25"
            reference="user"
            sort={User.sortOrder}
            source="real_meeting_employee_id"
            validate={candidateOrderValidators.real_meeting_employee_id}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="real_meeting_employee_id" />
        )}

        {/* <DateInput
          source="candidate_file_sent_date"
          validate={candidateOrderValidators.candidate_file_sent_date}
          formClassName="ra-input__flex-75"
        /> */}

        <DateInput
          source="customer_interview_date"
          validate={candidateOrderValidators.customer_interview_date}
          formClassName="ra-input__flex-75"
        />

        <DateTimeInput
          source="post_meeting_date"
          validate={candidateOrderValidators.post_meeting_date}
          formClassName="ra-input__flex-25"
        />

        <DateInput
          source="customer_second_interview_date"
          validate={candidateOrderValidators.customer_second_interview_date}
          formClassName="ra-input__flex-75"
        />

        <DateInput
          source="customer_file_sent_date"
          validate={candidateOrderValidators.customer_file_sent_date}
          formClassName="ra-input__flex-25"
        />

        <DateInput
          formClassName="ra-input__flex-75"
          source="job_start_date"
          validate={candidateOrderValidators.job_start_date}
        />

        <DateTimeInput
          source="coaching_meeting_date"
          validate={candidateOrderValidators.coaching_meeting_date}
          formClassName="ra-input__flex-25"
        />

        <DateInput
          formClassName="ra-input__flex-75"
          source="invoice_sent_at"
          validate={candidateOrderValidators.invoice_sent_at}
        />

        <ArrayInput
          className={classnames(
            'array-input__simple-form-iterator',
            'fullwidth',
            `theme-${theme.palette.type}`,
            classes.redLabel
          )}
          formClassName="ra-input__flex-25"
          source="reporting_note"
        >
          <SimpleFormIterator>
            <DateInput
              defaultValue={new DateTime().toDateString()}
              label="resources.candidate_order.fields.reporting.date"
              source="date"
              validate={candidateOrderValidators.reporting.date}
            />
            <LongTextInput
              label="resources.candidate_order.fields.reporting.note"
              source="note"
              validate={candidateOrderValidators.reporting.note}
            />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput
          className={`array-input__simple-form-iterator fullwidth theme-${theme.palette.type}`}
          formClassName="ra-input__flex-25"
          source="internal_reporting_note"
        >
          <SimpleFormIterator>
            <DateInput
              defaultValue={new DateTime().toDateString()}
              label="resources.candidate_order.fields.reporting.date"
              source="date"
              validate={candidateOrderValidators.reporting.date}
            />
            <LongTextInput
              label="resources.candidate_order.fields.reporting.note"
              source="note"
              validate={candidateOrderValidators.reporting.note}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput
          formClassName="ra-input__flex-25"
          source="archive_box_number"
          validate={candidateOrderValidators.archive_box_number}
        />
      </SimpleForm>
    </Edit>
  );
};

CandidateOrderEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noRedirect: PropTypes.bool,
  permissions: PropTypes.object,
  theme: PropTypes.object,
};

CandidateOrderEdit.defaultProps = {
  noRedirect: true,
};

const enhance = compose(withStyles(styles), withTheme());
export default enhance(CandidateOrderEdit);
