export const DOMAINS_LEVELS = Object.freeze({
  BEGINNER: 0,
  INTERMEDIATE: 1,
  EXPERT: 2,
});

export const DOMAINS_LEVEL = Object.freeze({
  values: [
    {
      id: DOMAINS_LEVELS.BEGINNER,
      name: 'form.domains_level.beginner',
    },
    {
      id: DOMAINS_LEVELS.INTERMEDIATE,
      name: 'form.domains_level.intermediate',
    },
    {
      id: DOMAINS_LEVELS.EXPERT,
      name: 'form.domains_level.expert',
    },
  ],
  default: DOMAINS_LEVELS.BEGINNER,
});

export class Tag {
  static TYPE = Object.freeze({
    DIPLOMA: 'diploma',
    DOMAIN: 'domain',
    SECTOR: 'domain',
    SUB_SECTOR: 'sub_domain',
    DOMAIN_LEVEL: 'domain_level',
    LOCATION: 'location',
    JOB_NAME: 'job_name',
    CANDIDATE_SOURCE: 'candidate_source',
  });
}
