import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Candidate } from '../../../models';
import { PageTitle } from '../../framework';

const CandidateOrderPageTitle = ({ candidate, translate, ...props }) => {
  const source = record => {
    const parts = [];

    if (candidate) {
      parts.push(Candidate.getFullName(candidate));
    }

    parts.push(`${translate('resources.order.name', { smart_count: 1 })} #${record.order_id}`);
    return parts.join(' / ');
  };

  return <PageTitle {...props} source={source} />;
};

CandidateOrderPageTitle.propTypes = {
  candidate: PropTypes.object,
  record: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

/**
 * Inject candidate into title component.
 * @param {object} state the global state.
 * @param {object} props the component properties.
 */
function mapStateToProps(state, props) {
  const { record } = props;
  return {
    candidate:
      state.admin.resources.candidate.data[record && record.candidate_id && record.candidate_id],
  };
}

const enhance = compose(
  translate,
  connect(mapStateToProps)
);

export default enhance(CandidateOrderPageTitle);
