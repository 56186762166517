import React from 'react';
import { Login } from 'react-admin';
import { LOGIN_BACKGROUND_IMAGE } from '../../settings';
import CustomLoginForm from './CustomLoginForm';

const CustomLogin = () => (
  <Login
    backgroundImage={LOGIN_BACKGROUND_IMAGE}
    className="login-background"
    loginForm={<CustomLoginForm />}
  />
);

export default CustomLogin;
