import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import { TextSearchInput } from '../../framework';
import { Reporting } from '../../../models/reporting';

const ReportingFilters = props => (
  <Filter {...props}>
    <TextSearchInput
      source="customer-dot-business_name"
      label="resources.reporting.fields.customer_id"
      alwaysOn
    />
    <SelectInput
      allowEmpty
      alwaysOn
      choices={Reporting.EMAIL_STATUS_CHOICES}
      source="email_status"
    />
  </Filter>
);

export default ReportingFilters;
