import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  DateInput,
  Edit,
  FunctionField,
  LongTextInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { Candidate, CandidateSource, Customer, ProfessionalPosition, Tag } from '../../../models';
import { User } from '../../../models/user';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { candidateCustomer } from '../../../validators';
import {
  AutocompleteInput,
  CustomEditActions,
  DateTimeInput,
  EmptyField,
  ReferenceNameInput,
  TagAutocompleteInput,
  Toolbar,
} from '../../framework';

const CandidateCustomerEdit = ({ noRedirect, permissions, ...props }) => {
  const { id } = props;
  const formName = useMemo(() => `candidate_customer_edit_${id}`, [id]);

  return (
    <Edit
      {...props}
      actions={<CustomEditActions />}
      className="candidate_customer"
      undoable={false}
    >
      <SimpleForm
        className="form-inline"
        form={formName}
        redirect={noRedirect ? false : REDIRECT_AFTER_EDIT}
        toolbar={<Toolbar hasDelete={noRedirect ? false : undefined} />}
      >
        {permissions && permissions.canList('candidate') ? (
          <ReferenceField
            source="candidate_id"
            reference="candidate"
            formClassName="ra-input__flex-25"
          >
            <FunctionField render={Candidate.getFullName} />
          </ReferenceField>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="candidate_id" />
        )}

        <DateInput
          formClassName="ra-input__flex-25"
          source="candidacy_date"
          validate={candidateCustomer.candidacy_date}
        />

        {permissions && permissions.canList('candidate_source') ? (
          <ReferenceNameInput
            allowEmpty
            filterToQuery={CandidateSource.filterToQuery}
            formClassName="ra-input__flex-50"
            reference="candidate_source"
            target="name"
            sort={CandidateSource.sortOrder}
            source="source"
            validate={candidateCustomer.source}
          >
            <AutocompleteInput optionText="name" optionValue="name" />
          </ReferenceNameInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-50" source="source" />
        )}

        <TextInput
          formClassName="ra-input__flex-25"
          source="customer_business_name"
          validate={candidateCustomer.customer_business_name}
        />

        {permissions && permissions.canList('customer') ? (
          <ReferenceInput
            allowEmpty
            formClassName="ra-input__flex-25"
            reference="customer"
            sort={Customer.sortOrder}
            source="customer_id"
            validate={candidateCustomer.customer_id}
          >
            <AutocompleteInput optionText="business_name" />
          </ReferenceInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="customer_id" />
        )}

        {permissions && permissions.canList('professional_position') ? (
          <ReferenceNameInput
            allowEmpty
            filterToQuery={ProfessionalPosition.filterToQuery}
            formClassName="ra-input__flex-25"
            reference="professional_position"
            target="name"
            sort={ProfessionalPosition.sortOrder}
            source="job_title"
            validate={candidateCustomer.job_title}
          >
            <AutocompleteInput optionText="name" optionValue="name" />
          </ReferenceNameInput>
        ) : (
          <EmptyField formClassName="ra-input__flex-25" source="job_title" />
        )}

        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="job_location"
          type={Tag.TYPE.LOCATION}
          validate={candidateCustomer.job_location}
        />

        <DateTimeInput
          formClassName="ra-input__flex-25"
          source="sms_date"
          validate={candidateCustomer.sms_date}
        />

        <DateTimeInput
          formClassName="ra-input__flex-25"
          source="phone_meeting_date"
          validate={candidateCustomer.phone_meeting_date}
        />

        {permissions && permissions.canList('user') ? (
          <ReferenceInput
            allowEmpty
            filterToQuery={User.filterToQuery}
            formClassName="ra-input__flex-25"
            reference="user"
            sort={User.sortOrder}
            source="user_id"
            validate={candidateCustomer.user_id}
          >
            <AutocompleteInput source="name" />
          </ReferenceInput>
        ) : (
          <EmptyField
            formClassName="ra-input__flex-25"
            source="user_id"
            validate={candidateCustomer.user_id}
          />
        )}

        <LongTextInput
          formClassName="ra-input__flex-50"
          source="comment"
          validate={candidateCustomer.comment}
        />
      </SimpleForm>
    </Edit>
  );
};

CandidateCustomerEdit.propTypes = {
  noRedirect: PropTypes.bool,
  permissions: PropTypes.object,
};

CandidateCustomerEdit.propTypes = {
  noRedirect: false,
};

export default CandidateCustomerEdit;
