import React from 'react';
import { UserMenu } from 'react-admin';
import ThemeSwitcher from './ThemeSwitcher';


const CustomUserMenu = props => (
  <UserMenu {...props}>
    <ThemeSwitcher />
  </UserMenu>
);

export default CustomUserMenu;
