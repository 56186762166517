export default {
  DataValidationException: "Des règles de validation de données n'ont pas été respectées",
  AuthenticationException: "Problème d'authentification de l'utilisateur",
  ObjectNotFoundException: "L'objet n'a pas été trouvé",
  ObjectDuplicatedException: "L'objet ne peut être créé car il est déjà présent",
  ObjectConstraintException: "L'objet contient une contrainte qui empêche le traitement demandé",
  ObjectStateSupportException: "L'objet ne supporte pas le changement de statut",
  ObjectStateTransitionsException: "L'objet ne peut pas changer vers le statut demandé",
  ObjectMethodFailureException: "La méthode de l'objet a renvoyé une erreur",
  MethodNotAllowedHttpException: 'Méthode API non authorisée',
  QueryException: 'Erreur de traitement de la requête dans la base de données',
  PrintException: "Problème d'impression",
  UnauthorizedException: 'Permissions insuffisantes',
  MaintenanceModeException: "L'application est en maintenance",
  ERROR_VALIDATION_DATA: 'Une information est invalide',
  EmailException: "Aucun destinataire valide pour l'email",
};
