import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import RadioButtonUnckeckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ErrorIcon from '@material-ui/icons/Warning';
import { get, isEqualWith } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { REDUX_FORM_NAME, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { updateObjectStateId as updateObjectStateIdAction } from '../../actions/stateActions';
import { crudGetTransition as crudGetTransitionAction } from '../../actions/transitionActions';
import { PermissionManager } from '../../data-provider';
import { DateTime } from '../../utils/datetime';
import { isEmpty } from '../../utils/tools';
import { Confirm } from '../framework/layout';

/**
 * Get correct icon for a given step.
 * @param {number} stepIndex index of current step.
 * @param {number} activeStepIndex index of current active step.
 * @param {boolean} isError is the current step in error state.
 * @param {boolean} isActive is the current step active?
 */
function getIcon(stepIndex, activeStepIndex, isError, isActive) {
  if (isError) {
    return <ErrorIcon color="error" />;
  }

  if (stepIndex > activeStepIndex) {
    return <RadioButtonUnckeckedIcon color={(isActive && 'primary') || 'disabled'} />;
  }

  return ' '; // default icon, a plain circle.
}

class ObjectStateStepper extends React.Component {
  state = { confirm: false, confirmStep: null };

  componentDidMount() {
    this.fetchTransitions();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.resource !== prevProps.resource ||
      (!this.props.readOnly && prevProps.readOnly)
    ) {
      this.fetchTransitions();
    }
  }

  /**
   * Fetch state transitions from server.
   */
  fetchTransitions() {
    const { readOnly } = this.props;
    if (typeof this.props.transitions === 'undefined' && !readOnly) {
      // load transitions only if they have not been already loaded (already in state).
      this.props.crudGetTransition(this.props.resource);
    }
  }

  /**
   * Handle confirmation cancel.
   */
  handleStepClickCancel = () => {
    this.setState({ confirm: false });
  };

  /**
   * Handle confirmation.
   * Launch a request to server to update state_id of the current object.
   */
  handleStepClickConfirm = () => {
    const { basePath, record, resource, sourceId } = this.props;
    const step = Object.assign({}, this.state.confirmStep);
    const id = get(record, sourceId);

    this.setState({ confirm: false });
    this.props.updateObjectStateId(resource, id, step.id, record, basePath);
  };

  /**
   * Handle click on a step.
   * @param {object} step the clicked step.
   */
  handleStepClick = step => () => {
    this.setState({ confirm: true, confirmStep: step });
  };

  /**
   * Get active step object.
   * @returns {object} the current active step.
   */
  getActiveStep = () => {
    const { choices, record, source, step } = this.props;
    const value = typeof step === 'undefined' ? get(record, source) : step;

    return choices.find(choice => choice.id === value);
  };

  /**
   * Get index of current active step in choices list.
   * @returns {number} the index of the current active step, or -1 if step has not been found.
   */
  getActiveStepIndex = () => {
    const { choices } = this.props;
    const activeStep = this.getActiveStep();

    if (!activeStep) {
      return -1;
    }

    return choices.findIndex(choice => choice.id === activeStep.id);
  };

  /**
   * Check if a step is enabled.
   * @param {object} step the current step to check.
   * @returns {boolean}
   */
  isStepEnabled = step => {
    const { disabledSteps, enabledSteps, readOnly, transitions } = this.props;
    const activeStep = this.getActiveStep();

    if (Array.isArray(enabledSteps) && enabledSteps.includes(step.id)) {
      return true;
    }

    if (Array.isArray(disabledSteps) && disabledSteps.includes(step.id)) {
      return false;
    }

    if (readOnly || !transitions || !activeStep || activeStep.id === step.id) {
      return false;
    }

    const stepTransitions = transitions[activeStep.id];
    return stepTransitions && stepTransitions.includes(step.id);
  };

  render() {
    const { choices, className, orientation, resource, source, translate } = this.props;
    const { confirm, confirmStep } = this.state;
    const activeStepIndex = this.getActiveStepIndex();

    return (
      <React.Fragment>
        <Stepper
          activeStep={activeStepIndex}
          className={className}
          nonLinear
          orientation={orientation}
        >
          {choices.map((step, index) => {
            const isEnabled = this.isStepEnabled(step);
            const isCurrent = activeStepIndex === index;
            const isActive = isCurrent || isEnabled;
            const isError = step.error && isCurrent;

            return (
              <Step key={step.id} disabled={!isEnabled} active={isActive} completed={isCurrent}>
                <StepButton
                  icon={getIcon(index, activeStepIndex, isError, isActive)}
                  onClick={this.handleStepClick(step)}
                >
                  <StepLabel error={isError}>{translate(step.name)}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <Confirm
          open={confirm}
          onCancel={this.handleStepClickCancel}
          onConfirm={this.handleStepClickConfirm}
          content={translate('message.state_stepper_confirm', {
            name: confirmStep && translate(confirmStep.name),
          })}
          title={`resources.${resource}.fields.${source}`}
          translateContent={false}
        />
      </React.Fragment>
    );
  }
}

ObjectStateStepper.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      completed: PropTypes.bool,
      error: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  crudGetTransition: PropTypes.func.isRequired,
  disabledSteps: PropTypes.array,
  enabledSteps: PropTypes.array,
  label: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  readOnly: PropTypes.bool.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitions: PropTypes.object,
  translate: PropTypes.func.isRequired,
  updateObjectStateId: PropTypes.func.isRequired,
};

ObjectStateStepper.defaultProps = {
  sourceId: 'id',
  readOnly: false,
};

/**
 * Load transitions from app state.
 * @param {*} state the app state.
 * @param {*} props the component properties.
 */
function mapStateToProps(state, props) {
  const { readOnly } = props;
  const formName = props.form || REDUX_FORM_NAME;

  const stateToProps = { readOnly, transitions: state.transition[props.resource] };

  let differentNbFields = false;

  // exclude some fields which are not updated directly in forms
  const excludedFields = ['object_state_id', 'created_at', 'updated_at'];

  if (state.form[formName] && state.form[formName].values && state.form[formName].initial) {
    differentNbFields =
      Object.keys(state.form[formName].initial).filter(key => !excludedFields.includes(key))
        .length !==
      Object.keys(state.form[formName].values).filter(key => !excludedFields.includes(key)).length;

    const anyUpdated = Object.keys(state.form[formName].initial)
      .filter(key => !excludedFields.includes(key))
      .some(key => {
        const newValue = state.form[formName].values[key];
        const oldValue = state.form[formName].initial[key];

        return !isEqualWith(oldValue, newValue, function customizer(a, b) {
          if (isEmpty(a) && isEmpty(b)) {
            // avoid comparing null and "" which are the same value for api
            return true;
          }

          if (isEmpty(a) || isEmpty(b)) {
            // one is empty and the other is filled
            return false;
          }

          if (DateTime.isDateTime(a) && DateTime.isDateTime(b)) {
            const dateA = new DateTime(a);
            const dateB = new DateTime(b);

            if (dateA.toString() === dateB.toString()) {
              return true;
            }
          }

          if (!isNaN(a) || !isNaN(b)) {
            return String(a) === String(b);
          }
        });
      });

    if (anyUpdated || differentNbFields) {
      // disable if form data updated
      stateToProps.readOnly = true;
    }
  }

  return stateToProps;
}

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    crudGetTransition: crudGetTransitionAction,
    updateObjectStateId: updateObjectStateIdAction,
  })
);

const ObjectStateStepperConnected = enhance(ObjectStateStepper);

const ObjectStateStepperWrapper = props => {
  const permissions = new PermissionManager();

  return (
    <ObjectStateStepperConnected
      {...props}
      readOnly={props.readOnly || !permissions.can(props.resource, 'set_object_state')}
    />
  );
};

ObjectStateStepperWrapper.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.string,
};

export default ObjectStateStepperWrapper;
