import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import get from 'lodash/get';

/**
 * Display page title.
 * Format is <Resource name> (#<Resource id>)- <Record name>.
 * Resource id is optional.
 * @param {object} record the current record.
 * @param {string} resource the resource.
 * @param {string|function} source attribute of the record to display as name.
 *    If function, the record will be given as parameter.
 * @param {string} sourceId the attribute to use to get record ID.
 * @param {function} translate the translation HOC.
 * @param {boolean} withId set true to display the record ID with the record name.
 */
const PageTitle = ({ locale, record, resource, source, sourceId, translate, withId }) => {
  if (!record) {
    return null;
  }

  const recordTitle = typeof source === 'function' ? source(record) : get(record, source);
  const resourceTitle = translate(`resources.${resource}.name`, {
    _: `resources.${resource}.name`,
    smart_count: 1,
  });
  const idTitle = withId ? '#' + get(record, sourceId) + ' ' : '';

  return <span locale={locale}>{`${resourceTitle} ${idTitle}/ ${recordTitle}`}</span>;
};

PageTitle.propTypes = {
  locale: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  sourceId: PropTypes.string,
  translate: PropTypes.func.isRequired,
  withId: PropTypes.bool,
};

PageTitle.defaultProps = {
  source: 'name',
  sourceId: 'id',
  withId: false,
};

export default translate(PageTitle);
