import { maxValue, minValue } from 'ra-core';
import { email, maxLength, number } from 'react-admin';
import { notEmpty, memoize } from './validators';
import sum from 'lodash/sum';

/**
 * Check if a single `is_main` is checked.
 * @param {srting|Array} attrs the other attribute to check.
 * @param {string} message the error message.
 */
const singleIsMain = memoize((message = 'validation.singleIsMain') => (value, values, props) => {
  if (value && Array.isArray(values.contacts)) {
    if (
      Array.isArray(values.contacts) &&
      sum(values.contacts.map(item => Boolean(item.is_main))) > 1
    ) {
      // if any other `is_main`, raise error.
      return props.translate(message, { _: message });
    }
  }

  return undefined;
});

export default {
  first_name: [notEmpty(), maxLength(64)],
  last_name: [notEmpty(), maxLength(64)],
  phone: [maxLength(16)],
  email: [maxLength(64), email()],
  role: [maxLength(64)],
  is_main: [number(), minValue(0), maxValue(1), singleIsMain()],
};
