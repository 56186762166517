import CandidateIcon from '@material-ui/icons/AccountCircle';
import CustomerIcon from '@material-ui/icons/BusinessCenter';
import MailIcon from '@material-ui/icons/Email';
import CandidateOrderIcon from '@material-ui/icons/FolderShared';
import UserIcon from '@material-ui/icons/Group';
import CandidateSourceIcon from '@material-ui/icons/Label';
import DegreeIcon from '@material-ui/icons/School';
import RoleIcon from '@material-ui/icons/Security';
import OrderIcon from '@material-ui/icons/ShoppingCart';
import ReportingIcon from '@material-ui/icons/ViewList';
import ProfessionalPositionIcon from '@material-ui/icons/Work';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { CandidateCreate, CandidateEdit, CandidateList } from './components/Candidate';
import { CandidateCustomerCreate } from './components/CandidateCustomer';
import {
  CandidateOrderCreate,
  CandidateOrderEdit,
  CandidateOrderList,
} from './components/CandidateOrder';
import {
  CandidateSourceCreate,
  CandidateSourceEdit,
  CandidateSourceList,
} from './components/CandidateSource';
import { CustomLayout, CustomLogin, NotFound } from './components/CustomLayout';
import { CustomerCreate, CustomerEdit, CustomerList } from './components/Customer';
import { DegreeCreate, DegreeEdit, DegreeList } from './components/Degree';
import { MailEdit, MailList } from './components/Mail';
import { OrderCreate, OrderEdit, OrderList } from './components/Order';
import {
  ProfessionalPositionCreate,
  ProfessionalPositionEdit,
  ProfessionalPositionList,
} from './components/ProfessionalPosition';
import { ReportingList } from './components/Reporting';
import { RoleCreate, RoleEdit, RoleList } from './components/Role';
import { UserCreate, UserEdit, UserList } from './components/User';
import Api from './data-provider/api';
import AuthProvider from './data-provider/login';
import { Session } from './data-provider/session';
import i18n from './i18n';
import { theme as themeReducer, transition as transitionReducer } from './reducers';
import customRoutes from './routes';
import { formSubmit } from './sagas';
import { REFRESH_APP_VERSION_TIMER } from './settings';

const reducers = { theme: themeReducer, transition: transitionReducer };
const sagas = [formSubmit];
const unauthenticatedUrls = [/^\/auth\/reset\/\w+/gi];

const resources = {
  customer: { create: CustomerCreate, edit: CustomerEdit, list: CustomerList, icon: CustomerIcon },
  order: { create: OrderCreate, edit: OrderEdit, list: OrderList, icon: OrderIcon },
  candidate: {
    create: CandidateCreate,
    edit: CandidateEdit,
    list: CandidateList,
    icon: CandidateIcon,
  },
  candidate_customer: {
    create: CandidateCustomerCreate,
  },
  candidate_order: {
    create: CandidateOrderCreate,
    edit: CandidateOrderEdit,
    list: CandidateOrderList,
    icon: CandidateOrderIcon,
  },
  reporting: { list: ReportingList, icon: ReportingIcon },
  mail: { edit: MailEdit, list: MailList, icon: MailIcon },
  degree: { create: DegreeCreate, edit: DegreeEdit, list: DegreeList, icon: DegreeIcon },
  professional_position: {
    create: ProfessionalPositionCreate,
    list: ProfessionalPositionList,
    edit: ProfessionalPositionEdit,
    icon: ProfessionalPositionIcon,
  },
  candidate_source: {
    create: CandidateSourceCreate,
    edit: CandidateSourceEdit,
    list: CandidateSourceList,
    icon: CandidateSourceIcon,
  },
  user: { create: UserCreate, edit: UserEdit, list: UserList, icon: UserIcon },
  role: { create: RoleCreate, edit: RoleEdit, list: RoleList, icon: RoleIcon },
  tag: {},
  permission: {},
};
const resourceKeys = Object.keys(resources);

class App extends React.Component {
  componentDidMount() {
    this.timerID = setInterval(Session.checkAppVersion, REFRESH_APP_VERSION_TIMER, true);
    Session.init();

    if (!Session.getUser() || !Session.isAccessTokenValid()) {
      const url = window.location.hash.substring(1); // '#' is included in hash
      const matchingRegex = unauthenticatedUrls.find(regex => regex.test(url));

      if (!matchingRegex) {
        setTimeout(Session.redirectToLogin, 0);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      <Admin
        appLayout={CustomLayout}
        authProvider={AuthProvider}
        catchAll={NotFound}
        customReducers={reducers}
        customRoutes={customRoutes}
        customSagas={sagas}
        dataProvider={Api}
        i18nProvider={i18n}
        locale="fr"
        loginPage={CustomLogin}
        title="Talento"
      >
        {permissions =>
          resourceKeys.map(resource => (
            <Resource
              name={resource}
              key={resource}
              create={
                resources[resource].create &&
                (permissions.canCreate(resource) ||
                  (resources[resource].permissions && resources[resource].permissions.create))
                  ? resources[resource].create
                  : null
              }
              edit={
                resources[resource].edit &&
                (permissions.canEdit(resource) ||
                  (resources[resource].permissions && resources[resource].permissions.edit))
                  ? resources[resource].edit
                  : null
              }
              list={
                resources[resource].list &&
                (permissions.canList(resource) ||
                  (resources[resource].permissions && resources[resource].permissions.list))
                  ? resources[resource].list
                  : null
              }
              show={
                resources[resource].show &&
                (permissions.canShow(resource) ||
                  (resources[resource].permissions && resources[resource].permissions.show))
                  ? resources[resource].show
                  : null
              }
              options={{
                hasDelete:
                  permissions.canDelete(resource) ||
                  (resources[resource].permissions && resources[resource].permissions.delete),
              }}
              icon={resources[resource].icon}
            />
          ))
        }
      </Admin>
    );
  }
}

export default App;
