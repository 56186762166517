import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ActionDelete from '@material-ui/icons/Delete';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, crudDeleteMany, startUndoable, translate } from 'react-admin';
import { ConfirmDelete } from '../layout';

const sanitizeRestProps = ({
  basePath,
  classes,
  dispatchCrudDeleteMany,
  filterValues,
  label,
  redirect,
  resource,
  selectedIds,
  startUndoable,
  undoable,
  ...rest
}) => rest;

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

class BulkDeleteButton extends Component {
  static propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    dispatchCrudDeleteMany: PropTypes.func.isRequired,
    icon: PropTypes.element,
    label: PropTypes.string,
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    undoable: PropTypes.bool,
  };

  static defaultProps = {
    icon: <ActionDelete />,
    label: 'ra.action.delete',
    undoable: true,
  };

  state = { open: false };

  /**
   * Handle click on delete button.
   * Open confirmation modal.
   */
  handleClick = e => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  /**
   * Handle click on cancel button of confirmation modal.
   * Close confirmation modal.
   */
  handleCancel = e => {
    e.stopPropagation();
    this.setState({ open: false });
  };

  /**
   * Handle click on confirmation button in confirmation modal.
   * Launch delete action and close confirmation modal.
   */
  handleConfirm = () => {
    const {
      basePath,
      dispatchCrudDeleteMany,
      resource,
      selectedIds,
      startUndoable,
      undoable,
      onClick,
    } = this.props;

    if (undoable) {
      startUndoable(crudDeleteMany(resource, selectedIds, basePath));
    } else {
      dispatchCrudDeleteMany(resource, selectedIds, basePath);
    }

    if (typeof onClick === 'function') {
      onClick();
    }

    this.setState({ open: false });
  };

  render() {
    const { classes, icon, label, selectedIds, translate, ...rest } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label={label}
          className={classes.deleteButton}
          {...sanitizeRestProps(rest)}
        >
          {icon}
        </Button>

        <ConfirmDelete
          content={translate('ra.message.bulk_delete_content', selectedIds.length)}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          open={open}
          translateContent={false}
        />
      </Fragment>
    );
  }
}

const BulkDeleteConfirmButton = compose(
  translate,
  connect(
    undefined,
    {
      startUndoable,
      dispatchCrudDeleteMany: crudDeleteMany,
    }
  ),
  withStyles(styles)
)(BulkDeleteButton);

BulkDeleteConfirmButton.defaultProps = {
  undoable: false,
};

export default BulkDeleteConfirmButton;
