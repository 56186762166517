import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { EmailField as ReactAdminEmailField } from 'react-admin';
import compose from 'recompose/compose';
import pure from 'recompose/pure';

const styles = theme =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  });

const EmailField = ({ classes, className, ...rest }) => (
  <ReactAdminEmailField {...rest} className={classnames(classes.link, className)} />
);

EmailField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
};

const PureEmailField = compose(
  pure,
  withStyles(styles)
)(EmailField);

PureEmailField.defaultProps = {
  addLabel: true,
};

export default PureEmailField;
