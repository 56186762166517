import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';

import { CardActions, CreateButton, ExportButton, sanitizeListRestProps } from 'react-admin';

const CustomListActions = ({
  bulkActions,
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total,
  leftActions,
  rightActions,
  ...rest
}) => (
  <CardActions className={className} {...sanitizeListRestProps(rest)}>
    {bulkActions &&
      cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {leftActions &&
      leftActions.map((action, index) =>
        React.cloneElement(action, {
          key: index,
          basePath,
          filterValues,
          onUnselectItems,
          resource,
          selectedIds,
          total,
          ...rest,
        })
      )}
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {hasCreate && <CreateButton basePath={basePath} />}
    {exporter !== false && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ filterValues, ...permanentFilter }}
        exporter={exporter}
      />
    )}
    {rightActions &&
      rightActions.map((action, index) =>
        React.cloneElement(action, {
          key: index,
          basePath,
          filterValues,
          onUnselectItems,
          resource,
          selectedIds,
          total,
          ...rest,
        })
      )}
  </CardActions>
);

CustomListActions.propTypes = {
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  leftActions: PropTypes.array,
  permanentFilter: PropTypes.object,
  resource: PropTypes.string,
  rightActions: PropTypes.array,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number.isRequired,
};

CustomListActions.defaultProps = {
  selectedIds: [],
};

export default onlyUpdateForKeys([
  'resource',
  'filters',
  'displayedFilters',
  'filterValues',
  'selectedIds',
])(CustomListActions);
