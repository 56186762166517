import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { PageTitle } from '../../framework';

const OrderPageTitle = ({ customer, translate, ...props }) => {
  const source = record => {
    const parts = [];

    if (customer) {
      parts.push(customer.business_name);
    }

    parts.push(record.job_title);
    return parts.join(' / ');
  };

  return <PageTitle {...props} source={source} withId />;
};

OrderPageTitle.propTypes = {
  customer: PropTypes.object,
  record: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

/**
 * Inject customer into title component.
 * @param {object} state the global state.
 * @param {object} props the component properties.
 */
function mapStateToProps(state, props) {
  const { record } = props;
  return {
    customer:
      state.admin.resources.customer.data[record && record.customer_id && record.customer_id],
  };
}

const enhance = compose(
  translate,
  connect(mapStateToProps)
);

export default enhance(OrderPageTitle);
