import { TRANSITION_GET, TRANSITION_GET_ACCUMULATE } from '.';

/**
 * Get possible state transitions for a given resource.
 * @param {*} resource the resource name.
 */
const crudGetTransitionAction = resource => ({
  type: TRANSITION_GET,
  payload: { resource },
  meta: {
    resource,
    fetch: TRANSITION_GET,
    onFailure: {
      notification: {
        body: 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

/**
 * Get possible state transitions for a given resource.
 * Accumulate requests to reduce number of requests made to API.
 * @param {string} resource the resource name.
 */
export const crudGetTransition = resource => ({
  type: TRANSITION_GET_ACCUMULATE,
  /*
   * Set `ids = []` because accumulate sideEffect expects an `ids` parameter.
   * @see https://github.com/marmelab/react-admin/blob/v2.3.2/packages/ra-core/src/sideEffect/accumulate.js
   */
  payload: { resource, ids: [] },
  meta: { accumulate: crudGetTransitionAction },
});
