import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { TextField } from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    nl2br: {
      whiteSpace: 'pre-line',
    },
    preserveSpaces: {
      whiteSpace: 'pre-wrap',
    },
  });

/**
 * Display a text on several lines.
 * Interprets `\n` and break lines.
 * @param {*} param0 ext field properties.
 */
const MultilineText = ({ classes, preserveSpaces, ...props }) => (
  <TextField {...props} className={preserveSpaces ? classes.preserveSpaces : classes.nl2br} />
);

MultilineText.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  preserveSpaces: PropTypes.bool,
};

const MultilineTextField = compose(withStyles(styles))(MultilineText);

MultilineTextField.defaultProps = {
  addLabel: true,
  preserveSpaces: false,
};

export default MultilineTextField;
