import { Session } from '.';

const SEPARATOR = '-';

export class PermissionManager {
  constructor() {
    this.list = Session.getPermissions();
  }

  /**
   * Check if user can execute an action for a resource.
   * @param {string} resource the resource to check.
   * @param {string} action the action to check.
   * @returns {boolean}
   */
  can = (resource, action) => this.list.some(item => item === resource + SEPARATOR + action);

  /**
   * Check if user has at least one permission on a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canResource = resource => this.list.some(item => item.startsWith(resource + SEPARATOR));

  /**
   * Check if user has at least one permission on an action.
   * @param {string} action the action to check.
   * @returns {boolean}
   */
  canAction = action => this.list.some(item => item.endsWith(SEPARATOR + action));

  /**
   * Check if current user can create a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canCreate = resource => this.can(resource, 'post');

  /**
   * Check if current user cannot create a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  cantCreate = resource => !this.canCreate(resource);

  /**
   * Check if current user can delete a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canDelete = resource => this.can(resource, 'delete');

  /**
   * Check if current user cannot delete a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  cantDelete = resource => !this.canDelete(resource);

  /**
   * Check if current user can edit a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canEdit = resource => this.can(resource, 'put');

  /**
   * Check if current user cannot edit a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  cantEdit = resource => !this.canEdit(resource);

  /**
   * Check if current user can list a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canList = resource => this.can(resource, 'get');

  /**
   * Check if current user cannot list a resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  cantList = resource => !this.canList(resource);

  /**
   * Check if current user can read a single resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  canShow = resource => this.can(resource, 'get');

  /**
   * Check if current user cannot read a single resource.
   * @param {string} resource the resource to check.
   * @returns {boolean}
   */
  cantShow = resource => !this.canShow(resource);
}
