import { HttpError } from 'react-admin';
import { PRINT } from '../actions';

/**
 * Retrieve filename from response headers.
 * If no Content-Disposition header or no filename attribute, return timestamp.
 * @param {object} response the response from server.
 * @returns {string}
 */
function getFileName(response) {
  const header = response.headers.get('Content-Disposition');
  if (header) {
    const match = header.match(/filename=(.+)/);
    if (match && match.length > 1) {
      return match[1];
    }
  }

  return Date.now().toString();
}

/**
 * Download a file.
 * @param {object} body the blob to download (bytecode).
 * @param {string} filename the name to set for the downloaded file.
 * @see https://gist.github.com/dreamyguy/6b4ab77d2f118adb8a63c4a03fba349d
 * @see https://stackoverflow.com/a/43133108/9925718
 */
export const downloadBlob = (body, filename) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // Thanks IE! Probably the only time I thank IE in my life :)
    window.navigator.msSaveBlob(body, filename);
  } else {
    const objectUrl = window.URL.createObjectURL(body);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.setAttribute('download', filename);
    // We only want to set _blank target if the browser does not support
    // the HTML5 download attribute.
    if (typeof anchor.download === 'undefined') {
      anchor.setAttribute('target', '_blank');
    }
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      // Fixed in Firefox 50
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(objectUrl);
    }, 100);
  }
};

/**
 * Fetch a blob from server and download it.
 * @param {string} url the url to reach.
 * @param {*} options the request options.
 */
export const fetchBlob = (url, type, options = {}) => {
  let win;
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  if (type === PRINT) {
    // open temporary window,
    // may be used later to display downloaded file if printing is not possible.
    win = window.open('about:blank', '_blank');
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.blob().then(blob => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: blob,
        filename: getFileName(response),
        contentType: response.headers.get('Content-Type'),
      }))
    )
    .then(({ status, statusText, headers, body, filename }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError((json && json.message) || statusText, status, json));
      }

      if (type === PRINT) {
        const objectUrl = window.URL.createObjectURL(body);

        const currentBody = document.body;
        const closePrint = container => () => {
          currentBody.removeChild(container);
        };
        const printFrame = 'printblobhiddeniframe';

        // in some browsers, previous dom element is not removed by `onbeforeunload` / `onafterprint`.
        var hiddenIFrame = document.getElementById(printFrame) || document.createElement('iframe');
        hiddenIFrame.onbeforeunload = closePrint(hiddenIFrame);
        hiddenIFrame.onafterprint = closePrint(hiddenIFrame);
        hiddenIFrame.style.display = 'none';
        hiddenIFrame.src = objectUrl;
        hiddenIFrame.name = printFrame;
        hiddenIFrame.id = printFrame;

        // @see https://stackoverflow.com/a/38800755/9925718
        // @see https://developer.mozilla.org/en-US/docs/Web/Guide/Printing#Print_an_external_page_without_opening_it
        hiddenIFrame.onload = () => {
          // when iframe has been mounted into DOM, print it
          try {
            // try to print hidden iframe.
            window.frames[printFrame].print();
            if (win && win.close) {
              // don't know why, but the window may be closed immedialty after opening.
              win.close();
            }
          } catch (err) {
            // access to window.frames[printFrame] can be blocked by some browsers (cross origin).
            // try to open blob in the new window we opened previously.
            try {
              // remove hidden iframe, will not be used
              closePrint(hiddenIFrame);
              //display blob in new window
              win.location.href = objectUrl;
              win.focus();
            } catch (err) {
              // if this method fails too, download file.
              downloadBlob(body, filename);
            }
          } finally {
            window.URL.revokeObjectURL(objectUrl);
          }
        };

        document.body.appendChild(hiddenIFrame);
      } else {
        // download file
        downloadBlob(body, filename);
      }

      return { status, headers, body, blob: body };
    });
};
