import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { professionalPosition } from '../../../validators';
import { CustomCreateActions, Toolbar } from '../../framework';

export const ProfessionalPositionCreate = props => (
  <Create {...props} actions={<CustomCreateActions />}>
    <SimpleForm redirect={REDIRECT_AFTER_CREATE} toolbar={<Toolbar />}>
      <TextInput source="name" validate={professionalPosition.name} />
    </SimpleForm>
  </Create>
);
