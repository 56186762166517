import React from 'react';
import { Filter } from 'react-admin';
import { TextSearchInput } from '../../framework';

export const UserFilter = props => (
  <Filter {...props}>
    <TextSearchInput source="name" alwaysOn />
    <TextSearchInput source="email" alwaysOn />
  </Filter>
);
