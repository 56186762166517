import { TRANSITION_GET_SUCCESS } from '../actions';

/**
 * Set transitions in state once loaded from server.
 */
export default (previousState, { type, payload }) => {
  const newState = Object.assign({}, previousState);

  if (type === TRANSITION_GET_SUCCESS) {
    newState[payload.resource] = payload.data;
  }

  return newState;
};
