import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, DateField, List, Responsive, SimpleList, TextField } from 'react-admin';
import { Candidate } from '../../../models';
import { ObjectStateSelect } from '../../ObjectState';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  DeleteConfirmButton,
  EmailField,
  PhoneField,
  RepeatedStringField,
  defaultPerPage,
} from '../../framework';
import CandidateFilter from './CandidateFilter';

const sortOrder = { field: 'created_at', order: 'DESC' };
const getEmails = record => (
  <RepeatedStringField source="emails" orientation="column" record={record}>
    <TextField />
  </RepeatedStringField>
);
const bulkActions = <BulkDeleteConfirmButton />;

export const CandidateList = props => (
  <List
    {...props}
    bulkActionButtons={props.options.hasDelete ? bulkActions : false}
    filters={<CandidateFilter permissions={props.permissions} />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sortOrder}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={Candidate.getFullName}
          secondaryText={getEmails}
          tertiaryText={record => (
            <ObjectStateSelect
              choices={Candidate.OBJECT_STATE_ID.values}
              readOnly
              record={record}
              source="object_state_id"
            />
          )}
        />
      }
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <DateField source="candidacy_date" />
          <ObjectStateSelect
            source="object_state_id"
            choices={Candidate.OBJECT_STATE_ID.values}
            readOnly
          />
          <TextField source="last_name" />
          <TextField source="first_name" />
          <RepeatedStringField source="phones" orientation="column">
            <PhoneField />
          </RepeatedStringField>
          <RepeatedStringField source="emails" orientation="column">
            <EmailField />
          </RepeatedStringField>
          <RepeatedStringField source="sectors" />
          <RepeatedStringField source="sub_sectors" />
          <RepeatedStringField source="department_mobility" />
          <TextField source="source" />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

CandidateList.propTypes = {
  hasEdit: PropTypes.bool,
  permissions: PropTypes.object,
  options: PropTypes.object,
};
