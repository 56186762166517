import isPlainObject from 'lodash/isPlainObject';
import { stringify } from 'query-string';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  HttpError,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import {
  CREATE_MANY,
  DOWNLOAD,
  DOWNLOAD_STATIC,
  METHOD_GET,
  METHOD_MANY_POST,
  METHOD_POST,
  OBJECT_STATE_ID_UPDATE,
  PRINT,
  TRANSITION_GET,
} from '../actions';
import { MODEL_API_URL, PER_PAGE_REFERENCES } from '../settings';
import { fetchBlob } from '../utils/fetch';
import { isEmpty, isNotEmpty, isObject } from '../utils/tools';
import { Session } from './session';
import addUploadFeature from './upload';

const REQUEST_KIND = Object.freeze({
  JSON: 'json',
  BLOB: 'blob',
});

// {keyInCurrentParams: keyInRestRequest}
const PROTECTED_FILTERS = {
  withCount: 'with_count',
  withMethods: 'with_methods',
  withMethodsParams: 'method_parameters',
  withRelations: 'with',
};

/**
 * Transform filters of react-admin framework to a format readable by remote API.
 * @param {*} filters The filters to send to the API.
 */
function generateApiFilters(filters) {
  let finalFilters = [];

  Object.keys(filters).forEach(key => {
    const value = filters[key];
    let positive = true;
    let operator = false;
    const specialOperators = ['>=', '<=', '=', '>', '<'];

    if (key.startsWith('!')) {
      positive = false;
      key = key.substr(1);
    }

    specialOperators.forEach(op => {
      if (key.startsWith(op)) {
        operator = op;
        key = key.substr(op.length);
      }
    });

    var regex = /-dot-/gi;
    if (key.match(regex)) {
      // if key contains a `.` in its name, redux doesn't preserve it and set a nested structure.
      // see https://github.com/marmelab/react-admin/issues/2102 and https://github.com/api-platform/admin/issues/110
      key = key.replace(regex, '.');
    }

    if (value === null || typeof value === 'undefined') {
      finalFilters.push({
        field: key,
        operator: positive ? '=' : '!=',
        value: null,
      });
    }

    if (typeof value === 'string' && !isEmpty(value)) {
      if (operator) {
        finalFilters.push({
          field: key,
          operator: positive ? operator : `!${operator}`,
          value,
        });
      } else {
        finalFilters.push({
          field: key,
          operator: positive ? 'like' : 'not like',
          value: `%${value}%`,
        });
      }
    }

    if (['number', 'boolean'].includes(typeof value) && !isEmpty(value)) {
      if (operator) {
        finalFilters.push({
          field: key,
          operator: positive ? operator : `!${operator}`,
          value: value,
        });
      } else {
        finalFilters.push({
          field: key,
          operator: positive ? '=' : '!=',
          value: value,
        });
      }
    }

    if (Array.isArray(value) && !isEmpty(value)) {
      finalFilters.push({
        field: key,
        operator: positive ? 'in' : 'not in',
        value: value,
      });
    }

    if (isObject(value)) {
      if (value.polymorph) {
        // if polymorph field, do not include original key but set partner/source id & type.
        const newValue = Object.assign({}, value);
        delete newValue.polymorph;
        finalFilters = [...finalFilters, ...generateApiFilters(newValue)];
      } else if (value.multi) {
        // multiple criteria on the same field
        if (Array.isArray(value.filters)) {
          finalFilters = value.filters.reduce(
            (acc, filter) => [...acc, ...generateApiFilters(filter)],
            finalFilters
          );
        }
      } else {
        // composed filter
        const newFilter = Object.assign(
          {
            field: key,
            operator: positive ? '=' : '!=',
          },
          value
        );

        if (newFilter.value !== undefined) {
          finalFilters.push(newFilter);
        }
      }
    }
  });

  return finalFilters;
}

/**
 * Transform react-admin request to be understood by talento API.
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  let url;
  let requestKind = REQUEST_KIND.JSON;
  const options = {
    headers: new Headers({ Accept: 'application/json' }),
  };

  const access_token = Session.getAccessToken();

  if (isNotEmpty(access_token)) {
    options.headers.set('Authorization', `Bearer ${access_token}`);
  }

  const pagination = Object.assign({}, params.pagination);
  const sort = Object.assign({}, params.sort);
  const filter = Object.assign({}, params.filter);

  switch (type) {
    case GET_MANY_REFERENCE: {
      const source = params.source ? params.source : 'id';
      filter[params.target] = params[source];
    } // eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]

    case GET_LIST: {
      const { field, order } = sort;
      const { q } = filter;
      delete filter.q;

      const protectedFilterValues = {};
      Object.keys(PROTECTED_FILTERS).forEach(key => {
        protectedFilterValues[key] = filter[key];
        delete filter[key];
      });

      const query = {
        per_page: pagination.perPage,
        page: pagination.page,
        filters: JSON.stringify(generateApiFilters(filter)),
        q,
      };

      if (field) {
        query.sort = order === 'DESC' ? `-${field}` : `${field}`;
      }

      Object.entries(protectedFilterValues).forEach(([key, value]) => {
        if (
          ((typeof value === 'string' || Array.isArray(value)) && value.length > 0) ||
          (isPlainObject(value) && Object.keys(value).length > 0)
        ) {
          query[PROTECTED_FILTERS[key]] = JSON.stringify(value);
        }
      });

      url = `${MODEL_API_URL}/${resource}?${stringify(query)}`;
      break;
    }

    case GET_ONE:
      url = `${MODEL_API_URL}/${resource}/${params.id}`;
      if (params.data) {
        url = `${url}?${stringify(params.data)}`;
      }
      break;

    case GET_MANY: {
      let paramName = 'id';
      let values = params.ids.map(val => parseInt(val, 10));
      let perPage = (pagination && pagination.perPage) || values.length;

      if (['candidate_source', 'degree', 'professional_position', 'tag'].includes(resource)) {
        // link is not made on ID but on name.
        paramName = 'name';
        values = params.ids.slice();
        perPage = (pagination && pagination.perPage) || PER_PAGE_REFERENCES;
      }

      const protectedFilterValues = {};
      Object.keys(PROTECTED_FILTERS).forEach(key => {
        protectedFilterValues[key] = params[key];
        delete filter[key];
      });

      let query = {
        filters: JSON.stringify(generateApiFilters({ [paramName]: values })),
        per_page: perPage,
      };

      Object.entries(protectedFilterValues).forEach(([key, value]) => {
        if (
          ((typeof value === 'string' || Array.isArray(value)) && value.length > 0) ||
          (isPlainObject(value) && Object.keys(value).length > 0)
        ) {
          query[PROTECTED_FILTERS[key]] = JSON.stringify(value);
        }
      });

      url = `${MODEL_API_URL}/${resource}/?${stringify(query)}`;
      break;
    }

    case CREATE:
      url = `${MODEL_API_URL}/${resource}`;
      options.method = 'POST';
      options.body = JSON.stringify(params.data);
      break;

    case CREATE_MANY:
      url = `${MODEL_API_URL}/${resource}/many`;
      options.method = 'POST';
      options.body = JSON.stringify(params.data);
      break;

    case UPDATE:
      url = `${MODEL_API_URL}/${resource}/${params.data.id}`;
      options.method = 'PUT';
      options.body = JSON.stringify(params.data);
      break;

    case DELETE:
      url = `${MODEL_API_URL}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;

    case DELETE_MANY:
      url = `${MODEL_API_URL}/${resource}/many/${JSON.stringify(
        params.ids.map(val => parseInt(val, 10))
      )}`;
      options.method = 'DELETE';
      break;

    case METHOD_GET:
      url = `${MODEL_API_URL}/${resource}/method/${params.methodName}?${stringify(params.data)}`;
      break;

    case METHOD_POST:
      url = `${MODEL_API_URL}/${resource}/${params.id}/method/${params.methodName}`;
      options.body = JSON.stringify(params.data);
      options.method = 'POST';
      break;

    case METHOD_MANY_POST: {
      url = `${MODEL_API_URL}/${resource}/many/${JSON.stringify(params.ids)}/method/${
        params.methodName
      }`;
      options.body = JSON.stringify(params.data);
      options.method = 'POST';
      break;
    }

    case TRANSITION_GET:
      url = `${MODEL_API_URL}/${resource}/get_object_transitions`;
      break;

    case OBJECT_STATE_ID_UPDATE:
      url = `${MODEL_API_URL}/${resource}/${params.id}/set_object_state`;
      options.body = JSON.stringify(params.data);
      options.method = 'PUT';
      break;

    case PRINT: {
      let id = params.id;
      let singleOrMany = '';

      if (Array.isArray(id)) {
        id = JSON.stringify(id.map(val => parseInt(val, 10)));
        singleOrMany = '/many';
      }

      url = `${MODEL_API_URL}/${resource}${singleOrMany}/${id}/print?${stringify(params.data)}`;
      requestKind = REQUEST_KIND.BLOB;
      break;
    }

    case DOWNLOAD:
      requestKind = REQUEST_KIND.BLOB;
      url = `${MODEL_API_URL}/${resource}/${params.id}/download/${params.methodName}`;
      options.body = JSON.stringify(params.data);
      options.method = 'POST';
      break;

    case DOWNLOAD_STATIC: {
      requestKind = REQUEST_KIND.BLOB;
      url = `${MODEL_API_URL}/${resource}/download/${params.methodName}?${stringify(params.data)}`;
      break;
    }

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }

  return { url: url, options: options, kind: requestKind };
};

/**
 * Parse talento API response to be understood by react-admin.
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { json } = response;
  switch (type) {
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE:
      return {
        data: json.data || [],
        total: json.total || 0,
      };

    case CREATE:
    case UPDATE:
      return { data: { ...json, id: json.id } };

    case DELETE:
      return { data: Object.assign({}, params.previousData, json) };

    case TRANSITION_GET: {
      const data = {};
      Object.entries(json).forEach(([key, value]) => (data[Number(key)] = value));
      return { data, resource };
    }

    default:
      return { data: json };
  }
};

/**
 * Send request to talento API.
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
const api = (type, resource, params) => {
  let fetchMethod;
  let fetchParams;
  const { url, options, kind } = convertDataProviderRequestToHTTP(type, resource, params);

  if (kind === REQUEST_KIND.JSON) {
    const { fetchJson } = fetchUtils;
    fetchMethod = fetchJson;
    fetchParams = [url, options];
  } else if (kind === REQUEST_KIND.BLOB) {
    fetchMethod = fetchBlob;
    fetchParams = [url, type, options];
  } else {
    throw new Error(`Unsupported request kind '${type}'`);
  }

  return fetchMethod(...fetchParams)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params))
    .catch(err => {
      if (err && err.body && isNotEmpty(err.body.exception)) {
        // render translated error
        err.message = `error.${err.body.exception}`;
      }

      if (err.body.fields) {
        throw new HttpError(`error.${err.body.error}`, err.status, err.body);
      }

      throw err;
    });
};

export default addUploadFeature(api);
