import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from 'react-admin';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const thousandsSeparatorSymbol = ' ';

const numberMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol,
});

const NumberMaskInput = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      keepCharPositions
      mask={numberMask}
      ref={inputRef}
      showMask
    />
  );
};

NumberMaskInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

/**
 * Transform string formated number into a number.
 * @param {string} value the value to parse.
 * @returns {number}
 */
const parse = value => {
  if (typeof value === 'string') {
    return value.replace(/\s/g, '');
  }

  return value;
};

const ThousandsNumberInput = props => (
  <TextInput {...props} InputProps={{ inputComponent: NumberMaskInput }} parse={parse} />
);

export default ThousandsNumberInput;
