export default {
  candidate: {
    add_candidate_customer_button: 'Ajouter une nouvelle candidature',
    driving_licence: {
      yes: 'Oui',
      no: 'Non',
      in_progress: 'En cours',
    },
    state: {
      new: 'Nouveau',
      waiting: 'En attente',
      reminder: 'Relance',
      call_planned: 'Appel planifié',
      voicemail: 'Messagerie',
      refused: 'Refus',
      breeding_ground: 'Vivier',
      seen_and_linked: 'Vu et rattaché',
      blacklisted: 'Blacklisté',
    },
    tabs: {
      candidate: 'Candidat',
      candidate_customers: 'Candidatures',
    },
    vehicule: {
      yes: 'Oui',
      no: 'Non',
      in_progress: 'En cours',
    },
  },
  candidate_order: {
    state: {
      waiting: 'En attente',
      talento_interview: 'Entretien Talento',
      talento_review: 'Point Talento',
      sent_customer: 'Envoyé au client',
      customer_interview_pending: 'Entretien client',
      refused_talento: 'Refusé Talento',
      refused_customer: 'Refusé client',
      candidate_cancel: 'Abandon candidat',
      done: 'Validé',
      invoiced: 'Facturé',
      closed: 'Clôturé',
    },
  },
  customer: {
    state: {
      disabled: 'Inactif',
      enabled: 'Actif',
    },
    tabs: {
      general: 'Client',
      contacts: 'Contacts',
      orders: 'Commandes',
    },
  },
  domains_level: {
    beginner: 'Junior',
    intermediate: 'Intermédiaire',
    expert: 'Expert',
  },
  invoice: {
    state: {
      waiting: 'En attente',
      paid: 'Réglée',
      unpaid: 'Impayée',
    },
  },
  mail: {
    body_help: 'Liste des paramètres disponibles: \n%{placeholders}',
  },
  order: {
    state: {
      draft: 'Devis',
      inProgress: 'En cours',
      done: 'Terminé',
      cancelled: 'Annulé',
    },
    tabs: {
      general: 'Commande',
      invoices: 'Factures',
      candidates: 'Candidats',
    },
    add_candidate_button: 'Ajouter candidat',
  },
  permission: {
    action: {
      add_role: 'Associer un rôle',
      custom: 'Personnalisé',
      delete: 'Supprimer',
      get: 'Lire',
      post: 'Créer',
      put: 'Modifier',
      remove_role: 'Enlever un rôle',
      // custom actions
      check_phone: 'Vérifier n° téléphone',
      check_email: 'Vérifier adresse email',
      download: 'Télécharger',
      generate_reporting: 'Créer un rapport client',
      send_email: "Envoyer l'email",
      set_object_state: 'Modifier le statut',
    },
  },
  reporting: {
    email_status: {
      not_sent: 'Non envoyé',
      sent: 'Envoyé',
    },
  },
  user: {
    tabs: {
      general: 'Utilisateur',
      roles: 'Rôles',
    },
    role: {
      add_membership: 'Ajouter le rôle %{name}',
      remove_membership: 'Retirer le rôle %{name}',
      is_member: 'Membre ?',
    },
  },
};
