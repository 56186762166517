import inflection from 'inflection';
import adminFrench from 'ra-language-french';
import french from './fr';

export const messages = {
  fr: { ...adminFrench, ...french },
};

export const UI_LANGS = ['fr'];

export const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

/**
 * Get a structure to include langs in a selector.
 * @param {array} langs an array of languages codes (fr, en, etc.).
 * @returns {array} an array of objects to use in a selector.
 */
export const getLangsChoices = (langs = []) =>
  langs.map(lang => ({ id: lang, name: lang.toUpperCase() }));

export default locale => messages[locale];
