import { DOWNLOAD, DOWNLOAD_STATIC } from '.';

/**
 * Call a download method on a resource.
 * @param {string} resource the type of the resource.
 * @param {strng} methodName the method name.
 * @param {object} data the data to send as params for the method.
 * @param {string} basePath the base path.
 * @param {string|bool} redirectTo the redirection to execute after request success.
 * @param {*} options others parameters.
 */
export const download = (
  methodName = 'download',
  resource,
  id,
  data,
  basePath,
  redirectTo = false,
  options = {}
) => ({
  type: DOWNLOAD,
  payload: {
    id,
    data,
    methodName,
  },
  meta: {
    resource: resource,
    fetch: DOWNLOAD,
    onSuccess: {
      redirectTo,
      basePath,
      unselectAll: options.unselectAll,
      callback: options.callbackSuccess,
    },
    onFailure: {
      notification: !options.callbackError && {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
      callback: options.callbackError,
    },
  },
});

/**
 * Call a download method on a model class.
 * @param {string} resource the type of the resource.
 * @param {strng} methodName the method name.
 * @param {object} data the data to send as params for the method.
 * @param {string} basePath the base path.
 * @param {string|bool} redirectTo the redirection to execute after request success.
 * @param {*} options others parameters.
 */
export const downloadStatic = (
  methodName = 'download',
  resource,
  data,
  basePath,
  redirectTo = false,
  options = {}
) => ({
  type: DOWNLOAD,
  payload: {
    methodName,
    data,
  },
  meta: {
    resource: resource,
    fetch: DOWNLOAD_STATIC,
    onSuccess: {
      redirectTo,
      basePath,
      unselectAll: options.unselectAll,
      callback: options.callbackSuccess,
    },
    onFailure: {
      notification: !options.callbackError && {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
      callback: options.callbackError,
    },
  },
});
