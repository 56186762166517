import React from 'react';
import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { user } from '../../../validators';
import { CustomCreateActions, Toolbar } from '../../framework';

export const UserCreate = props => (
  <Create {...props} actions={<CustomCreateActions />}>
    <SimpleForm redirect={REDIRECT_AFTER_CREATE} toolbar={<Toolbar />}>
      <TextInput source="name" validate={user.name} />
      <TextInput source="email" validate={user.email} />
      <TextInput source="password" validate={user.password.create} type="password" />
      <TextInput source="repassword" validate={user.password.create} type="password" />
      <BooleanInput source="enabled" validate={user.enabled} />
    </SimpleForm>
  </Create>
);
