import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fetchUtils, showNotification, translate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { RESET_PASSWORD_URL, PASSWORD_MIN_LENGTH } from '../../settings';
import { Session } from '../../data-provider/session';

const styles = ({ spacing }) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
    header: {
      marginTop: '1em',
    },
    buttonLink: {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      outline: 'none',
      margin: 0,
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderConnectedInput = ({
  meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class ResetPasswordForm extends React.Component {
  state = { isLoading: false };

  /**
   * Retrieve reset token and user email from url.
   * @returns {object}
   */
  getFormHiddenParams = () => {
    const { match } = this.props;
    return match.params || {};
  };

  /**
   * Send request to server.
   */
  handleSubmit = (payload, dispatch, { redirectTo = '/login' }) => {
    const { showNotification } = this.props;

    const sendRequest = () =>
      fetchUtils
        .fetchJson(RESET_PASSWORD_URL, {
          method: 'POST',
          body: JSON.stringify({ ...payload, ...this.getFormHiddenParams() }),
        })
        .then(() => {
          showNotification('auth.reset_password_success');
          dispatch(push(redirectTo));
        })
        .catch(e => {
          showNotification('auth.reset_password_error', 'warning');
        })
        .finally(() => this.setState({ isLoading: false }));

    this.setState({ isLoading: true }, sendRequest);
  };

  /**
   * Redirect to login form.
   */
  handleCancelClick = event => {
    event.stopPropagation();
    Session.redirectToLogin();
  };

  render() {
    const { classes, handleSubmit, translate } = this.props;
    const { isLoading } = this.state;

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={classes.form}>
          <div className={classes.header}>
            <Typography align="center" gutterBottom variant="title">
              {translate('auth.reset_password_form.title')}
            </Typography>
            <Typography gutterBottom>
              {translate('auth.reset_password_form.description')}
            </Typography>
          </div>
          <div className={classes.input}>
            <Field
              autoFocus
              id="password"
              name="password"
              component={renderConnectedInput}
              label={translate('ra.auth.password')}
              type="password"
              disabled={isLoading}
              autoComplete="current-password"
            />
          </div>
          <div className={classes.input}>
            <Field
              id="password_confirmation"
              name="password_confirmation"
              component={renderConnectedInput}
              label={translate('auth.reset_password_form.password_confirmation')}
              type="password"
              disabled={isLoading}
              autoComplete="current-password_confirmation"
            />
          </div>
        </div>
        <CardActions>
          <Button
            variant="raised"
            type="submit"
            color="primary"
            disabled={isLoading}
            className={classes.button}
          >
            {isLoading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
            {translate('auth.reset_password_form.submit')}
          </Button>
        </CardActions>
        <CardActions>
          <button className={classes.buttonLink} onClick={this.handleCancelClick} type="button">
            <Typography color="primary">{translate('ra.action.back')}</Typography>
          </button>
        </CardActions>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  translate,
  connect(undefined, { showNotification }),
  reduxForm({
    form: 'resetPassword',
    validate: (values, props) => {
      const errors = { password: '', password_confirmation: '' };
      const { translate } = props;

      if (!values.password) {
        errors.password = translate('ra.validation.required');
      } else if (values.password.length < PASSWORD_MIN_LENGTH) {
        errors.password = translate('ra.validation.minLength', { min: PASSWORD_MIN_LENGTH });
      } else if (!values.password_confirmation) {
        errors.password_confirmation = translate('ra.validation.required');
      } else if (values.password !== values.password_confirmation) {
        errors.password = translate('validation.passwords_not_equal');
        errors.password_confirmation = translate('validation.passwords_not_equal');
      }

      return errors;
    },
  })
);

const EnhancedResetPasswordForm = enhance(ResetPasswordForm);

EnhancedResetPasswordForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default EnhancedResetPasswordForm;
