export default {
  candidate: {
    name: 'Candidat |||| Candidats',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_by_user_id: 'Modifié par',
      updated_at: 'Date de dernière modif.',
      duplicate_id: 'Duplique',
      object_state_id: 'Statut',
      title: 'Civilité',
      first_name: 'Prénom',
      last_name: 'Nom',
      phones: 'Téléphone',
      emails: 'Email',
      source: 'Provenance',
      has_driving_license: 'Titulaire du permis de conduire',
      vehicule: 'Véhiculé',
      registred_job_center: 'Inscrit Pôle Emploi',
      diploma: 'Diplôme',
      department_mobility: 'Mobilité départementale',
      exact_mobility: 'Mobilité précise',
      mobility_note: 'Précisions mobilité',
      salary_min: 'Salaire brut annuel min.',
      sectors: 'Secteurs',
      sub_sectors: 'Sous-Secteurs',
      candidacy_date: 'Date de candidature',
      upload_files: 'Fichiers joints',
      comments: 'Commentaires',
      comment: {
        date: 'Date',
        note: 'Commentaire',
      },
      is_top_profile: 'Top Profil',
      professional_positions: 'Poste souhaité',
      is_coach_job: 'Emploi coach',
      coach_job_comment: 'Commentaire Emploi Coach',
    },
  },
  candidate_customer: {
    name: 'Candidature |||| Candidatures',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      candidate_id: 'Candidat',
      customer_id: 'Client',
      user_id: 'Collaborateur',
      candidacy_date: 'Date de candidature',
      customer_business_name: 'Enseigne client',
      job_title: 'Poste',
      job_location: 'Lieu du poste',
      source: 'Provenance',
      sms_date: 'Date envoi SMS',
      phone_meeting_date: 'Date RDV téléphonique',
      comment: 'Commentaire',
    },
  },
  candidate_order: {
    name: 'Suivi de candidature |||| Suivi des candidatures',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      candidate_id: 'Candidat',
      order_id: 'Commande',
      object_state_id: 'Statut',
      real_meeting_date: 'Entretien Talento',
      real_meeting_employee_id: 'Collaborateur entretien Talento',
      post_meeting_date: 'Point post entretien',
      customer_file_sent_date: 'Envoi dossier client',
      customer_interview_date: 'Entretien client',
      coaching_meeting_date: 'Rendez-vous coaching Talento',
      customer_second_interview_date: 'Deuxième entretien client',
      job_start_date: 'Début du contrat',
      invoice_sent_at: "Date d'envoi de facture",
      internal_reporting_note: 'Avancement du recrutement TALENTO',
      reporting_note: 'Avancement du recrutement CLIENT',
      reporting: {
        date: 'Date',
        note: 'Note',
      },
      archive_box_number: "Numéro de boîte d'archivage",
      references_validated: 'Références validées',
      waiting_date: 'En attente',
    },
  },
  candidate_source: {
    name: 'Provenance |||| Provenances',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
    },
  },
  customer: {
    name: 'Client |||| Clients',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      object_state_id: 'Statut',
      name: 'Raison sociale',
      logo: 'Logo',
      parent_company_name: 'Société mère',
      business_name: 'Enseigne',
      siret: 'Siret',
      address_name: "Nom de l'adresse",
      address_line_1: 'Rue',
      address_line_2: 'Lieu dit / Bâtiment',
      address_line_3: 'Boîte postale',
      address_zipcode: 'Code postal',
      address_city: 'Ville',
      use_deposit: 'Avec acompte',
      domains: 'Domaines',
      comments: 'Commentaires',
      locations: 'Sites géographiques',
      contacts: 'Contacts',
    },
  },
  contact: {
    name: 'Contact |||| Contacts',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      title: 'Civilité',
      first_name: 'Prénom',
      last_name: 'Nom',
      phone: 'Téléphone',
      email: 'Email',
      role: 'Rôle',
      is_main: 'Interlocuteur principal',
    },
  },
  degree: {
    name: 'Diplôme |||| Diplômes',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
    },
  },
  invoice: {
    name: 'Facture |||| Factures',
    fields: {
      number: 'Numéro de facture',
      object_state_id: 'Statut',
    },
  },
  mail: {
    name: 'Template email |||| Templates emails',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      type: "Type d'email",
      subject: 'Sujet',
      body: "Contenu de l'email",
      cc: 'Copie (cc)',
      bcc: 'Copie cachée (cci)',
    },
  },
  order: {
    name: 'Commande |||| Commandes',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      object_state_id: 'Statut',
      customer_id: 'Client',
      quotation_number: 'N° devis',
      quotation_comments: 'Commentaires devis',
      ordered_at: 'Date de la commande',
      offer_registred: 'Offre déposée',
      use_deposit: 'Acompte',
      deposit_payed: 'Acompte versé',
      deposit_invoice_number: 'Numéro facture acompte',
      invoices: 'Facture(s)',
      job_title: 'Poste recherché',
      job_qualification: 'Qualification recherchée',
      job_start_date: 'Date de début',
      salary_min: 'Salaire brut annuel min.',
      salary_max: 'Salaire brut annuel max.',
      location: 'Lieu du poste',
      cancel_date: 'Date annulation',
      cancel_reason: 'Raison annulation',
      upload_files: 'Fichiers joints',
    },
  },
  permission: {
    name: 'Permission |||| Permissions',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
      guard_name: 'Nom de la garde',
    },
  },
  professional_position: {
    name: 'Poste |||| Postes',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
    },
  },
  reporting: {
    name: 'Rapport client |||| Rapports clients',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      customer_id: 'Client',
      data: 'Rapport',
      sent_at: "Date d'envoi client",
      email_status: 'Email envoyé ?',
    },
  },
  role: {
    name: 'Rôle |||| Rôles',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
      guard_name: 'Nom de la garde',
    },
  },
  tag: {
    name: 'Suggestion |||| Suggestions',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
      type: 'Type',
    },
  },
  user: {
    name: 'Utilisateur |||| Utilisateurs',
    fields: {
      id: 'ID',
      created_at: 'Date de création',
      updated_at: 'Date de dernière modif.',
      name: 'Nom',
      enabled: 'Compte actif',
      email: 'Email',
      password: 'Mot de passe',
      repassword: 'Confirmer le mot de passe',
    },
  },
};
