import PropTypes from 'prop-types';
import React from 'react';
import { ImageField } from 'react-admin';
import { buildUploadUrl } from '../../../utils/images';

/**
 * Transform url into object
 * @param {string} record the current url.
 * @param {string} source the attribute to set.
 */
const generateFakeFile = (record, source) => {
  const url = buildUploadUrl(record);
  return { [source]: url, rawFile: { preview: url } };
};

/**
 * Image field with consolidated url.
 */
const UploadedImageField = ({ record, ...props }) => {
  const fakeRecord =
    typeof record === 'string' ? generateFakeFile(record, props.source) : Object.assign({}, record);

  return <ImageField {...props} record={fakeRecord} />;
};

UploadedImageField.propTypes = {
  record: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  source: PropTypes.string,
};

export default UploadedImageField;
