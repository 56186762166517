import { UPLOAD_FOLDER_PATH } from '../settings';

/**
 * Check if a string is base64 encoded.
 * @param {string} v the value to check.
 * @param {object} opts options for decoding.
 * @returns {boolean}
 * @see https://github.com/miguelmota/is-base64
 */
export const isBase64 = (v, opts) => {
  if (v instanceof Boolean || typeof v === 'boolean') {
    return false;
  }
  if (!(opts instanceof Object)) {
    opts = {};
  }
  if (opts.hasOwnProperty('allowBlank') && !opts.allowBlank && v === '') {
    return false;
  }

  var regex = /^(data:\w+\/[a-zA-Z+\-.]+;base64,)?(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/gi;

  if (opts.paddingRequired === false) {
    regex = /^(data:\w+\/[a-zA-Z+-.]+;base64,)?(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}(==)?|[A-Za-z0-9+/]{3}=?)?$/gi;
  }

  return regex.test(v);
};

/**
 * Build an absolute url for an image file.
 * @param {string} url the file url.
 * @returns {string}
 */
export const buildUploadUrl = url =>
  url && !isBase64(url) && url.indexOf('://') === -1 ? `${UPLOAD_FOLDER_PATH}${url}` : url;
