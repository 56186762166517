import Typography from '@material-ui/core/Typography';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import PropTypes from 'prop-types';
import { DateField, Edit, SimpleForm, TextInput, translate } from 'react-admin';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { mailValidators } from '../../../validators';
import { CustomEditActions, Toolbar, PageTitle } from '../../framework';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Mail } from '../../../models';

/**
 * Wrapper to handle warning on form properties send to Typography component.
 */
const BodyHelper = translate(({ record, translate }) => (
  <Typography variant="caption">
    {translate('form.mail.body_help', {
      _: 'form.mail.body_help',
      placeholders: Object.entries(Mail.getTokensForTemplate(record.type))
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n'),
    })
      .split('\n')
      .map((item, i) => (
        <div key={i}>{item}</div>
      ))}
  </Typography>
));

const styles = theme =>
  createStyles({
    [theme.palette.type === 'dark' && 'richTextInput']: {
      '& > div .ra-rich-text-input': {
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing.unit,
      },
    },
  });

const MailEdit = ({ classes, ...props }) => (
  <Edit
    actions={<CustomEditActions {...props} />}
    title={<PageTitle {...props} source="type" />}
    undoable={false}
    {...props}
  >
    <SimpleForm toolbar={<Toolbar hasDelete={false} />} redirect={REDIRECT_AFTER_EDIT}>
      <TextInput source="subject" validate={mailValidators.subject} />
      <TextInput source="cc" validate={mailValidators.cc} />
      <TextInput source="bcc" validate={mailValidators.bcc} />
      <RichTextInput
        className={classes.richTextInput}
        source="body"
        validate={mailValidators.body}
      />
      <BodyHelper fullWidth />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </SimpleForm>
  </Edit>
);

MailEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MailEdit);
