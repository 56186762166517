import { CRUD_GET_ONE, CRUD_UPDATE } from 'react-admin';
import { METHOD_GET, METHOD_MANY_POST, METHOD_POST } from '.';

/**
 * Call a method on a resource.
 * @param {string} methodName the name of the method to call.
 * @param {string} resource the type of the resource.
 * @param {string|number} id the ID of the resource.
 * @param {object} data the data to send as params for the methodd.
 * @param {object} previousData the previous record data.
 * @param {string} basePath the base path for the resource.
 * @param {string|bool} redirectTo the page to redirect to after executing the method,
 *  or `false` to not redirect (default: `false`).
 * @param {bool} refresh refresh after executing the method (default: `false`)
 * @param {*} options others parameters.
 */
export const executeMethodPost = (
  methodName,
  resource,
  id,
  data,
  previousData,
  basePath,
  redirectTo = false,
  options = {}
) => ({
  type: options.type || CRUD_UPDATE,
  payload: {
    id,
    data,
    methodName,
    previousData,
  },
  meta: {
    resource: resource,
    fetch: METHOD_POST,
    basePath,
    onSuccess: {
      notification: !options.callbackSuccess && {
        body: options.notification_success || 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo,
      basePath,
      refresh: typeof options.refresh !== 'undefined' ? options.refresh : true,
      callback: options.callbackSuccess,
      unselectAll: options.unselectAll,
    },
    onFailure: {
      notification: !options.callbackError && {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
      callback: options.callbackError,
    },
  },
});

/**
 * Call a method on many resources.
 * @param {string} methodName the name of the method to call.
 * @param {string} resource the type of the resource.
 * @param {array} ids the IDs of the resources.
 * @param {object} data the data to send as params for the methodd.
 * @param {object} previousData the previous record data.
 * @param {string} basePath the base path for the resource.
 * @param {string|bool} redirectTo the page to redirect to after executing the method,
 *  or `false` to not redirect (default: `false`).
 * @param {bool} refresh refresh after executing the method (default: `false`)
 * @param {*} options others parameters.
 */
export const executeManyMethodPost = (
  methodName,
  resource,
  ids,
  data,
  previousData,
  basePath,
  redirectTo = false,
  options = {}
) => ({
  type: options.type || CRUD_UPDATE,
  payload: {
    ids,
    data,
    methodName,
    previousData,
  },
  meta: {
    resource: resource,
    fetch: METHOD_MANY_POST,
    basePath,
    onSuccess: {
      notification: {
        body: options.notification_success || 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: ids.length,
        },
      },
      redirectTo,
      basePath,
      refresh: typeof options.refresh !== 'undefined' ? options.refresh : true,
      unselectAll: true,
    },
    onFailure: {
      notification: {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});

/**
 * Call a method on a model.
 * @param {string} methodName the name of the method to call.
 * @param {string} resource the type of the resource.
 * @param {object} data the data to send as params for the methodd.
 * @param {string} basePath the base path for the resource.
 * @param {string|bool} redirectTo the page to redirect to after executing the method,
 *  or `false` to not redirect (default: `false`).
 * @param {bool} refresh refresh after executing the method (default: `false`)
 * @param {*} options others parameters.
 */
export const executeMethodGet = (
  methodName,
  resource,
  data,
  basePath,
  redirectTo = false,
  options = {}
) => ({
  type: options.type || CRUD_GET_ONE,
  payload: {
    data,
    methodName,
  },
  meta: {
    resource: resource,
    fetch: METHOD_GET,
    basePath,
    onSuccess: {
      notification: !options.callbackSuccess && {
        body: options.notification_success || 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo,
      basePath,
      refresh: typeof options.refresh !== 'undefined' ? options.refresh : true,
      callback: options.callbackSuccess,
      unselectAll: options.unselectAll,
    },
    onFailure: {
      notification: !options.callbackError && {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
      callback: options.callbackError,
    },
  },
});
