import { maxLength, maxValue, minValue, number, required } from 'react-admin';
import { boolean, date, list, notEmpty } from './validators';

export default {
  duplicate_id: [number()],
  title: [required(), number()],
  first_name: [notEmpty(), maxLength(64)],
  last_name: [notEmpty(), maxLength(64)],
  phones: [list()],
  emails: [list()],
  source: [maxLength(64)],
  has_driving_license: [number(), minValue(0), maxValue(2)],
  vehicule: [number(), minValue(0), maxValue(2)],
  comments: [],
  comment: {
    date: [date()],
    note: [],
  },
  registred_job_center: [boolean()],
  is_top_profile: [boolean()],
  diploma: [maxValue(128)],
  department_mobility: [list()],
  exact_mobility: [list()],
  mobility_note: [maxLength(256)],
  salary_min: [number(), minValue(8000)],
  sectors: [list()],
  candidacy_date: [notEmpty(), date()],
  sub_sectors: [list()],
  upload_files: [],
  professional_positions: [list()],
  is_coach_job: [boolean()],
  coach_job_comment: [],
};
