/* global process */
// server settings
let {
  NODE_ENV,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_GRAND_TYPE,
  REACT_APP_SERVER_API_PROTOCOL: SERVER_PROTOCOL,
  REACT_APP_SERVER_API_HOST: SERVER_HOST,
  REACT_APP_SERVER_API_PORT: SERVER_PORT,
  REACT_APP_VERSION,
  REACT_APP_BUGSNAG_API_KEY,
} = process.env;

// if empty url, use current hostname and port
if (!SERVER_PROTOCOL) {
  SERVER_PROTOCOL = window.location.protocol;
}
if (!SERVER_HOST) {
  SERVER_HOST = window.location.hostname;
}

export const SERVER_URL = `${SERVER_PROTOCOL}//${SERVER_HOST}${
  SERVER_PORT ? `:${SERVER_PORT}` : ''
}`;
export const UPLOAD_FOLDER_PATH = `${SERVER_URL}/`;
export const MODEL_API_URL = SERVER_URL + '/api/model';
export const LOGIN_URL = SERVER_URL + '/api/auth/login';
export const LOGOUT_URL = SERVER_URL + '/api/auth/logout';
export const LOGIN_BACKGROUND_IMAGE = '/images/login-background.jpeg';
export const TALENTO_LOGO = '/images/talento-logo.jpg';
export const FORGOTTEN_PASSWORD_URL = SERVER_URL + '/api/auth/forgotten';
export const RESET_PASSWORD_URL = SERVER_URL + '/api/auth/reset';
export const APP_VERSION = REACT_APP_VERSION;
export const APP_ENVIRONMENT = NODE_ENV;
export const REFRESH_APP_VERSION_TIMER = 120000; // time in ms
export const REFRESH_APP_VERSION_THROTTLE = 5000; // time in ms

// external services
export const BUGSNAG = Object.freeze({
  apiKey: REACT_APP_BUGSNAG_API_KEY,
  appVersion: APP_VERSION,
  appType: 'web',
  notifyReleaseStages: ['production'],
  releaseStage: APP_ENVIRONMENT,
});

// app constants
export const PER_PAGE_REFERENCES = 9999;
export const sex = { MRS: 0, MR: 1, LAWYER: 2, DOCTOR: 3 };
export const REDIRECT_AFTER_CREATE = 'list';
export const REDIRECT_AFTER_EDIT = 'list';
export const PASSWORD_MIN_LENGTH = 6; // min length in laravel = 6, hardcoded :/

export const AUTH = Object.freeze({
  CLIENT_ID: REACT_APP_AUTH_CLIENT_ID,
  CLIENT_SECRET: REACT_APP_AUTH_CLIENT_SECRET,
  GRAND_TYPE: REACT_APP_AUTH_GRAND_TYPE,
});

export const DEFAULT_THEME = 'dark';
