import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { addField, Labeled } from 'react-admin';
import compose from 'recompose/compose';
import { DateTime } from '../../../utils/datetime';
import sanitizeRestProps from './sanitizeRestProps';

// parse : input -> record
// format : record -> input
const formatDate = value => {
  if (value == null || value === '') {
    // if undefined is returned, it will make the input uncontrolled.
    return '';
  }

  if (DateTime.isDateString(value)) {
    return value;
  }

  if (DateTime.isTimeString(value)) {
    return '';
  }

  return new DateTime(value).toDateString();
};
const formatTime = value => {
  if (value == null || value === '') {
    // if undefined is returned, it will make the input uncontrolled.
    return '';
  }

  if (DateTime.isTimeString(value)) {
    return new DateTime(value).toTimeString(false);
  }

  if (DateTime.isDateString(value)) {
    return '';
  }

  return new DateTime(value).toTimeString(false);
};
const parseDate = (value, fullValue) => {
  if (!value) {
    return null;
  }

  if (fullValue) {
    const date = new DateTime(fullValue);
    date.setDate(value);
    return date.toString();
  }

  return value;
};
const parseTime = (value, fullValue) => {
  if (fullValue) {
    const date = new DateTime(fullValue);
    date.setTime(value);
    return date.toString();
  }

  return value;
};

const styles = theme =>
  createStyles({
    date: {
      width: theme.spacing.unit * 20,
    },
    labelWrapper: {
      '& > label': {
        position: 'absolute',
      },
      '& > div': {
        padding: 0,
      },
    },
    time: {
      marginLeft: theme.spacing.unit,
      width: theme.spacing.unit * 11,
    },
  });

/**
 * DateTimeInput with format understandable by API and compatible with Firefox.
 */
class DateTimeInput extends React.Component {
  handleBlurDate = event => {
    event.preventDefault();

    const { input, onBlur } = this.props;
    onBlur(input.value);
    input.onBlur(input.value);
  };

  handleBlurTime = event => {
    event.preventDefault();

    const { input, onBlur } = this.props;
    onBlur(input.value);
    input.onBlur(input.value);
  };

  handleChangeDate = event => {
    event.preventDefault();

    const { input, onChange } = this.props;
    const newDateTime = parseDate(event.target.value, input.value);
    onChange(newDateTime);
    input.onChange(newDateTime);
  };

  handleChangeTime = event => {
    event.preventDefault();

    const { input, onChange } = this.props;
    const newDateTime = parseTime(event.target.value, input.value);
    onChange(newDateTime);
    input.onChange(newDateTime);
  };

  render() {
    const {
      classes,
      className,
      meta: { touched, error },
      input,
      isRequired,
      label,
      options,
      source,
      resource,
      ...rest
    } = this.props;

    const dateValue = formatDate(input.value);
    const timeValue = formatTime(input.value);

    return (
      <Labeled
        className={classes.labelWrapper}
        label={label}
        source={source}
        resource={resource}
        isRequired={isRequired}
      >
        <React.Fragment>
          <TextField
            {...input}
            className={classnames(classes.date, className)}
            type="date"
            margin="normal"
            error={!!(touched && error)}
            helperText={touched && error}
            InputLabelProps={{
              shrink: true,
            }}
            {...options}
            {...sanitizeRestProps(rest)}
            onBlur={this.handleBlurDate}
            onChange={this.handleChangeDate}
            value={dateValue}
          />
          <TextField
            {...input}
            className={classnames(classes.time, className)}
            type="time"
            margin="normal"
            error={!!(touched && error)}
            helperText={touched && error}
            InputLabelProps={{
              shrink: true,
            }}
            {...options}
            {...sanitizeRestProps(rest)}
            onBlur={this.handleBlurTime}
            onChange={this.handleChangeTime}
            value={timeValue}
          />
        </React.Fragment>
      </Labeled>
    );
  }
}

DateTimeInput.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

DateTimeInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
};

const enhance = compose(addField, withStyles(styles));
export default enhance(DateTimeInput);
