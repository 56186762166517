import React from 'react';
import {
  BooleanInput,
  DateField,
  FormTab,
  ImageInput,
  LongTextInput,
  Responsive,
  TextInput,
} from 'react-admin';
import { Customer, Tag } from '../../../models';
import { customerValidators } from '../../../validators';
import { TagListAutocompleteInput, UploadedImageField } from '../../framework';
import { ObjectStateStepper } from '../../ObjectState';

const CustomerEditGeneralTab = props => (
  <FormTab {...props}>
    <Responsive
      small={
        <ObjectStateStepper
          choices={Customer.OBJECT_STATE_ID.values}
          orientation="vertical"
          source="object_state_id"
        />
      }
      medium={
        <ObjectStateStepper choices={Customer.OBJECT_STATE_ID.values} source="object_state_id" />
      }
      formClassName="ra-input__noflex"
      fullWidth
    />
    <ImageInput
      accept="image/*"
      formClassName="ra-input__flex-noflex"
      source="logo"
      validate={customerValidators.logo}
    >
      <UploadedImageField source="src" />
    </ImageInput>
    <TextInput
      formClassName="ra-input__flex-25"
      source="business_name"
      validate={customerValidators.business_name}
    />
    <TextInput formClassName="ra-input__flex-25" source="name" validate={customerValidators.name} />
    <TextInput
      formClassName="ra-input__flex-25"
      source="parent_company_name"
      validate={customerValidators.parent_company_name}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="siret"
      validate={customerValidators.siret}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="address_name"
      validate={customerValidators.address_name}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="address_line_1"
      validate={customerValidators.address_line_1}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="address_line_2"
      validate={customerValidators.address_line_2}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="address_line_3"
      validate={customerValidators.address_line_3}
    />
    <TextInput
      formClassName="ra-input__flex-25"
      source="address_zipcode"
      validate={customerValidators.address_zipcode}
    />
    <TextInput
      formClassName="ra-input__flex-75"
      source="address_city"
      validate={customerValidators.address_city}
    />
    <BooleanInput
      formClassName="ra-input__flex-25 ra-input__v-align"
      source="use_deposit"
      validate={customerValidators.use_deposit}
    />
    <TagListAutocompleteInput
      formClassName="ra-input__flex-25"
      reference="tag"
      source="domains"
      type={Tag.TYPE.DOMAIN}
      validate={customerValidators.domains}
    />
    <TagListAutocompleteInput
      formClassName="ra-input__flex-50"
      reference="tag"
      source="locations"
      type={Tag.TYPE.LOCATION}
      validate={customerValidators.locations}
    />
    <LongTextInput
      formClassName="ra-input__flex-50"
      source="comments"
      validate={customerValidators.comments}
    />
    <DateField formClassName="ra-input__flex-25" source="created_at" showTime />
    <DateField formClassName="ra-input__flex-25" source="updated_at" showTime />
  </FormTab>
);

export default CustomerEditGeneralTab;
