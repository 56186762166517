import uniqBy from 'lodash/uniqBy';

const DRIVING_LICENCE = {
  NO: 0,
  YES: 1,
  IN_PROGRESS: 2,
};
const GEO_GOUV_API = 'https://geo.api.gouv.fr/';
const OBJECT_STATE_ID = Object.freeze({
  NEW: 0,
  WAITING: 1,
  REMINDER: 2,
  CALL_PLANNED: 3,
  VOICEMAIL: 4,
  REFUSED: 5,
  BREEDING_GROUND: 6,
  SEEN_AND_LINKED: 7,
  BLACKLISTED: 8,
});
const VEHICULE = {
  NO: 0,
  YES: 1,
  IN_PROGRESS: 2,
};

export class Candidate {
  static OBJECT_STATE_ID = Object.freeze({
    values: [
      {
        id: OBJECT_STATE_ID.NEW,
        name: 'form.candidate.state.new',
        color: 'misty',
      },
      {
        id: OBJECT_STATE_ID.WAITING,
        name: 'form.candidate.state.waiting',
        color: 'summer',
      },
      {
        id: OBJECT_STATE_ID.REMINDER,
        name: 'form.candidate.state.reminder',
        color: 'orange',
      },
      {
        id: OBJECT_STATE_ID.CALL_PLANNED,
        name: 'form.candidate.state.call_planned',
        color: 'lettuce',
      },
      {
        id: OBJECT_STATE_ID.VOICEMAIL,
        name: 'form.candidate.state.voicemail',
        color: 'summer',
      },
      {
        id: OBJECT_STATE_ID.REFUSED,
        name: 'form.candidate.state.refused',
        color: 'red',
      },
      {
        id: OBJECT_STATE_ID.BREEDING_GROUND,
        name: 'form.candidate.state.breeding_ground',
        color: 'lime',
      },
      {
        id: OBJECT_STATE_ID.SEEN_AND_LINKED,
        name: 'form.candidate.state.seen_and_linked',
        color: 'navyGreen',
      },
      {
        id: OBJECT_STATE_ID.BLACKLISTED,
        name: 'form.candidate.state.blacklisted',
        color: 'mattBlack',
      },
    ],
    default: OBJECT_STATE_ID.NEW,
  });

  static DRIVING_LICENCE_ENUM = {
    values: [
      { id: DRIVING_LICENCE.NO, name: 'form.candidate.driving_licence.no' },
      { id: DRIVING_LICENCE.YES, name: 'form.candidate.driving_licence.yes' },
      { id: DRIVING_LICENCE.IN_PROGRESS, name: 'form.candidate.driving_licence.in_progress' },
    ],
    default: null,
  };
  static VEHICULE_ENUM = {
    values: [
      { id: VEHICULE.NO, name: 'form.candidate.vehicule.no' },
      { id: VEHICULE.YES, name: 'form.candidate.vehicule.yes' },
      { id: VEHICULE.IN_PROGRESS, name: 'form.candidate.vehicule.in_progress' },
    ],
    default: null,
  };

  /**
   * Get candidate full name.
   * @param {object} candidate the candidate.
   * @returns {string}
   */
  static getFullName = candidate =>
    candidate ? `${candidate.last_name} ${candidate.first_name}` : '';

  static GEO_GOUV_API = GEO_GOUV_API;
  static departmentSortOrder = { field: 'nom', order: 'ASC' };
  static departmentFilterToQuery = searchText => ({ nom: searchText });
  static townSortOrder = { field: 'nom', order: 'ASC' };
  static townFilterToQuery = searchText => ({ nom: searchText });

  /**
   * Fitler towns and remove duplicated names.
   * There can be several towns with the same name, for instance: Savigneux (01480) and Savigneux (42600)
   * @param {Array<Object>} towns the towns to filter.
   */
  static filterUniqueTowns = towns => uniqBy(towns, 'nom');
}
