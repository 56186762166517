

import PropTypes from 'prop-types';
import React from 'react';
import { addField, translate } from 'react-admin';
import compose from 'recompose/compose';
import { ReferenceArrayInputView } from '../ReferenceArrayInputView';
import ExternalReferenceArrayInputController from './ExternalReferenceArrayInputController';

/*
 * ===================================
 * Identical to react-admin ReferenceArrayInput.
 * Except two changes:
 * - The ReferenceArrayInputView is moved in a dedicated file.
 * - The ReferenceArrayInput uses a custom controller.
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/ReferenceArrayInput.js
 * ===================================
 */

export const ReferenceArrayInput = ({ children, ...props }) => {
  if (React.Children.count(children) !== 1) {
    throw new Error('<ReferenceArrayInput> only accepts a single child (like <Datagrid>)');
  }

  return (
    /*
     * ===============================
     * Custom controller name.
     * ===============================
     */
    <ExternalReferenceArrayInputController {...props}>
      {controllerProps => (
        <ReferenceArrayInputView {...props} {...{ children, ...controllerProps }} />
      )}
    </ExternalReferenceArrayInputController>
  );
};

ReferenceArrayInput.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterToQuery: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  perPage: PropTypes.number,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['ASC', 'DESC']),
  }),
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

ReferenceArrayInput.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  perPage: 25,
  sort: { field: 'id', order: 'DESC' },
};

const EnhancedReferenceArrayInput = compose(addField, translate)(ReferenceArrayInput);

export default EnhancedReferenceArrayInput;
