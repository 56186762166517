import { withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Edit, TabbedForm, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { Candidate } from '../../../models';
import SendMailButton from '../../Mail/SendMailButton';
import { CustomEditActions, PageTitle, Toolbar } from '../../framework';
import { asyncValidateEmail, asyncValidatePhone } from '../asyncValidate';
import CandidateEditCandidateCustomersTab from './CandidateEditCandidateCustomersTab';
import CandidateEditGeneralTab from './CandidateEditGeneralTab';

/**
 * Get available actions regarding permissions.
 * @param {object} permissions the permissions.
 * @param {string} resource the resource name.
 * @returns {Array} a list of buttons.
 */
const getEditActions = (permissions, resource) => {
  const actions = [];

  if (permissions && permissions.can(resource, 'send_email')) {
    actions.push(<SendMailButton key={0} />);
  }

  return actions;
};

class CandidateEditForm extends React.Component {
  /**
   * Hack async validation process to show warning if phone number is already used.
   * @param {object} values the form values.
   * @param {function} dispatch the state dispatch method.
   * @param {object} props the redux form properties.
   * @param {string} blurredField the field being edited
   * @return {Promise}
   */
  asyncValidate = (values, dispatch, props, blurredField) =>
    Promise.all([
      asyncValidatePhone(this.props, values, dispatch, props, blurredField),
      asyncValidateEmail(this.props, values, dispatch, props, blurredField),
    ]);

  render() {
    const { dataProvider: _, dispatch: __, theme, ...props } = this.props;
    const { permissions, resource } = this.props;

    return (
      <Edit
        actions={<CustomEditActions leftActions={getEditActions(permissions, resource)} />}
        className="candidate"
        title={<PageTitle {...props} source={Candidate.getFullName} />}
        undoable={false}
        {...props}
      >
        <TabbedForm asyncValidate={this.asyncValidate} redirect={false} toolbar={<Toolbar />}>
          <CandidateEditGeneralTab
            contentClassName="tab-inline"
            label="form.candidate.tabs.candidate"
          />

          {permissions && permissions.canList('candidate_customer') && (
            <CandidateEditCandidateCustomersTab
              label="form.candidate.tabs.candidate_customers"
              path="candidate-customers"
            />
          )}
        </TabbedForm>
      </Edit>
    );
  }
}

CandidateEditForm.propTypes = {
  theme: PropTypes.object.isRequired,
};

const enhance = compose(withTheme(), withDataProvider);

export const CandidateEdit = enhance(CandidateEditForm);
