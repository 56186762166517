import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, DateField, List, Responsive, SimpleList, TextField } from 'react-admin';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  DeleteConfirmButton,
  defaultPerPage,
} from '../../framework';
import CandidateSourceFilter from './CandidateSourceFilter';

const bulkActions = <BulkDeleteConfirmButton />;
const sortOrder = { field: 'name', order: 'ASC' };
const getValue = value => record => record[value];

export const CandidateSourceList = props => (
  <List
    {...props}
    bulkActionButtons={props.options.hasDelete ? bulkActions : false}
    filters={<CandidateSourceFilter />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sortOrder}
  >
    <Responsive
      small={<SimpleList primaryText={getValue('name')} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <TextField source="name" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

CandidateSourceList.propTypes = {
  hasEdit: PropTypes.bool,
  options: PropTypes.object,
};
