const OBJECT_STATE_ID = Object.freeze({
  DISABLED: 0,
  ENABLED: 1,
});

export class Customer {
  static OBJECT_STATE_ID = Object.freeze({
    values: [
      {
        id: OBJECT_STATE_ID.DISABLED,
        name: 'form.customer.state.disabled',
        color: 'red',
      },
      {
        id: OBJECT_STATE_ID.ENABLED,
        name: 'form.customer.state.enabled',
        color: 'lime',
      },
    ],
    default: OBJECT_STATE_ID.NEW,
  });

  /**
   * Get main contact of customer.
   * @param {object} customer the customer.
   * @returns {object} the main contact, if any.
   */
  static getMainContact = customer =>
    customer && Array.isArray(customer.contacts)
      ? customer.contacts.find(contact => contact.is_main)
      : null;

  static sortOrder = { field: 'business_name', order: 'ASC' };
}
