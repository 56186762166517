import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { addField, FieldTitle } from 'react-admin';
import compose from 'recompose/compose';
import sanitizeRestProps from './sanitizeRestProps';

/**
 * @see https://v1.mui.com/demos/selection-controls/
 */
const styles = theme => ({
  colorSwitchBase: {
    '&$colorChecked': {
      color: '#55BDC3',
      '& + $colorBar': {
        backgroundColor: '#55BDC3',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

export class BlueBooleanInput extends Component {
  handleChange = (event, value) => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      className,
      classes,
      id,
      input,
      isRequired,
      label,
      source,
      resource,
      options,
      fullWidth,
      meta,
      ...rest
    } = this.props;

    const { value, ...inputProps } = input;

    return (
      <FormGroup className={className} {...sanitizeRestProps(rest)}>
        <FormControlLabel
          htmlFor={id}
          control={
            <Switch
              id={id}
              color="primary"
              checked={!!value}
              onChange={this.handleChange}
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
              }}
              {...inputProps}
              {...options}
            />
          }
          label={
            <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
          }
        />
        {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      </FormGroup>
    );
  }
}

BlueBooleanInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
};

BlueBooleanInput.defaultProps = {
  options: {},
};

const enhance = compose(addField, withStyles(styles));
export default enhance(BlueBooleanInput);
