import React from 'react';
import ReactDOM from 'react-dom';
import './style/css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { BUGSNAG as BUGSNAG_SETTINGS } from './settings';
import { Session } from './data-provider/session';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const bugsnagSettings = Object.assign(
  {
    beforeSend: function(report) {
      report.user = Session.getUser();
      report.updateMetaData('session', 'locale', Session.getCurrentLocale());
    },
  },
  BUGSNAG_SETTINGS
);

const bugsnagClient = bugsnag(bugsnagSettings);
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);
