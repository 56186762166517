import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslate } from 'react-admin';
import compose from 'recompose/compose';
import { referenceSource as defaultReferenceSource } from '../../../../actions';
import { buildUrl, fetchPublicApi } from '../../../../data-provider/publicApi';
import { isNotEmpty, isObject } from '../../../../utils/tools';

class UnconnectedExternalReferenceArrayInputController extends React.Component {
  constructor(props) {
    super(props);
    const { perPage, sort, filter } = props;
    // stored as a property rather than state because we don't want redraw of async updates
    this.params = { pagination: { page: 1, perPage }, sort, filter };
    this.debouncedSetFilter = debounce(this.setFilter.bind(this), 500);

    this.state = {
      choices: [],
      fakeChoices: [],
      error: null,
      loadedOnce: false,
    };
  }

  componentDidMount() {
    this.fetchReferencesAndOptions(this.props, {});
  }

  componentWillReceiveProps(nextProps) {
    let shouldFetchOptions = false;

    if (
      (this.props.record || { id: undefined }).id !== (nextProps.record || { id: undefined }).id
    ) {
      this.fetchReferencesAndOptions(nextProps);
    } else if (this.props.input.value !== nextProps.input.value) {
      this.fetchReferences(nextProps);
    } else {
      if (!isEqual(nextProps.filter, this.props.filter)) {
        this.params = { ...this.params, filter: nextProps.filter };
        shouldFetchOptions = true;
      }
      if (!isEqual(nextProps.sort, this.props.sort)) {
        this.params = { ...this.params, sort: nextProps.sort };
        shouldFetchOptions = true;
      }
      if (nextProps.perPage !== this.props.perPage) {
        this.params = {
          ...this.params,
          pagination: {
            ...this.params.pagination,
            perPage: nextProps.perPage,
          },
        };
        shouldFetchOptions = true;
      }
    }
    if (shouldFetchOptions) {
      this.fetchOptions();
    }
  }

  setFilter = filter => {
    if (filter !== this.params.filter) {
      this.params.filter = this.props.filterToQuery(filter);
      this.fetchOptions();
    }
  };

  setPagination = pagination => {
    if (pagination !== this.params.pagination) {
      this.params.pagination = pagination;
      this.fetchOptions();
    }
  };

  setSort = sort => {
    if (sort !== this.params.sort) {
      this.params.sort = sort;
      this.fetchOptions();
    }
  };

  /**
   * Fetch the external api.
   * @param {String} reference
   * @param {Object} pagination
   * @param {Object} sort
   * @param {Object} filters
   * @returns {Promise<Array>}
   */
  fetchValues = (reference, pagination, sort, filters) => {
    const { apiUrl, translate } = this.props;
    const url = buildUrl(apiUrl, reference, filters);

    return fetchPublicApi('GET', url)
      .then(
        /**
         * @param {Array} response
         */
        response => {
          let data = response.slice();
          if (sort) {
            data = orderBy(data, [sort.field], [(sort.order || 'ASC').toLowerCase()]);
          }

          return data;
        }
      )
      .catch(err => {
        const message =
          err && err.body && isNotEmpty(err.body.exception)
            ? `error.${err.body.exception}`
            : err.toString();

        this.setState({ error: translate(message, { _: message }) });
      });
  };

  /**
   * Fetch values for existing input value and for selected values.
   * @param {String} reference
   * @param {Array} idsToFetch
   */
  crudGetMany = (reference, idsToFetch) => {
    const { sort, target, targetId } = this.props;

    idsToFetch.forEach(idToFetch => {
      const filters = { [target]: idToFetch };

      this.fetchValues(reference, undefined, undefined, filters).then(data => {
        const newValue = Array.isArray(data) && data.length ? data[0] : undefined;

        if (newValue) {
          this.setState(state => {
            let choices = state.choices.slice();
            const index = choices.findIndex(choice => choice[targetId] === newValue[targetId]);

            if (index > -1) {
              choices[index] = newValue;
            } else {
              choices = orderBy(
                [...choices, newValue],
                [sort.field],
                [(sort.order || 'ASC').toLowerCase()]
              );
            }

            return { choices };
          }, this.fetchFakeReferences);
        }
      });
    });
  };

  /**
   * Retrieve a list of suggestions.
   * @param {String} reference
   * @param {Array} referenceSource
   * @param {Object} pagination
   * @param {Object} sort
   * @param {Object} filters
   */
  crudGetMatching = (reference, referenceSource, pagination, sort, filters) => {
    const { filterResults, target } = this.props;

    if (!filters[target]) {
      delete filters[target];
    }

    this.fetchValues(reference, pagination, sort, filters)
      .then(data => (filterResults ? filterResults(data) : data))
      .then(data =>
        this.setState(
          { choices: (data || []).slice(0, pagination.perPage) },
          this.fetchFakeReferences
        )
      )
      .finally(() => this.setState({ loadedOnce: true }));
  };

  fetchFakeReferences = () => {
    const { displayInvalidValues, input, target } = this.props;

    if (!displayInvalidValues) {
      return;
    }

    const { choices } = this.state;
    const values = input.value || [];
    const newChoices = [];

    values.forEach(val => {
      const found = [...choices, ...newChoices].findIndex(choice => choice[target] === val) > -1;
      if (!found) {
        newChoices.push({ [target]: val });
      }
    });

    this.setState({ fakeChoices: newChoices });
  };

  fetchReferences = (nextProps, currentProps = this.props) => {
    const { input, reference, target } = nextProps;
    const ids = input.value;

    if (ids) {
      if (!Array.isArray(ids)) {
        throw Error('The value of ReferenceArrayInput should be an array');
      }
      /*
       * ========================================
       * Get items name (objects instead of ids)
       * ========================================
       */
      const idsToFetch = difference(
        ids.map(item => (isObject(item) ? item[target] : item)),
        (get(currentProps, 'input.value') || []).map(item => (isObject(item) ? item[target] : item))
      );
      if (idsToFetch.length) {
        this.crudGetMany(reference, idsToFetch);
      }
    }
  };

  fetchOptions = (props = this.props) => {
    const { reference, source, resource, referenceSource, filter: defaultFilter } = props;
    const { pagination, sort, filter } = this.params;
    this.crudGetMatching(reference, referenceSource(resource, source), pagination, sort, {
      ...defaultFilter,
      ...filter,
    });
  };

  fetchReferencesAndOptions(nextProps, currentProps = this.props) {
    this.fetchReferences(nextProps, currentProps);
    this.fetchOptions(nextProps);
  }

  /**
   * Patches the default input onChange event.
   * The autocomplete returns a list of objects, we want a list of values to be registred by redux.
   * @param {Array<Object>} newValue
   */
  onChange = (newValue, actionMeta) => {
    const { input, onChange, target, targetId } = this.props;
    const { option, action, removedValue } = actionMeta;
    let onChangeNewValue = input.value || [];

    switch (action) {
      case 'clear': // Removing all selected options with the clear button
        onChangeNewValue = [];
        break;

      case 'create-option': // (Creatable) Creating a new option
      case 'select-option': // Selecting an option from the list
        onChangeNewValue.push(isObject(option) ? option[target] : option);
        break;

      case 'deselect-option': // (Multiple) Deselecting an option from the list
      case 'pop-value': // Removing options using backspace
      case 'remove-value': // (Multiple) Removing a selected option with the remove button
        onChangeNewValue = onChangeNewValue.filter(choice => {
          const choiceValue = isObject(choice)
            ? isObject(removedValue) && removedValue[targetId] && choice[targetId]
              ? choice[targetId]
              : choice[target]
            : choice;
          const removedValueValue = isObject(removedValue)
            ? isObject(choice) && removedValue[targetId] && choice[targetId]
              ? removedValue[targetId]
              : removedValue[target]
            : removedValue;

          return choiceValue !== removedValueValue;
        });
        break;

      case 'set-value': // Calling setValue from a component without an action
        onChangeNewValue = newValue;
        break;

      default:
        break;
    }

    input.onChange(onChangeNewValue);
    if (onChange) {
      onChange(onChangeNewValue);
    }
  };

  /**
   * List fake events to display in suggestions list.
   * @returns {Array}
   */
  getFakeChoices = () => {
    const { fakeChoices } = this.state;
    return fakeChoices;
  };

  render() {
    const { input, children } = this.props;
    const { choices, error, loadedOnce } = this.state;

    return children({
      choices: [...choices, ...this.getFakeChoices()],
      error,
      input: { ...input, onChange: null },
      isLoading: !loadedOnce,
      isMulti: true,
      onChange: this.onChange,
      setFilter: this.debouncedSetFilter,
      setPagination: this.setPagination,
      setSort: this.setSort,
    });
  }
}

UnconnectedExternalReferenceArrayInputController.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  displayInvalidValues: PropTypes.bool,
  filterResults: PropTypes.func,
  target: PropTypes.string,
  targetId: PropTypes.string,
};

UnconnectedExternalReferenceArrayInputController.defaultProps = {
  displayInvalidValues: false,
  target: 'name',
  targetId: 'id',
};

const ExternalReferenceArrayInputController = compose(withTranslate)(
  UnconnectedExternalReferenceArrayInputController
);

ExternalReferenceArrayInputController.defaultProps = {
  referenceSource: defaultReferenceSource,
};

export default ExternalReferenceArrayInputController;
