const EMAIL_STATUS = Object.freeze({
  NOT_SENT: 0,
  SENT: 1,
});

export class Reporting {
  static EMAIL_STATUS = EMAIL_STATUS;
  static EMAIL_STATUS_CHOICES = [
    { id: EMAIL_STATUS.NOT_SENT, name: 'form.reporting.email_status.not_sent' },
    { id: EMAIL_STATUS.SENT, name: 'form.reporting.email_status.sent' },
  ];

  /**
   * Check if reporting can be sent to customer.
   * @param {object} report the reporting to check.
   * @returns {boolean}
   */
  static canSendReporting = reporting => reporting && !reporting.email_status;
}
