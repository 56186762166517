import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Children, cloneElement, PureComponent } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import TextWarningInput from './TextWarningInput';

class CustomSimpleFormIterator extends PureComponent {
  /**
   * Format new value from object to string.
   */
  fieldPush = value => this.props.fields.push(value[this.props.source] || '');

  /**
   * Override some methods provided by redux and forwarded to SimpleFormIterator
   */
  getFields = () => ({
    ...this.props.fields,
    // if react-admin updates SimpleFormIterator, override `this.props.fields.map` if needed.
    push: this.fieldPush, //because react-admin is inserting `{}` as new value.
  });

  render() {
    return <SimpleFormIterator {...this.props} fields={this.getFields()} />;
  }
}

const RepeatedStringInput = ({ children, className, inputLabel, ...props }) => (
  <ArrayInput
    {...props}
    className={classnames(
      'array-input__simple-form-iterator',
      'array-input__simple-form-iterator--no-label',
      className
    )}
  >
    <CustomSimpleFormIterator>
      {cloneElement(Children.only(children), { label: inputLabel })}
    </CustomSimpleFormIterator>
  </ArrayInput>
);

RepeatedStringInput.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  inputLabel: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

RepeatedStringInput.defaultProps = {
  children: <TextWarningInput />,
  inputLabel: ' ',
};

export default RepeatedStringInput;
