import { maxLength } from 'react-admin';
import { boolean, list, notEmpty } from './validators';

export default {
  name: [maxLength(64)],
  logo: [maxLength(256)],
  business_name: [notEmpty(), maxLength(64)],
  parent_company_name: [maxLength(64)],
  siret: [maxLength(32)],
  address_name: [notEmpty(), maxLength(64)],
  address_line_1: [notEmpty(), maxLength(64)],
  address_line_2: [maxLength(64)],
  address_line_3: [maxLength(64)],
  address_zipcode: [notEmpty(), maxLength(16)],
  address_city: [notEmpty(), maxLength(64)],
  use_deposit: [boolean()],
  domains: [],
  comments: [],
  locations: [list()],
  contacts: [list()],
};
