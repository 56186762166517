import PropTypes from 'prop-types';
import React from 'react';
import { SimpleForm, TextInput, translate } from 'react-admin';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { roleValidators } from '../../../validators';
import { CustomEditActions, EditWith, PageTitle } from '../../framework';
import { PermissionDatagrid } from '../../Permission';

/**
 * Edit a role.
 * @param {*} props edit view properties.
 */
const RoleEdit = ({ permissions, translate, ...props }) => (
  <EditWith
    {...props}
    actions={<CustomEditActions />}
    relations="permissions"
    title={<PageTitle {...props} />}
    undoable={false}
  >
    <SimpleForm redirect={REDIRECT_AFTER_EDIT}>
      <TextInput source="name" validate={roleValidators.name} />
      {permissions && permissions.canList('permission') && (
        <PermissionDatagrid
          addLabel
          label={translate('resources.permission.name', 2)}
          source="permissions"
        />
      )}
    </SimpleForm>
  </EditWith>
);

RoleEdit.propTypes = {
  permissions: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

export default translate(RoleEdit);
