import React from 'react';
import { ArrayInput, BooleanInput, FormTab, SimpleFormIterator, TextInput } from 'react-admin';
import { contactValidators } from '../../../validators';
import { PhoneMaskTextInput, SexInput } from '../../framework';

const CustomerEditContactsTab = props => (
  <FormTab {...props}>
    <ArrayInput className="array-input__simple-form-iterator--inline" fullWidth source="contacts">
      <SimpleFormIterator>
        <SexInput
          source="title"
          validate={contactValidators.title}
          label="resources.contact.fields.title"
        />
        <TextInput
          source="last_name"
          validate={contactValidators.last_name}
          label="resources.contact.fields.last_name"
        />
        <TextInput
          source="first_name"
          validate={contactValidators.first_name}
          label="resources.contact.fields.first_name"
        />
        <PhoneMaskTextInput
          source="phone"
          validate={contactValidators.phone}
          label="resources.contact.fields.phone"
        />
        <TextInput
          source="email"
          validate={contactValidators.email}
          label="resources.contact.fields.email"
        />
        <TextInput
          source="role"
          validate={contactValidators.role}
          label="resources.contact.fields.role"
        />
        <BooleanInput
          source="is_main"
          validate={contactValidators.is_main}
          label="resources.contact.fields.is_main"
        />
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
);

export default CustomerEditContactsTab;
