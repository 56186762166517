export const CREATE_MANY = 'CREATE_MANY';
export const METHOD_GET = 'METHOD_GET';
export const METHOD_POST = 'METHOD_POST';
export const METHOD_MANY_POST = 'METHOD_MANY_POST';

export const CHANGE_THEME = 'CHANGE_THEME';
export const OBJECT_STATE_ID_UPDATE = 'OBJECT_STATE_ID_UPDATE';
export const DOWNLOAD = 'DOWNLOAD';
export const DOWNLOAD_STATIC = 'DOWNLOAD_STATIC';
export const PRINT = 'PRINT';

export const TRANSITION_GET = 'TRANSITION_GET';
export const TRANSITION_GET_LOADING = `${TRANSITION_GET}_LOADING`;
export const TRANSITION_GET_SUCCESS = `${TRANSITION_GET}_SUCCESS`;
export const TRANSITION_GET_ACCUMULATE = 'TRANSITION_GET_ACCUMULATE';
export const TRANSITION_GET_ACCUMULATE_LOADING = `${TRANSITION_GET_ACCUMULATE}_LOADING`;
export const TRANSITION_GET_ACCUMULATE_SUCCESS = `${TRANSITION_GET_ACCUMULATE}_SUCCESS`;

/**
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/input/ReferenceArrayInputController.tsx
 */
export const referenceSource = (resource, source) => `${resource}@${source}`;
