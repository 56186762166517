import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FileInput,
  FormDataConsumer,
  FormTab,
  FunctionField,
  LongTextInput,
  ReferenceField,
  ReferenceManyField,
  Responsive,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  SimpleList,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import { Candidate, CandidateOrder, Invoice, Order, Tag } from '../../../models';
import { invoiceValidators, orderValidators } from '../../../validators';
import CandidateOrderEdit from '../../CandidateOrder/CandidateOrderEdit/CandidateOrderEdit';
import {
  CustomEditActions,
  DeleteConfirmButton,
  EmailField,
  EmptyField,
  RepeatedStringField,
  TagAutocompleteInput,
  ThousandsNumberInput,
  Toolbar,
  UploadedFileField,
} from '../../framework';
import { ObjectStateSelect, ObjectStateStepper } from '../../ObjectState';
import AddCandidateButton from './AddCandidateButton';
import OrderEditPageTitle from './OrderEditPageTitle';

const OrderEdit = ({ permissions, ...props }) => (
  <Edit
    actions={<CustomEditActions />}
    title={<OrderEditPageTitle {...props} />}
    undoable={false}
    {...props}
  >
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab contentClassName="tab-inline" label="form.order.tabs.general">
        <Responsive
          small={
            <ObjectStateStepper
              choices={Order.ORDER_STATE_ID.values}
              source="object_state_id"
              orientation="vertical"
            />
          }
          medium={
            <ObjectStateStepper choices={Order.ORDER_STATE_ID.values} source="object_state_id" />
          }
          formClassName="ra-input__noflex"
          fullWidth
        />
        {permissions && permissions.canList('customer') ? (
          <ReferenceField
            formClassName="ra-input__flex-25"
            reference="customer"
            source="customer_id"
          >
            <TextField source="business_name" />
          </ReferenceField>
        ) : (
          <EmptyField source="customer_id" />
        )}
        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="job_title"
          type={Tag.TYPE.JOB_NAME}
          validate={orderValidators.job_title}
        />
        <SelectInput
          choices={Order.DOMAINS_LEVEL.values}
          defaultValue={Order.DOMAINS_LEVEL.default}
          formClassName="ra-input__flex-25"
          source="job_qualification"
          validate={orderValidators.job_qualification}
        />
        <DateInput
          formClassName="ra-input__flex-25"
          source="job_start_date"
          validate={orderValidators.job_start_date}
        />
        <ThousandsNumberInput
          formClassName="ra-input__flex-25"
          source="salary_min"
          validate={orderValidators.salary_min}
        />
        <ThousandsNumberInput
          formClassName="ra-input__flex-25"
          source="salary_max"
          validate={orderValidators.salary_max}
        />
        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="location"
          type={Tag.TYPE.LOCATION}
          validate={orderValidators.location}
        />
        <DateInput
          formClassName="ra-input__flex-25"
          source="ordered_at"
          validate={orderValidators.ordered_at}
        />
        <BooleanInput
          formClassName="ra-input__flex-25 ra-input__v-align"
          source="offer_registred"
          validate={orderValidators.offer_registrerd}
        />
        <BooleanInput
          formClassName="ra-input__flex-25 ra-input__v-align"
          source="use_deposit"
          validate={orderValidators.use_deposit}
        />
        <FormDataConsumer formClassName="ra-input__flex-25 ra-input__v-align">
          {({ formData, ...rest }) =>
            formData && formData.use_deposit ? (
              <BooleanInput
                {...rest}
                source="deposit_payed"
                validate={orderValidators.deposit_payed}
              />
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer formClassName="ra-input__flex-25">
          {({ formData, ...rest }) =>
            formData && formData.use_deposit && formData.deposit_payed ? (
              <TextInput
                {...rest}
                source="deposit_invoice_number"
                validate={orderValidators.deposit_invoice_number}
              />
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          formClassName="ra-input__flex-25"
          source="quotation_number"
          validate={orderValidators.quotation_number}
        />
        <LongTextInput
          formClassName="ra-input__flex-25"
          source="quotation_comments"
          validate={orderValidators.quotation_comments}
        />
        <FormDataConsumer formClassName="ra-input__flex-25">
          {({ formData, ...rest }) => (
            <DateInput
              {...rest}
              disabled={rest.record.object_state_id !== Order.OBJECT_STATE_ID.CANCELLED}
              source="cancel_date"
              validate={orderValidators.cancel_date}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer formClassName="ra-input__flex-25">
          {({ formData, ...rest }) => (
            <LongTextInput
              {...rest}
              disabled={rest.record.object_state_id !== Order.OBJECT_STATE_ID.CANCELLED}
              source="cancel_reason"
              validate={orderValidators.cancel_reason}
            />
          )}
        </FormDataConsumer>
        <FileInput
          formClassName="ra-input__flex-50"
          fullWidth
          multiple
          source="upload_files"
          validate={orderValidators.upload_files}
        >
          <UploadedFileField source="src" target="_blank" title="title" />
        </FileInput>
        <DateField formClassName="ra-input__flex-25" source="created_at" showTime />
        <DateField formClassName="ra-input__flex-25" source="updated_at" showTime />
      </FormTab>

      <FormTab label="form.order.tabs.invoices" path="invoices">
        <ArrayInput
          className="array-input__simple-form-iterator--inline"
          fullWidth
          source="invoices"
        >
          <SimpleFormIterator>
            <TextInput
              label="resources.invoice.fields.number"
              source="number"
              validate={invoiceValidators.number}
            />
            <SelectInput
              label="resources.invoice.fields.object_state_id"
              source="object_state_id"
              choices={Invoice.INVOICE_STATE_ID.values}
              validate={invoiceValidators.object_state_id}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="form.order.tabs.candidates" path="candidates">
        <AddCandidateButton />
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference="candidate_order"
          target="order_id"
        >
          <Responsive
            small={
              <SimpleList
                primaryText={record =>
                  permissions &&
                  permissions.canList('candidate') && (
                    <ReferenceField
                      basePath={props.basePath}
                      label="resources.candidate.fields.last_name"
                      linkType={false}
                      record={record}
                      reference="candidate"
                      source="candidate_id"
                    >
                      <FunctionField render={Candidate.getFullName} />
                    </ReferenceField>
                  )
                }
                secondaryText={record => (
                  <SelectField
                    source="object_state_id"
                    choices={CandidateOrder.OBJECT_STATE_ID.values}
                    record={record}
                  />
                )}
              />
            }
            medium={
              <Datagrid
                expand={<CandidateOrderEdit title=" " noRedirect permissions={permissions} />}
              >
                {permissions && permissions.canList('candidate') && (
                  <ReferenceField
                    label="resources.candidate.fields.last_name"
                    linkType={false}
                    reference="candidate"
                    source="candidate_id"
                  >
                    <TextField source="last_name" />
                  </ReferenceField>
                )}
                {permissions && permissions.canList('candidate') && (
                  <ReferenceField
                    label="resources.candidate.fields.first_name"
                    linkType={false}
                    reference="candidate"
                    source="candidate_id"
                  >
                    <TextField source="first_name" />
                  </ReferenceField>
                )}
                {permissions && permissions.canList('candidate') && (
                  <ReferenceField
                    label="resources.candidate.fields.emails"
                    linkType={false}
                    reference="candidate"
                    source="candidate_id"
                  >
                    <RepeatedStringField source="emails" orientation="column">
                      <EmailField />
                    </RepeatedStringField>
                  </ReferenceField>
                )}
                {permissions && permissions.canList('candidate') && (
                  <ReferenceField
                    label="resources.candidate.fields.phones"
                    linkType={false}
                    reference="candidate"
                    source="candidate_id"
                  >
                    <RepeatedStringField source="phones" orientation="column">
                      <TextField />
                    </RepeatedStringField>
                  </ReferenceField>
                )}
                <ObjectStateSelect
                  source="object_state_id"
                  choices={CandidateOrder.OBJECT_STATE_ID.values}
                  readOnly
                />
                {permissions && permissions.canDelete('candidate_order') && (
                  <DeleteConfirmButton label="" redirect={false} />
                )}
              </Datagrid>
            }
          />
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

OrderEdit.propTypes = {
  basePath: PropTypes.string,
  permissions: PropTypes.object,
};

export default OrderEdit;
