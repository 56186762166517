export default {
  resource_updated: '%{resource_name} mis à jour',
  resource_created: '%{resource_name} créé',
  resource_deleted: '%{resource_name} supprimé |||| %{smart_count} %{resource_name} supprimés',
  resource_bad_item: '%{resource_name} inconnu',
  printing: 'Impression en cours ...',
  created_many: 'Élément créé |||| Éléments créés',
  email_sent: 'Email envoyé |||| Emails envoyés',
  user_role: {
    added_success: 'Rôle ajouté avec succès',
    removed_success: 'Rôle retiré avec succès',
  },
};
