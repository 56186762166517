import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import inflection from 'inflection';
import { reset } from 'redux-form';
import { translate, crudUpdate, startUndoable, REDUX_FORM_NAME } from 'react-admin';
import { crudGetOneWith } from '../../../actions/crudActions';
import checkMinimumRequiredProps from './checkMinimumRequiredProps';

/**
 * Page component for the Edit view
 * Same behavior than react-admin Edit component, but load related objects with the current one.
 */
export class EditController extends Component {
  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id || prevProps.version !== this.props.version) {
      this.props.resetForm(REDUX_FORM_NAME);
      this.updateData(this.props.resource, this.props.id);
    }
  }

  defaultRedirectRoute() {
    return 'list';
  }

  updateData(resource = this.props.resource, id = this.props.id) {
    this.props.crudGetOneWith(
      resource,
      id,
      this.props.relations,
      this.props.methods,
      this.props.methodsParams,
      this.props.count,
      this.props.basePath
    );
  }

  save = (data, redirect) => {
    const { undoable = true, startUndoable, dispatchCrudUpdate } = this.props;
    if (undoable) {
      startUndoable(
        crudUpdate(
          this.props.resource,
          this.props.id,
          data,
          this.props.record,
          this.props.basePath,
          redirect
        )
      );
    } else {
      dispatchCrudUpdate(
        this.props.resource,
        this.props.id,
        data,
        this.props.record,
        this.props.basePath,
        redirect
      );
    }
  };

  render() {
    const { basePath, children, id, isLoading, record, resource, translate, version } = this.props;

    if (!children) return null;

    const resourceName = translate(`resources.${resource}.name`, {
      smart_count: 1,
      _: inflection.humanize(inflection.singularize(resource)),
    });
    const defaultTitle = translate('ra.page.edit', {
      name: `${resourceName}`,
      id,
      record,
    });

    return children({
      isLoading,
      defaultTitle,
      save: this.save,
      resource,
      basePath,
      record,
      redirect: this.defaultRedirectRoute(),
      translate,
      version,
    });
  }
}

EditController.propTypes = {
  basePath: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  crudGetOneWith: PropTypes.func.isRequired,
  dispatchCrudUpdate: PropTypes.func.isRequired,
  record: PropTypes.object,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  methods: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  methodsParams: PropTypes.object,
  relations: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resetForm: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func.isRequired,
  title: PropTypes.any,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  version: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
  return {
    id: props.id,
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

export default compose(
  checkMinimumRequiredProps('Edit', ['basePath', 'resource']),
  connect(
    mapStateToProps,
    {
      crudGetOneWith,
      dispatchCrudUpdate: crudUpdate,
      startUndoable,
      resetForm: reset,
    }
  ),
  translate
)(EditController);
