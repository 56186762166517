import { createMuiTheme } from '@material-ui/core/styles';
import { cloneDeep, merge } from 'lodash';
import { defaultTheme } from 'react-admin';
import { DEFAULT_THEME } from '../../settings';
import red from '@material-ui/core/colors/red';

const customs = type => ({
  custom: {
    stateLabel: {
      misty: '#ced0d2',
      astrogranite: '#747678',
      mattBlack: '#161614',
      salmon: '#fa6262',
      summer: '#f8e505',
      orange: '#ffa500',
      red: '#ff0000',
      energyPeak: '#bf5d5d',
      lettuce: '#b1d689',
      lime: '#8cc54f',
      navyGreen: '#34570e',
    },
    label: {
      red: red[500],
    },
  },
});

const overrides = {
  MuiInputLabel: {
    shrink: {
      fontWeight: 'bold',
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 'bold',
    },
  },
};

export const lightTheme = createMuiTheme({
  ...defaultTheme,
  palette: {
    primary: { main: '#7ec31f' },
    secondary: { main: '#7ec31f' },
  },
  ...customs('light'),
});

lightTheme.overrides = overrides;

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: '#7ec31f' },
    secondary: { main: '#7ec31f' },
    background: {
      paper: '#4D4D4D',
    },
  },
  ...customs('dark'),
});

darkTheme.overrides = merge(cloneDeep(overrides), {
  MuiTab: {
    textColorInherit: {
      color: darkTheme.palette.text.primary,
    },
  },
});

export default DEFAULT_THEME === 'dark' ? darkTheme : lightTheme;
