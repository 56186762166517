import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { changeTheme as changeThemeAction } from '../../actions/themeActions';
import { createStyles, withStyles } from '@material-ui/core';
import LightIcon from '@material-ui/icons/BrightnessHigh';
import DarkIcon from '@material-ui/icons/BrightnessLow';

const styles = theme =>
  createStyles({
    menuItem: {
      color: theme.palette.text.secondary,
    },
    menuItemActive: {
      color: theme.palette.text.primary,
    },
    icon: {
      paddingRight: '.8em',
    },
  });

class ThemeSwitcher extends React.PureComponent {
  handleClick = event => {
    event.stopPropagation();
    const { changeTheme, isDarkTheme, onClick } = this.props;

    if (onClick) {
      // onClick is set only if UserMenu context.
      onClick();
    }

    changeTheme(isDarkTheme ? 'light' : 'dark');
  };

  render() {
    const { classes, isDarkTheme, translate } = this.props;

    return (
      <MenuItem className={classes.menuItem} onClick={this.handleClick}>
        {isDarkTheme ? (
          <LightIcon className={classes.icon} />
        ) : (
          <DarkIcon className={classes.icon} />
        )}
        {translate(`action.theme.${isDarkTheme ? 'disable' : 'enable'}`)}
      </MenuItem>
    );
  }
}

ThemeSwitcher.propTypes = {
  changeTheme: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isDarkTheme: state.theme === 'dark',
    theme: state.theme,
  };
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(
    mapStateToProps,
    { changeTheme: changeThemeAction }
  )
);

export default enhance(ThemeSwitcher);
