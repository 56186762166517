export class User {
  /**
   * Get user query filter when searching for a user.
   * Currently searching by user name.
   * @param {String} searchText the searched text.
   * @returns {Object}
   */
  static filterToQuery = searchText => ({ name: searchText });
  /**
   * The default sort order when listing users.
   * @var {Object} sortOrder
   */
  static sortOrder = { field: 'name', order: 'ASC' };
}
