import EmailIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import { Confirm } from '../framework';
import { executeMethodPost } from '../../actions/methodActions';

class SendMailButton extends React.Component {
  state = { open: false };

  /**
   * Call server action to send email.
   */
  handleConfirm = () => {
    const { basePath, executeMethodPost, record, resource } = this.props;

    executeMethodPost('sendEmail', resource, record.id, {}, {}, basePath, false, {
      notification_success: 'notification.email_sent',
    });
    this.closeConfirmation();
  };

  /**
   * Cancel email sending.
   */
  handleCancel = () => {
    this.closeConfirmation();
  };

  /**
   * Close confirmation modal.
   */
  closeConfirmation = () => {
    this.setState({ open: false });
  };

  /**
   * Open confirmation modal.
   * @param {object} event the click event.
   */
  handleClick = event => {
    event.stopPropagation();
    this.setState({ open: true });
  };

  /**
   * Disable button if email has already been sent.
   * @returns {boolean}
   */
  isDisabled = () => !this.props.record || Boolean(this.props.record.email_status);

  render() {
    const { confirm_message, confirm_title, icon, label } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Button disabled={this.isDisabled()} label={label} onClick={this.handleClick}>
          {icon}
        </Button>
        <Confirm
          open={open}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          content={confirm_message}
          title={confirm_title}
        />
      </React.Fragment>
    );
  }
}

SendMailButton.propTypes = {
  basePath: PropTypes.string,
  executeMethodPost: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
};

SendMailButton.defaultProps = {
  label: 'action.send_email',
  confirm_message: 'message.send_email',
  confirm_title: 'action.send_email',
  icon: <EmailIcon />,
};

const enhance = connect(
  undefined,
  { executeMethodPost }
);

export default enhance(SendMailButton);
