import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames';
import compose from 'recompose/compose';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { translate } from 'react-admin';
import { isObject } from 'util';
import get from 'lodash/get';

/**
 * Get min width for the autocomplete field.
 * The width is different by browser, to get a closest result of react-admin
 * input width (inherited from browser).
 * The react-select plugin is setting a f***ing `style: {width: 2px}` on input element.
 */
const getMinWidth = () => {
  if (navigator.userAgent.search(/trident/i) > 0) {
    // Internet Explorer
    return '200px';
  } else if (navigator.userAgent.search(/webkit/i) > 0) {
    // Chrome, Safari
    return '200px';
  } else if (navigator.userAgent.search(/gecko/i) > 0) {
    // Firefox
    return '194px';
  } else {
    // other
    return '200px';
  }
};

/**
 * Styles for autocomplete input.
 * To override default style of menu list or other, see https://react-select.com/styles
 */
const styles = theme =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
      fontSize: '1rem',
      marginBottom: '1em',
    },
    input: {
      display: 'flex',
      padding: '2px 0 3px 0',
    },
    inputSize: {
      minWidth: getMinWidth(),
    },
    inputLabel: {},
    inputLabelShrink: {
      top: 0,
    },
    valueContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    valueContainerMulti: {
      flexWrap: 'wrap',
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      fontSize: '1rem',
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: '1rem',
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: '1rem',
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
  });

/**
 * Text displayed when no option is matching.
 * @param {*} props component properties.
 */
const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);
/**
 * Text displayed when an option is selected
 * @param {*} props component properties.
 */
const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;
/**
 * Input component displayed to wrap autoselect.
 * @param {*} props component properties.
 */
const Control = ({
  children,
  hasValue,
  innerProps,
  innerRef,
  selectProps: {
    classes,
    error,
    fullWidth,
    helperText,
    inputSize,
    isDisabled,
    isRequired,
    margin,
    name,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    placeholder,
  },
}) => {
  const rest = {
    error,
    fullWidth,
    helperText,
    margin,
    name,
  };

  /**
   * Wrap a method to inject input name in event.target.
   * Because the input used is elumated by react-select, target name is not set.
   * @param {function} method the method to wrap.
   */
  const wrapEvent = method => event => {
    if (method) {
      event.target.name = name;
      method(event);
    }
  };

  return (
    <TextField
      disabled={isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classnames(classes.input, {
            [classes.inputSize]: inputSize === 'auto',
          }),
          ref: innerRef,
          children: children,
          ...innerProps,
        },
      }}
      // set undefined if no value to preserve placeholder label animation.
      InputLabelProps={{
        shrink: hasValue === true || undefined,
        classes: {
          root: classes.inputLabel,
          shrink: classes.inputLabelShrink,
        },
      }}
      label={placeholder}
      required={isRequired}
      onKeyDown={wrapEvent(onKeyDown)}
      onKeyPress={wrapEvent(onKeyPress)}
      onKeyUp={wrapEvent(onKeyUp)}
      {...rest}
    />
  );
};

/**
 * Display format for each option.
 * @param {*} props component properties.
 */
const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);
/**
 * Text displayed as input placeholder.
 * @param {*} props component properties.
 */
// current placeholder is null, already managed by `Control` component (TextField).
const Placeholder = props => null;
//   <Typography
//     color="textSecondary"
//     className={props.selectProps.classes.placeholder}
//     {...props.innerProps}
//   >
//     {props.children}
//   </Typography>
/**
 * Arrow displayed as dropdown selector.
 * @param {*} props component properties.
 */
const DropdownIndicator = props => null;
/**
 * Separator between `Control` and `DropdownIndicator`.
 * @param {*} props component properties.
 */
const IndicatorSeparator = props => null;
/**
 * Component displayed when a single value is selected.
 * @param {*} props component properties.
 */
const SingleValue = props => (
  <Typography
    className={classnames(
      props.selectProps.classes.singleValue,
      props.selectProps.isDisabled && props.selectProps.classes.disabled
    )}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);
/**
 * Option value container.
 * @param {*} props component properties.
 */
const ValueContainer = props => (
  <div
    className={classnames(
      props.selectProps.classes.valueContainer,
      props.isMulti && props.selectProps.classes.valueContainerMulti
    )}
  >
    {props.children}
  </div>
);
/**
 * Component displayed when multiple values selected.
 * @param {*} props component properties.
 */
const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classnames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={event => {
      props.removeProps.onClick();
      props.removeProps.onMouseDown(event);
    }}
  />
);

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  DropdownIndicator, // remove this entry to restore dropdown indicator.
  IndicatorSeparator, // remove this entry to restore dropdown indicator.
  SingleValue,
  MultiValue,
  ValueContainer,
};

/**
 * Useless, but fixes search with a space for lists fetched from server.
 * @see https://github.com/JedWatson/react-select/issues/1641
 */
const uselessFilter = a => a;

class AutocompleteInput extends React.Component {
  /**
   * Handle new value seletion.
   * @param {object} value the selected value.
   * @see https://react-select.com/props#select-props
   */
  handleUpdate = (value, actionMeta) => {
    const { creatable, isMulti, onChange, input } = this.props;

    let newValue = value;

    if (!creatable) {
      if (isMulti) {
        newValue = (value || []).map(val => this.getOptionValue(val));
      } else {
        newValue = this.getOptionValue(value) || null;
      }
    }

    if (onChange) {
      onChange(newValue, actionMeta);
    }

    if (input && input.onChange) {
      input.onChange(newValue);
    }
  };

  /**
   * Handle input change.
   * @param {string} value text input value.
   * @param {object} event the type of change.
   *     Can be one of `set-value`,`input-change`,`input-blur`,`menu-close`.
   * @see https://react-select.com/props#select-props
   */
  handleInputChange = (value, event) => {
    const { setFilter } = this.props;

    if (event.action === 'input-change' && setFilter) {
      setFilter(value);
    }
  };

  /**
   * Get label to display for an option.
   * @param {object} option the option to display.
   * @returns {string} the label to display.
   */
  getOptionValue = option => option && option[this.props.optionValue];

  /**
   * Get the value to use in the form for an option.
   * @param {object} option the option to use.
   * @returns {*} the value to use in the form.
   */
  getOptionLabel = option =>
    option &&
    (typeof this.props.optionText === 'function'
      ? this.props.optionText(option)
      : option[this.props.optionText]);

  /**
   * Get an option matching a given value.
   * This is the opposite as `getOptionValue` method.
   * @returns {object} the option mathcing the value.
   * @see getOptionValue
   */
  getInitialOptionValue = () => {
    const { creatable, input, isMulti, meta, optionValue, value } = this.props;
    let initialValue;

    if (typeof value !== 'undefined') {
      // if component outside of a form.
      initialValue = value;
    } else if (input && typeof input.value !== 'undefined') {
      // with a `ReferenceInput` and outside of a redux form
      initialValue = input.value;
    } else if (meta && typeof meta.initial !== 'undefined') {
      // in a redux form
      initialValue = meta.initial;
    }

    if (isMulti) {
      if (Array.isArray(initialValue)) {
        if (creatable) {
          return initialValue;
        } else {
          const initialChoicesIndexes = initialValue.reduce(
            (acc, choice) => [...acc, isObject(choice) ? choice[optionValue] : choice],
            []
          );
          return this.props.choices.filter(choice =>
            initialChoicesIndexes.includes(choice[optionValue])
          );
        }
      }
    } else {
      return this.props.choices.find(option => option && option[optionValue] === initialValue);
    }
  };

  /**
   * Get label to display as placeholder regarding component properties.
   * @returns {string} the placeholder label.
   */
  getPlaceholderLabel = () => {
    const { label, resource, source, translate } = this.props;
    if (label) {
      return label;
    }

    if (resource && source) {
      return translate(`resources.${resource}.fields.${source}`);
    }
  };

  /**
   * Determines whether the "create new ..." option should be displayed based on the current
   * input value, select value and options array.
   * @param {string} inputValue the input text.
   * @param {array|object} selectedValue values already selected in list (may be empty).
   * @param {array} selectOptions the list of options matching the inputValue.
   * @returns {boolean}
   */
  isValidNewOption = (inputValue, selectedValue, selectOptions) =>
    inputValue && selectOptions.length === 0;

  /**
   * Gets the label for the "create new ..." option in the menu. Is given the current input value.
   * @param {string} inputValue the input text.
   * @returns {string}
   */
  formatCreateLabel = inputValue => {
    const { creatableLabel, translate } = this.props;

    if (creatableLabel) {
      return translate(creatableLabel, { option: inputValue });
    }

    return translate('input.autocomplete.create', {
      name: inputValue,
      _: `Create "${inputValue}"`,
    });
  };

  /**
   * Returns an object to use when option "create new..." is displayed. This object will be passed to
   * onChange method.
   * @param {string} inputValue the text set in the input.
   * @param {string} optionLabel label displayed ("create new..."). Sent by `formatCreateLabel`.
   * @returns {object}
   * @see formatCreateLabel
   */
  getNewOptionData = (inputValue, optionLabel) => ({
    [this.props.optionValue]: null,
    [typeof this.props.optionText === 'function' ? 'value' : this.props.optionText]: optionLabel,
  });

  /**
   * Get custom style for item menuPortal, according to current props.
   * @returns function a function if there are custom style properties, else null.
   */
  getMenuPortalCustomStyle = () => {
    const { menuPortalZIndex } = this.props;
    const menuPortal = {};

    if (menuPortalZIndex !== undefined) {
      menuPortal.zIndex = menuPortalZIndex;
    }

    return menuPortal;
  };

  /**
   * Get error status and help text from properties.
   * @returns {object} a boolean to know if field is invalid, with an message.
   */
  getErrorHelper = () => {
    const { meta } = this.props;
    let { error, helperText } = this.props;

    if (!error && meta && meta.touched && meta.invalid) {
      error = true;
    }
    if (!helperText && meta && meta.touched && meta.error) {
      helperText = meta.error;
    }

    return { error, helperText };
  };

  render() {
    const {
      allowEmpty,
      choices,
      classes,
      className,
      classToUse,
      creatable,
      disabled,
      fullWidth,
      input,
      inputSize,
      isMulti,
      isRequired,
      loadingMessage,
      margin,
      menuPosition,
      noOptionsMessage,
      onCreateOption,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      position,
      reactSelectProps,
      setFilter,
      theme,
      translate,
    } = this.props;
    const { error, helperText } = this.getErrorHelper();

    let autoCompleteClassToUse = classToUse;
    const customStyles = {
      clearIndicator: base => ({
        ...base,
        padding: 0,
        color: theme.palette.action.active,
        '&:hover': {
          color: theme.palette.action.active,
        },
      }),
      input: base => ({ ...base, color: theme.palette.text.primary }),
      menu: base => ({ ...base, backgroundColor: theme.palette.background.paper }),
    };

    const menuPortalStyle = this.getMenuPortalCustomStyle();
    if (Object.keys(menuPortalStyle).length) {
      customStyles.menuPortal = base => ({ ...base, ...menuPortalStyle });
      // auto-width for suggestion list
      customStyles.menu = base => ({
        ...base,
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
        ...get(theme, 'overrides.MuiPaper.rounded', {}),
      });
    }

    const noOptions =
      typeof noOptionsMessage === 'function'
        ? noOptionsMessage
        : ({ inputValue }) => translate(noOptionsMessage, { _: noOptionsMessage, inputValue });

    const loadingMessageFn =
      typeof loadingMessage === 'function'
        ? loadingMessage
        : ({ inputValue }) => translate(loadingMessage, { _: loadingMessage, inputValue });

    const props = {
      classes,
      className,
      components,
      error,
      filterOption: setFilter ? uselessFilter : undefined,
      fullWidth,
      getOptionLabel: this.getOptionLabel,
      getOptionValue: this.getOptionValue,
      helperText,
      inputSize,
      isClearable: allowEmpty,
      isDisabled: disabled,
      isMulti,
      isRequired,
      loadingMessage: loadingMessageFn,
      margin,
      menuPlacement: position,
      menuPosition,
      name: input && input.name,
      noOptionsMessage: noOptions,
      onChange: this.handleUpdate,
      onInputChange: this.handleInputChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      options: choices,
      placeholder: this.getPlaceholderLabel(),
      value: this.getInitialOptionValue(),
      styles: customStyles,
      translate,
      ...reactSelectProps,
    };

    if (creatable) {
      autoCompleteClassToUse = CreatableSelect;
      props.isValidNewOption = this.isValidNewOption;
      props.formatCreateLabel = this.formatCreateLabel;
      props.onCreateOption = onCreateOption;
      props.getNewOptionData = this.getNewOptionData;
    }

    return React.createElement(autoCompleteClassToUse, props);
  }
}

AutocompleteInput.propTypes = {
  allowEmpty: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  creatable: PropTypes.bool,
  creatableLabel: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  inputSize: PropTypes.oneOf(['auto', 'unset']),
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  loadingMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  margin: PropTypes.string,
  menuPosition: PropTypes.string,
  menuPortalZIndex: PropTypes.number, // update zIndex if menu overrides some components having zIndex too.
  noOptionsMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  optionValue: PropTypes.string,
  position: PropTypes.oneOf(['auto', 'top', 'bottom']),
  reactSelectProps: PropTypes.object,
  resource: PropTypes.string,
  setFilter: PropTypes.func,
  source: PropTypes.string,
  theme: PropTypes.object,
  translate: PropTypes.func.isRequired,
  value: PropTypes.node,
};

AutocompleteInput.defaultProps = {
  allowEmpty: false,
  choices: [],
  classToUse: ReactSelect,
  creatable: false,
  disabled: false,
  error: false,
  fullWidth: true,
  inputSize: 'auto',
  isMulti: false,
  isRequired: false,
  loadingMessage: 'input.autocomplete.loading',
  margin: 'normal',
  menuPortalZIndex: 2,
  menuPosition: 'fixed',
  noOptionsMessage: 'input.autocomplete.no_options',
  optionValue: 'id',
  optionText: 'name',
  position: 'auto',
  reactSelectProps: {},
};

const enhance = compose(translate, withStyles(styles), withTheme());

export default enhance(AutocompleteInput);
