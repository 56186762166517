import { email, maxLength, number } from 'react-admin';
import { notEmpty } from './validators';

const identicalPassword = (value, allValues, props) => {
  if (
    (!allValues.password && !allValues.repassword) ||
    allValues.password === allValues.repassword
  ) {
    return undefined;
  }
  return props.translate('validation.passwords_not_equal');
};

export default {
  enabled: [number()],
  name: [notEmpty(), maxLength(64)],
  email: [notEmpty(), maxLength(64), email()],
  password: {
    create: [notEmpty(), maxLength(256), identicalPassword],
    update: [maxLength(256), identicalPassword],
  },
};
