import debounce from 'lodash/debounce';

/**
 * Launch server request to check phone validity.
 * @param {function} dataProvider the provider function
 * @param {string} resource the resource name.
 * @param {object} payload the request payload.
 * @param {function} onSuccess the success callback.
 * @param {function} onError the error callback.
 */
const debouncedRequest = debounce((dataProvider, resource, payload, onSuccess, onError) => {
  dataProvider('METHOD_GET', resource, payload).then(onSuccess).catch(onError);
}, 500);

/**
 * Hack async validation process to show warning if phone number is already used.
 * @param {object} componentProps the form properties.
 * @param {object} values the form values.
 * @param {function} dispatch the state dispatch method.
 * @param {object} reduxProps the redux form properties.
 * @param {string} blurredField the field being edited
 * @return {Promise}
 */
export const asyncValidatePhone = (componentProps, values, dispatch, reduxProps, blurredField) => {
  const { updateSyncWarnings, syncWarnings } = reduxProps;
  const { dataProvider, permissions, resource } = componentProps;

  if (
    permissions &&
    permissions.can(resource, 'check_phone') &&
    blurredField &&
    blurredField.startsWith('phones')
  ) {
    const { id, phones } = values;

    return new Promise((resolve, reject) => {
      const payload = {
        data: { id, phones: JSON.stringify(phones) },
        methodName: 'checkPhone',
      };
      debouncedRequest(dataProvider, resource, payload, resolve, reject);
    })
      .then(({ data }) => {
        dispatch(
          updateSyncWarnings({
            ...syncWarnings,
            phones: Array.isArray(data) ? data : undefined,
          })
        );
      })
      .catch(() => {});
  }

  return new Promise(resolve => resolve());
};

/**
 * Hack async validation process to show warning if email address is already used.
 * @param {object} componentProps the form properties.
 * @param {object} values the form values.
 * @param {function} dispatch the state dispatch method.
 * @param {object} reduxProps the redux form properties.
 * @param {string} blurredField the field being edited
 * @return {Promise}
 */
export const asyncValidateEmail = (componentProps, values, dispatch, reduxProps, blurredField) => {
  const { updateSyncWarnings, syncWarnings } = reduxProps;
  const { dataProvider, permissions, resource } = componentProps;

  if (
    permissions &&
    permissions.can(resource, 'check_email') &&
    blurredField &&
    blurredField.startsWith('emails')
  ) {
    const { id, emails } = values;

    return new Promise((resolve, reject) => {
      const payload = {
        data: { id, emails: JSON.stringify(emails) },
        methodName: 'checkEmail',
      };
      debouncedRequest(dataProvider, resource, payload, resolve, reject);
    })
      .then(({ data }) => {
        dispatch(
          updateSyncWarnings({
            ...syncWarnings,
            emails: Array.isArray(data) ? data : undefined,
          })
        );
      })
      .catch(() => {});
  }

  return new Promise(resolve => resolve());
};
