export default {
  theme: {
    enable: 'Activer le mode sombre',
    disable: 'Désactiver le mode sombre',
  },
  send_reporting: 'Envoyer au client',
  create_reporting: 'Créer un rapport client',
  no_send_reporting_bulk:
    'Un des rapports sélectionnés a déjà été envoyé |||| Certains rapports ont déjà été envoyés',
  download: 'Télécharger',
  send_email: "Envoyer l'email",
};
