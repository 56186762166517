import { createStyles, withStyles } from '@material-ui/core/styles';
import ExportIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { downloadStatic } from '../../../actions/downloadActions';

const styles = theme =>
  createStyles({
    root: {
      color: theme.palette.type === 'dark' && theme.palette.text.primary,
    },
  });

const OrderExportBulkButton = ({
  basePath,
  classes,
  downloadStatic,
  icon,
  label,
  resource,
  selectedIds,
}) => {
  /**
   * Download selected orders in an excel file.
   */
  const handleClick = useCallback(() => {
    downloadStatic('download', resource, { ids: JSON.stringify(selectedIds) }, basePath, false, {
      unselectAll: true,
    });
  }, [basePath, downloadStatic, resource, selectedIds]);

  return (
    <Button className={classes.root} label={label} onClick={handleClick}>
      {icon}
    </Button>
  );
};

OrderExportBulkButton.propTypes = {
  classes: PropTypes.object.isRequired,
  downloadStatic: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string,
  selectedIds: PropTypes.array,
};
OrderExportBulkButton.defaultProps = {
  icon: <ExportIcon />,
  label: 'ra.action.export',
  selectedIds: [],
};

const enhance = compose(connect(undefined, { downloadStatic }), withStyles(styles));
export default enhance(OrderExportBulkButton);
