import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import TextWarningInput from './TextWarningInput';

const mask = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      keepCharPositions
      mask={mask}
      // placeholderChar={'\u2000'}
      ref={inputRef}
      showMask
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const PhoneMaskTextInput = props => (
  <TextWarningInput {...props} InputProps={{ inputComponent: TextMaskCustom }} />
);

export default PhoneMaskTextInput;
