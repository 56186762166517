import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchUtils, showNotification, translate } from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { FORGOTTEN_PASSWORD_URL } from '../../settings';

const forgottenPasswordPromptStyles = theme =>
  createStyles({
    scrollPaper: {
      alignItems: 'start',
    },
    loadingIcon: {
      marginRight: theme.spacing.unit,
    },
  });

class PasswordPrompt extends React.Component {
  state = { error: false, loading: false, value: '' };

  /**
   * Handle cancel button click.
   * @param {Event} event the event generated by user action.
   */
  handleCancel = event => {
    event.stopPropagation();
    this.props.onCancel();
  };

  /**
   * Handle submit button click
   * @param {Event} event the event generated by user action.
   */
  handleSubmit = event => {
    if (event) {
      event.stopPropagation();
    }
    const { onSubmit, showNotification } = this.props;
    const { error, value } = this.state;

    if (!value || error) {
      return false;
    }

    const sendRequest = () =>
      fetchUtils
        .fetchJson(FORGOTTEN_PASSWORD_URL, {
          method: 'POST',
          body: JSON.stringify({ email: value }),
        })
        .then(() => {
          showNotification('auth.forgotten_password_success');
          this.setState({ value: '' });
          onSubmit(); // finish action
        })
        .catch(e => {
          showNotification('ra.notification.http_error', 'warning');
        })
        .finally(() => {
          this.setState({ error: false, loading: false });
        });

    this.setState({ loading: true }, sendRequest);
  };

  /**
   * Handle input value change.
   * @param {Event} event the event generated by user action.
   */
  handleUpdateValue = event => {
    event.stopPropagation();
    this.updateValue(event.target.value);
  };

  /**
   * Submit form if enter key is pressed.
   * @param {Object} event the event generated.
   */
  handleKeyUp = event => {
    if (event && event.keyCode === 13) {
      // enter
      this.handleSubmit();
    }
  };

  /**
   * Quick validation of email address.
   */
  isEmailValid = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase());
  };

  /**
   * Update email value.
   * @param {string} value the new email value.
   */
  updateValue = value => {
    this.setState({ error: !this.isEmailValid(value), value });
  };

  render() {
    const { classes, open, translate } = this.props;
    const { error, loading, value } = this.state;
    const dialogClasses = { scrollPaper: classes.scrollPaper };

    return (
      <Dialog
        classes={dialogClasses}
        open={open}
        onClose={this.handleCancel}
        aria-labelledby="forgotten-password-dialog-title"
      >
        <DialogTitle id="forgotten-password-dialog-title">
          {translate('auth.forgotten_password_form.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('auth.forgotten_password_form.description')}
          </DialogContentText>
          <TextField
            autoFocus
            error={error}
            fullWidth
            helperText={error ? translate('ra.validation.email') : undefined}
            id="email"
            label={translate('resources.user.fields.email')}
            onChange={this.handleUpdateValue}
            onKeyUp={this.handleKeyUp}
            margin="dense"
            type="email"
            value={value}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="primary" onClick={this.handleCancel}>
            {translate('ra.action.cancel')}
          </Button>
          <Button disabled={loading} color="primary" onClick={this.handleSubmit} variant="raised">
            {loading && (
              <CircularProgress size={18} thickness={2} className={classes.loadingIcon} />
            )}
            {translate('auth.forgotten_password_form.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PasswordPrompt.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  translate: PropTypes.func.isRequired,
};

const ForgottenPasswordPrompt = compose(
  translate,
  withStyles(forgottenPasswordPromptStyles),
  connect(undefined, { showNotification })
)(PasswordPrompt);

const forgottenPasswordStyles = theme =>
  createStyles({
    link: {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      outline: 'none',
    },
  });

class ForgottenPassword extends React.Component {
  state = { open: false };

  handleSubmit = event => {
    this.hideForm();
  };

  handleCancel = event => {
    this.hideForm();
  };

  hideForm = () => {
    this.setState({ open: false });
  };

  /**
   * Handle click on forgotten link.
   */
  handleClick = event => {
    event.stopPropagation();
    this.setState({ open: true });
  };

  render() {
    const { classes, translate } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <button className={classes.link} onClick={this.handleClick} type="button">
          <Typography color="primary">{translate('auth.forgotten_password')}</Typography>
        </button>
        <ForgottenPasswordPrompt
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          open={open}
        />
      </React.Fragment>
    );
  }
}

ForgottenPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const enhance = compose(translate, withStyles(forgottenPasswordStyles));

export default enhance(ForgottenPassword);
