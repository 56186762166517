import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

import sanitizeRestProps from './sanitizeRestProps';

const EmptyField = ({ children, className, ...rest }) => {
  return (
    <Typography component="span" variant="body1" className={className} {...sanitizeRestProps(rest)}>
      {React.Children.count(children) > 0 ? children : null}
    </Typography>
  );
};

EmptyField.propTypes = {
  addLabel: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
};

const PureEmptyField = pure(EmptyField);

PureEmptyField.defaultProps = {
  addLabel: true,
  source: '',
};

export default PureEmptyField;
