import PropTypes from 'prop-types';
import React from 'react';
import { AutocompleteInput, ReferenceNameInput } from '.';

const sort = { field: 'name', order: 'ASC' };
const filterToQuery = searchText => ({ name: searchText });

class TagAutocompleteInput extends React.PureComponent {
  /**
   * Parse an object into a string, to be compatible with model.
   * @param {array} value the AutocompleteInput value.
   * @returns {array} a value readable by model.
   */
  parse = value => (value ? value[this.props.optionValue] : null);

  render() {
    const { optionText, optionValue, type, ...rest } = this.props;
    const filter = type ? { '=type': type } : undefined;
    const targetFilter = { type };

    return (
      <ReferenceNameInput
        {...rest}
        allowEmpty
        filter={filter}
        filterToQuery={filterToQuery}
        parse={this.parse}
        sort={sort}
        target={optionValue}
        targetFilter={targetFilter}
      >
        <AutocompleteInput
          creatable
          isMulti={false}
          menuPortalZIndex={2}
          optionValue={optionValue}
          optionText={optionText}
        />
      </ReferenceNameInput>
    );
  }
}

TagAutocompleteInput.propTypes = {
  optionText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  reference: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  type: PropTypes.string,
};

TagAutocompleteInput.defaultProps = {
  reference: 'tag',
  optionText: 'name',
  optionValue: 'name',
};

export default TagAutocompleteInput;
