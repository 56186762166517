import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, DateField, List, Responsive, SimpleList, TextField } from 'react-admin';
import { DefaultPagination, defaultPerPage } from '../../framework';

const sort = { field: 'type', order: 'ASC' };
const getValue = attr => record => record[attr];

const MailList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sort}
  >
    <Responsive
      small={<SimpleList primaryText={getValue('type')} secondaryText={getValue('subject')} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <TextField source="type" />
          <TextField source="subject" />
          <DateField source="updated_at" showTime />
        </Datagrid>
      }
    />
  </List>
);

MailList.propTypes = {
  hasEdit: PropTypes.bool,
  permissions: PropTypes.object,
  options: PropTypes.object,
};

export default MailList;
