import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { REDIRECT_AFTER_EDIT } from '../../../settings';
import { professionalPosition } from '../../../validators';
import { CustomEditActions, PageTitle, Toolbar } from '../../framework';

export const ProfessionalPositionEdit = props => (
  <Edit
    actions={<CustomEditActions />}
    title={<PageTitle {...props} />}
    undoable={false}
    {...props}
  >
    <SimpleForm redirect={REDIRECT_AFTER_EDIT} toolbar={<Toolbar />}>
      <TextInput source="name" validate={professionalPosition.name} />
    </SimpleForm>
  </Edit>
);
