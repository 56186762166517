import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { crudDelete, startUndoable, Button } from 'react-admin';
import { crudDelete as customCrudDelete } from '../../../actions/crudActions';
import { ConfirmDelete } from '../layout';

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

class DeleteConfirmButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /**
   * Open confirmation dialog.
   * @param {Event} event the event generated byt the click action.
   */
  handleClickDelete = event => {
    event.stopPropagation();
    this.setState({ open: true });
  };

  /**
   * Close confirmation dialog when user clicks on Cancel or press `esc` key.
   * @param {Event} event the event generated byt the click action.
   */
  handleClickCancel = event => {
    event.stopPropagation();
    this.setState({ open: false });
  };

  /**
   * Run delete action when user clicks on Ok or press `enter` key.
   * @param {Event} event the event generated byt the click action.
   */
  handleClickConfirm = event => {
    event.preventDefault();
    this.setState({ open: false });
    this.executeDelete();
  };

  /**
   * Execute delete action.
   */
  executeDelete = () => {
    const {
      basePath,
      dispatchCrudDelete,
      onDelete,
      record,
      redirect,
      resource,
      startUndoable,
      undoable,
    } = this.props;

    if (onDelete) {
      onDelete(record);
    } else {
      if (undoable) {
        startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
      } else {
        dispatchCrudDelete(resource, record.id, record, basePath, redirect, true); // force refresh
      }
    }
  };

  render() {
    const {
      label = 'ra.action.delete',
      classes = {},
      className,
      confirmationMessage,
      confirmationTitle,
      disabled,
      icon,
    } = this.props;

    return (
      <React.Fragment>
        <Button
          onClick={this.handleClickDelete}
          label={label}
          className={classnames('ra-delete-button', classes.deleteButton, className)}
          key="button"
          disabled={disabled}
        >
          {icon}
        </Button>
        <ConfirmDelete
          content={confirmationMessage}
          onCancel={this.handleClickCancel}
          onConfirm={this.handleClickConfirm}
          open={this.state.open}
          title={confirmationTitle}
        />
      </React.Fragment>
    );
  }
}

DeleteConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmationMessage: PropTypes.string,
  confirmationTitle: PropTypes.string,
  disabled: PropTypes.bool,
  dispatchCrudDelete: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string,
  onDelete: PropTypes.func,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  startUndoable: PropTypes.func,
  undoable: PropTypes.bool,
};

DeleteConfirmButton.defaultProps = {
  confirmationMessage: 'ra.message.delete_content',
  disabled: false,
  icon: <ActionDelete />,
  redirect: 'list',
  undoable: false,
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudDelete: customCrudDelete }
  ),
  withStyles(styles)
)(DeleteConfirmButton);
