import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { addField, crudGetList as crudGetListAction, translate } from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';
import { Permission } from '../../../models/permission';
import { sequenceEquals } from '../../../utils/tools';
import { PER_PAGE_REFERENCES } from '../../../settings';

const emptyRenderData = Object.freeze({ data: {}, actions: [], resources: [] });
const CUSTOM_ACTION = 'custom';
const actionPermissionLabel = [CUSTOM_ACTION];

class PermissionDatagrid extends React.Component {
  constructor(props) {
    super(props);
    this.renderData = cloneDeep(emptyRenderData);
  }

  componentDidMount() {
    this.loadPermissions();
  }

  /**
   * Load all permissions to display them into form.
   */
  loadPermissions = () => {
    const { crudGetList } = this.props;

    crudGetList(
      'permission',
      { perPage: PER_PAGE_REFERENCES },
      { field: 'name', order: 'ASC' },
      {}
    );
  };

  componentDidUpdate(prevProps) {
    const { permissions } = this.props;

    if (
      Array.isArray(permissions.ids) &&
      permissions.ids.length > 0 &&
      !sequenceEquals(permissions.ids, prevProps.ids)
    ) {
      this.renderData = this.buildData();
    }
  }

  handleBlur = eventOrValue => {
    this.props.onBlur(this.getRecordPermissionList());
    this.props.input.onBlur(this.getRecordPermissionList());
  };

  handleFocus = event => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleChange = eventOrValue => {
    this.props.onChange(eventOrValue);
    this.props.input.onChange(eventOrValue);
  };

  /**
   * Get a list of all permissions enabled in form record.
   * @returns {array}
   */
  getRecordPermissionList = () =>
    this.props.input && Array.isArray(this.props.input.value) ? this.props.input.value : [];

  /**
   * Get a list of all permissions available in state.
   * @returns {array}
   */
  getStatePermissionList = () =>
    this.props.permissions.ids.map(id => this.props.permissions.data[id]);

  /**
   * Filter a list of permissions by resource or method.
   * @param {array} list the list to filter.
   * @param {string} resource a resource filter.
   * @param {string} method a method filter.
   * @returns {array} a filtered list of permissions.
   */
  filterPermissions = (list, resource = null, method = null) => {
    let newList = list.slice();
    const { permissionNameSeparator } = this.props;

    if (resource) {
      newList = newList.filter(perm => perm.name.startsWith(resource + permissionNameSeparator));
    }

    if (method) {
      newList = newList.filter(perm =>
        method === CUSTOM_ACTION
          ? !Permission.ACTIONS.some(action => perm.name.endsWith(permissionNameSeparator + action))
          : perm.name.endsWith(permissionNameSeparator + method)
      );
    }

    return newList;
  };

  /**
   * Enable or disable a permission.
   * @param {object} permission the permission.
   * @param {Event} event the event generated by user action.
   * @param {boolean} checked is the permission enabled?
   */
  togglePermission = permission => (event, checked) => {
    if (event) {
      event.stopPropagation();
    }

    const permissionsList = this.getRecordPermissionList().slice();
    const index = permissionsList.findIndex(p => p.id === permission.id);

    if (index > -1 && !checked) {
      // remove from permissions list
      permissionsList.splice(index, 1);
    } else if (index < 0 && checked) {
      // add to permissions list
      permissionsList.push(permission);
    }

    this.handleChange(permissionsList);
  };

  /**
   * Check all actions for all resources.
   * @param {Event} event the event generated by user action.
   * @param {boolean} checked enabled or disable all permissions.
   */
  toggleAll = (event, checked) => {
    if (event) {
      event.stopPropagation();
    }

    if (checked) {
      this.handleChange(this.getStatePermissionList());
    } else {
      this.handleChange([]);
    }
  };

  /**
   * Toggle an action for an action.
   * @param {string} action the selected action.
   */
  toggleAllActions = action => (event, checked) => {
    return this.toggleAllResourceOrAction(event, checked, null, action);
  };

  /**
   * Toggle all actions for a resource.
   * @param {string} resource the selected resource.
   */
  toggleAllResources = resource => (event, checked) => {
    return this.toggleAllResourceOrAction(event, checked, resource);
  };

  /**
   * Toggle all actions for a resource or an action.
   * @param {Event} event the event generated by user action.
   * @param {boolean} checked enabled or disable all permissions.
   * @param {string} resource the selected resource.
   * @param {string} method the selected action.
   */
  toggleAllResourceOrAction = (event, checked, resource = null, method = null) => {
    if (event) {
      event.stopPropagation();
    }

    let recordPermissions = this.getRecordPermissionList().slice();
    const recordPermissionsIds = recordPermissions.map(perm => perm.id);

    const actionPermissions = this.filterPermissions(
      this.getStatePermissionList(),
      resource,
      method
    );
    const actionPermissionsIds = actionPermissions.map(perm => perm.id);

    if (checked) {
      const idsToAdd = difference(actionPermissionsIds, recordPermissionsIds);
      actionPermissions.forEach(perm => {
        if (idsToAdd.includes(perm.id)) {
          recordPermissions.push(perm);
        }
      });
    } else {
      const idsToPreserve = difference(recordPermissionsIds, actionPermissionsIds);
      recordPermissions = recordPermissions.filter(perm => idsToPreserve.includes(perm.id));
    }

    this.handleChange(recordPermissions);
  };

  /**
   * Prepare data for rendering.
   */
  buildData = () => {
    const renderData = cloneDeep(emptyRenderData);

    const {
      permissions: { ids, data },
      permissionNameSeparator,
    } = this.props;

    ids.forEach(id => {
      const permission = data[id];
      let [resource, method] = permission.name.split(permissionNameSeparator);

      if (!(resource in renderData.data)) {
        renderData.resources.push(resource);
        renderData.data[resource] = {};
      }

      if (!Permission.ACTIONS.includes(method)) {
        method = CUSTOM_ACTION;
      }

      if (!renderData.actions.includes(method)) {
        renderData.actions.push(method);
      }

      if (!Array.isArray(renderData.data[resource][method])) {
        renderData.data[resource][method] = [];
      }

      renderData.data[resource][method].push(permission);
    });

    renderData.actions = Permission.sortActions(renderData.actions);
    return renderData;
  };

  /**
   * Render a header cell.
   * @param {string} header the header name.
   * @param {number} i index of the header.
   * @returns {ReactElement} the header cell.
   */
  renderHeaderCell = (header, i) => {
    const { translate } = this.props;
    const formPermissions = this.filterPermissions(this.getRecordPermissionList(), null, header);
    const statePermissions = this.filterPermissions(this.getStatePermissionList(), null, header);

    const checked = formPermissions.length > 0;
    const indeterminate =
      formPermissions.length > 0 && formPermissions.length < statePermissions.length;

    return (
      <TableCell key={i}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              onBlur={this.handleBlur}
              onChange={this.toggleAllActions(header)}
              onFocus={this.handleFocus}
            />
          }
          label={translate(`form.permission.action.${header}`, {
            smart_count: 1,
            _: `form.permission.action.${header}`,
          })}
        />
      </TableCell>
    );
  };

  renderHeaderCellAll = () => {
    const nbPermissions = this.getRecordPermissionList().length;
    const checked = nbPermissions > 0;
    const indeterminate = nbPermissions > 0 && nbPermissions < this.props.permissions.total;

    return (
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              onBlur={this.handleBlur}
              onChange={this.toggleAll}
              onFocus={this.handleFocus}
            />
          }
          label=""
        />
      </TableCell>
    );
  };

  renderResourceCell = resource => {
    const { translate } = this.props;
    const formPermissions = this.filterPermissions(this.getRecordPermissionList(), resource);
    const statePermissions = this.filterPermissions(this.getStatePermissionList(), resource);

    const checked = formPermissions.length > 0;
    const indeterminate =
      formPermissions.length > 0 && formPermissions.length < statePermissions.length;

    return (
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              onBlur={this.handleBlur}
              onChange={this.toggleAllResources(resource)}
              onFocus={this.handleFocus}
            />
          }
          label={translate(`resources.${resource}.name`, {
            _: `resources.${resource}.name`,
            smart_count: 2,
          })}
        />
      </TableCell>
    );
  };

  /**
   * Render a permission row.
   * @param {string} resource the resource name.
   * @param {number} i index of the row.
   * @returns {ReactElement} the row.
   */
  renderRow = (resource, i) => (
    <TableRow key={i}>
      {this.renderResourceCell(resource)}
      {this.renderData.actions.map(this.renderBodyCell(resource))}
    </TableRow>
  );

  /**
   * Render a permission cell.
   * @param {string} resource the resource name.
   * @param {string} action the action name.
   * @param {number} i index of the cell.
   * @returns {ReactElement} the cell.
   */
  renderBodyCell = resource => (action, i) => {
    const permissions = this.renderData.data[resource][action] || [];
    const enabled = this.getRecordPermissionList().map(p => p.id);
    const { permissionNameSeparator, translate } = this.props;

    /**
     * Get the permission label.
     * @param {object} permission the permission.
     * @returns {string|undefined}
     */
    const getLabel = permission => {
      if (actionPermissionLabel.includes(action)) {
        const token = `form.permission.action.${
          permission.name.includes(permissionNameSeparator)
            ? permission.name.split(permissionNameSeparator)[1]
            : permission.name
        }`;

        return translate(token, { smart_count: 1, _: token });
      }

      return undefined;
    };

    return (
      <TableCell key={i}>
        {permissions.map(permission => (
          <div key={permission.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enabled.includes(permission.id)}
                  onBlur={this.handleBlur}
                  onChange={this.togglePermission(permission)}
                  onFocus={this.handleFocus}
                />
              }
              label={getLabel(permission)}
            />
          </div>
        ))}
      </TableCell>
    );
  };

  render() {
    const {
      permissions: { total },
      isLoading,
    } = this.props;

    if (total === 0) {
      if (isLoading) {
        return <LinearProgress />;
      } else {
        return null;
      }
    }

    const { actions, resources } = this.renderData;

    return (
      <Table>
        <TableHead>
          <TableRow>
            {this.renderHeaderCellAll()}
            {actions.map(this.renderHeaderCell)}
          </TableRow>
        </TableHead>
        <TableBody>{resources.map(this.renderRow)}</TableBody>
      </Table>
    );
  }
}

PermissionDatagrid.propTypes = {
  crudGetList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  permissionNameSeparator: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    data: PropTypes.object,
    ids: PropTypes.array,
    total: PropTypes.number,
  }),
  resource: PropTypes.string,
  source: PropTypes.string,
  version: PropTypes.number,
};

PermissionDatagrid.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  permissionNameSeparator: '-',
  source: 'permissions',
};

/**
 * Inject state data into component properties.
 * @param {object} state the state.
 * @param {object} props the component properties.
 */
function mapStateToProps(state, props) {
  const resourceState = state.admin.resources.permission;
  return {
    permissions: {
      data: resourceState.data,
      ids: resourceState.list.ids,
      total: resourceState.list.total,
    },
    isLoading: state.admin.loading > 0,
    version: state.admin.ui.viewVersion,
  };
}

const enhance = compose(
  addField,
  translate,
  connect(
    mapStateToProps,
    { crudGetList: crudGetListAction }
  )
);

export default enhance(PermissionDatagrid);
