import React from 'react';
import { Login } from 'react-admin';
import PropTypes from 'prop-types';
import { LOGIN_BACKGROUND_IMAGE } from '../../settings';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = props => (
  <Login
    {...props}
    backgroundImage={LOGIN_BACKGROUND_IMAGE}
    className="login-background"
    loginForm={<ResetPasswordForm match={props.match} />}
  />
);

ResetPassword.propTypes = {
  match: PropTypes.object,
};

export default ResetPassword;
