const OBJECT_STATE_ID = Object.freeze({
  WAITING: 0,
  PAID: 1,
  UNPAID: 2,
});

export class Invoice {
  static INVOICE_STATE_ID = Object.freeze({
    values: [
      {
        id: OBJECT_STATE_ID.WAITING,
        name: 'form.invoice.state.waiting',
        color: 'misty',
      },
      {
        id: OBJECT_STATE_ID.PAID,
        name: 'form.invoice.state.paid',
        color: 'lime',
      },
      {
        id: OBJECT_STATE_ID.UNPAID,
        name: 'form.invoice.state.unpaid',
        color: 'red',
      },
    ],
    default: 0,
  });
}
