export default {
  invalid: 'Invalide',
  greaterThan: 'Doit être supérieur à %{min}',
  zipcode: 'Doit être un code postal à 5 chiffres',
  mustContain: 'Doit contenir %{token}',
  list: 'Doit être une liste',
  boolean: 'Doit être un choix de type vrai/faux',
  json: 'Doit être un objet',
  date: 'Doit être une date',
  datetime: 'Doit être une date avec une heure',
  singleIsMain: 'Un seul interlocuteur principal',
  emailOrListEmail: 'Doit être un email ou une liste d\'emails séparés par "%{separators}"',
  passwords_not_equal: 'Les mots de passes doivent être identiques',
};
