import PropTypes from 'prop-types';
import React from 'react';
import { crudGetList as crudGetListAction, Datagrid, FormTab, TextField } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { executeMethodPost } from '../../../../actions/methodActions';
import { PER_PAGE_REFERENCES } from '../../../../settings';
import UserEditRoleMembershipButton from './UserEditRoleMembershipButton';

const sanitizeRestProps = ({ crudGetList, executeMethodPost, roles, ...rest }) => rest;
const currentSort = { field: 'name', order: 'ASC' };

/**
 * User roles management.
 */
class UserEditRoleTab extends React.Component {
  componentDidMount() {
    this.loadRoles();
  }

  /**
   * Fetch roles from server.
   */
  loadRoles = () => {
    this.props.crudGetList('role', { perPage: PER_PAGE_REFERENCES }, currentSort, {});
  };

  /**
   * Add or remove a role for a user.
   * @param {string|number} roleId the ID of the role to add/remove.
   */
  toggleRole = roleId => {
    const { basePath, executeMethodPost, record } = this.props;
    const member = record && record.roles.find(role => role.id === roleId);

    executeMethodPost(
      member ? 'removeRole' : 'addRole',
      'user',
      record.id,
      { role_id: roleId },
      record,
      basePath,
      false,
      {
        refresh: true,
        notification_success: `notification.user_role.${member ? 'removed' : 'added'}_success`,
      }
    );
  };

  render() {
    const {
      permissions,
      record,
      roles: { data, ids },
    } = this.props;

    const roles = Object.assign({}, data);
    ids.forEach(id => {
      roles[id].is_member =
        record &&
        Array.isArray(record.roles) &&
        record.roles.some(recordRole => recordRole.id === id);
    });

    return (
      <FormTab {...sanitizeRestProps(this.props)}>
        <Datagrid data={roles} ids={ids} currentSort={currentSort}>
          <TextField basePath="/role" resource="role" source="name" />
          <UserEditRoleMembershipButton
            canAdd={permissions.can('user', 'add_role')}
            canRemove={permissions.can('user', 'remove_role')}
            handleChange={this.toggleRole}
            label="form.user.role.is_member"
            record={record}
            sortable={false}
            source="is_member"
          />
        </Datagrid>
      </FormTab>
    );
  }
}

UserEditRoleTab.propTypes = {
  crudGetList: PropTypes.func.isRequired,
  executeMethodPost: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  record: PropTypes.object,
  roles: PropTypes.shape({
    data: PropTypes.object,
    ids: PropTypes.array,
    total: PropTypes.number,
  }),
};

UserEditRoleTab.defaultProps = {
  roles: {},
};

function mapStateToProps(state, props) {
  const stateRoles = state.admin.resources.role;
  const {
    data,
    list: { ids, total },
  } = stateRoles;

  return {
    roles: { data, ids, total },
  };
}

const enhance = compose(
  connect(mapStateToProps, { executeMethodPost, crudGetList: crudGetListAction })
);

export default enhance(UserEditRoleTab);
