import lodashMemoize from 'lodash/memoize';
import { isEmpty, isNotEmpty } from '../utils/tools';

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
export const memoize = fn => lodashMemoize(fn, (...args) => JSON.stringify(args));
const DATE_REGEX = /^\d{4}-\d{2}-\d{2}( 00:00:00)?$/;
const DATETIME_REGEX = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

/**
 * Check if a value is empty in a form.
 * @param {string} value the value to check.
 * @param {object} allValues the form values.
 * @param {object} props the form properties. Used to get translate method.
 * @see tools.isEmpty
 * @see https://github.com/marmelab/react-admin/blob/v2.2.0/packages/ra-core/src/form/validate.js
 */
export const notEmpty = memoize((message = 'ra.validation.required') =>
  Object.assign((value, values, props) => (isEmpty(value) ? props.translate(message) : undefined), {
    isRequired: true,
  })
);

/**
 * Check if a value is strictly superior to a minimum value.
 * @param {string} value the value to check.
 * @param {object} allValues the form values.
 * @param {object} props the form properties. Used to get translate method.
 * @see tools.isEmpty
 * @see https://github.com/marmelab/react-admin/blob/v2.2.0/packages/ra-core/src/form/validate.js
 */
export const greaterThan = memoize(
  (min, message = 'validation.greaterThan') =>
    (value, values, props) =>
      !isEmpty(value) && Number(value) <= Number(min)
        ? props.translate(message, { _: message, min })
        : undefined
);

/**
 * Check if a value is inferior or equals to a maximum value.
 * @param {string} value the value to check.
 * @param {object} allValues the form values.
 * @param {object} props the form properties. Used to get translate method.
 * @see tools.isEmpty
 */
export const lte = memoize((min, message = 'ra.validation.maxValue') => (value, values, props) => {
  const compareValue = typeof min === 'string' ? values[min] : min;

  return !isEmpty(value) && Number(value) > Number(compareValue)
    ? props.translate(message, { _: message, max: compareValue })
    : undefined;
});

/**
 * Set an attribute required if no other attributes are valid.
 * @param {srting|Array} attrs the other exclusives attributes.
 * @param {string} message the error message.
 */
export const requiredIfNot = memoize(
  (attrs, message = 'ra.validation.required') =>
    (value, values, props) => {
      if (typeof attrs === 'string') {
        attrs = [attrs];
      }

      if (!notEmpty()(value, values, props)) {
        return undefined; // current value is set, do not check others
      }

      // if all others attributes are empty, raise error.
      if (attrs.every(attr => notEmpty()(values[attr], values, props))) {
        return props.translate(message, { _: message });
      }
    }
);

/**
 * Set an attribute required if another attribute is set.
 * @param {srting|Array} attrs the other attribute to check.
 * @param {string} message the error message.
 */
export const requiredIf = memoize(
  (attrs, message = 'ra.validation.required') =>
    (value, values, props) => {
      if (typeof attrs === 'string') {
        attrs = [attrs];
      }

      if (isNotEmpty(value)) {
        return undefined; // current value is set, do not check others
      }

      // if any other attribute is empty, raise error.
      if (attrs.some(attr => isNotEmpty(values[attr]))) {
        return props.translate(message, { _: message });
      }
    }
);

/**
 * If country is France, check that zipcode is exactly 5 digits
 */
export const zipcode = memoize(
  (countryAttribute, message = 'validation.zipcode') =>
    (value, values, props) => {
      const val = (value || '').trim();
      const regex = /^\d{5}$/gi;
      return ['FR', 'FRA'].includes(values[countryAttribute]) && val && !val.match(regex)
        ? props.translate(message, { _: message })
        : undefined;
    }
);

/**
 * Check that value is a repeated value.
 */
export const list = memoize((message = 'validation.list') => (value, values, props) => {
  return !isEmpty(value) && !Array.isArray(value)
    ? props.translate(message, { _: message })
    : undefined;
});

/**
 * Check value is a boolean value.
 */
export const boolean = memoize((message = 'validation.boolean') => (value, values, props) => {
  if (isEmpty(value) || typeof value === 'boolean' || value === 0 || value === 1) {
    return undefined;
  }

  return props.translate(message, { _: message });
});

/**
 * Check if value is a date.
 */
export const date = memoize(
  (message = 'validation.date') =>
    (value, values, props) =>
      // untouched date of existing record contains date
      isNotEmpty(value) && !(typeof value === 'string' && value.match(DATE_REGEX))
        ? props.translate(message, { _: message })
        : undefined
);

/**
 * Check if value is a datetime.
 */
export const datetime = memoize((message = 'validation.datetime') => (value, values, props) => {
  if (isNotEmpty(value)) {
    if (!(value instanceof Date || (typeof value === 'string' && value.match(DATETIME_REGEX)))) {
      return props.translate(message, { _: message });
    }
  }

  return undefined;
});
