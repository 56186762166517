import action from './action';
import auth from './auth';
import error from './error';
import form from './form';
import input from './input';
import message from './message';
import notification from './notification';
import page from './page';
import resources from './resources';
import validation from './validation';


export default {
  resources,
  error,
  form,
  notification,
  message,
  action,
  validation,
  page,
  input,
  auth,
  'Failed to fetch': 'Application temporairement indisponible',
};
