import React from 'react';
import PropTypes from 'prop-types';
import Confirm from './Confirm';

/**
 * Display delete confirmation.
 * @see Confirm
 * @param {*} props properties.
 */
const ConfirmDelete = props => <Confirm {...props} deleteAction />;

ConfirmDelete.propTypes = {
  cancel: PropTypes.string,
  content: PropTypes.node,
  confirm: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  translateButton: PropTypes.bool,
  translateContent: PropTypes.bool,
  translateTitle: PropTypes.bool,
};

ConfirmDelete.defaultProps = {
  confirm: 'ra.action.delete',
  content: 'ra.message.delete_content',
};

export default ConfirmDelete;
