import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, Create, NumberInput, ReferenceInput, SimpleForm } from 'react-admin';
import { Candidate } from '../../../models';
import { candidateOrderValidators } from '../../../validators';
import { CustomCreateActions, Toolbar, EmptyField } from '../../framework';

/**
 * Redirect to order after submit.
 * @param {string} basePath
 * @param {number} id
 * @param {object} data the form data.
 */
const CandidateOrderCreate = ({ permissions, ...props }) => {
  const { location } = props;
  const redirect = useCallback(
    (basePath, id, data) => {
      if (location && location.search && location.search.includes('candidate_id')) {
        return `/candidate/${data.candidate_id}/candidate-orders`;
      }

      return `/order/${data.order_id}/candidates`;
    },
    [location]
  );

  return (
    <Create {...props} actions={<CustomCreateActions />}>
      <SimpleForm redirect={redirect} toolbar={<Toolbar />}>
        {permissions && permissions.canList('candidate') ? (
          <ReferenceInput
            source="candidate_id"
            reference="candidate"
            validate={candidateOrderValidators.candidate_id}
          >
            <AutocompleteInput optionText={Candidate.getFullName} />
          </ReferenceInput>
        ) : (
          <EmptyField source="candidate_id" />
        )}
        <NumberInput source="order_id" validate={candidateOrderValidators.order_id} />
      </SimpleForm>
    </Create>
  );
};

CandidateOrderCreate.propTypes = {
  permissions: PropTypes.object,
};

export default CandidateOrderCreate;
