import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { translate } from 'react-admin';

import sanitizeRestProps from './sanitizeRestProps';

const styles = createStyles({
  label: {
    // Move the text out of the flow of the container.
    position: 'absolute',

    // Reduce its height and width to just one pixel.
    height: 1,
    width: 1,

    // Hide any overflowing elements or text.
    overflow: 'hidden',

    // Clip the box to zero pixels.
    clip: 'rect(0, 0, 0, 0)',

    // Text won't wrap to a second line.
    whiteSpace: 'nowrap',
  },
});

/**
 * Same as BooleanField from react-admin, but is compliant if value is not a boolean.
 * @param {bool} reversed set `true` to consider truthy value as false, and falsy as true.
 */
const BooleanField = ({
  className,
  classes,
  source,
  record = {},
  translate,
  valueLabelTrue,
  valueLabelFalse,
  reversed,
  type,
  ...rest
}) => {
  let value = Boolean(get(record, source));
  let ariaLabel = value ? valueLabelTrue : valueLabelFalse;

  if (!ariaLabel) {
    ariaLabel = value === false ? 'ra.boolean.false' : 'ra.boolean.true';
  }

  if (reversed) {
    value = !value;
  }

  if (type === 'icon') {
    return (
      <Typography
        component="span"
        variant="body1"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        <span className={classes.label}>{translate(ariaLabel, { _: ariaLabel })}</span>
        {value ? <TrueIcon data-testid="true" /> : <FalseIcon data-testid="false" />}
      </Typography>
    );
  } else {
    return (
      <Typography
        component="span"
        variant="body1"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {translate(ariaLabel, { _: ariaLabel })}
      </Typography>
    );
  }
};

BooleanField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  reversed: PropTypes.bool,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['icon', 'text']).isRequired,
  valueLabelTrue: PropTypes.string,
  valueLabelFalse: PropTypes.string,
};

BooleanField.defaultProps = {
  classes: {},
  reversed: false,
  type: 'text',
};

const enhance = compose(
  pure,
  withStyles(styles),
  translate
);

const PureBooleanField = enhance(BooleanField);

PureBooleanField.defaultProps = {
  addLabel: true,
};

export default PureBooleanField;
