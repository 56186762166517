import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  Responsive,
  SimpleList,
  translate,
  TextField,
} from 'react-admin';
import { Candidate, CandidateOrder } from '../../../models';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  defaultPerPage,
  DeleteConfirmButton,
} from '../../framework';
import { ObjectStateSelect } from '../../ObjectState';
import CandidateOrderFitler from './CandidateOrderFilter';

const sort = { field: 'created_at', order: 'desc' };
const bulkActions = <BulkDeleteConfirmButton />;

const CandidateOrderList = ({ permissions, translate, ...props }) => (
  <List
    bulkActionButtons={props.options.hasDelete ? bulkActions : false}
    filters={<CandidateOrderFitler permissions={permissions} />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sort}
    {...props}
    hasCreate={false}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={record =>
            permissions &&
            permissions.canList('candidate') && (
              <ReferenceField
                basePath={props.basePath}
                label="resources.candidate.fields.first_name"
                linkType={false}
                record={record}
                reference="candidate"
                source="candidate_id"
              >
                <FunctionField render={Candidate.getFullName} />
              </ReferenceField>
            )
          }
          secondaryText={record =>
            permissions &&
            permissions.canList('order') && (
              <ReferenceField
                basePath={props.basePath}
                label="resources.order.fields.job_title"
                linkType={false}
                record={record}
                reference="order"
                source="order_id"
              >
                <TextField source="job_title" />
              </ReferenceField>
            )
          }
          tertiaryText={record =>
            permissions &&
            permissions.canList('order') &&
            permissions &&
            permissions.canList('customer') && (
              <ReferenceField
                basePath={props.basePath}
                label={translate('resources.customer.fields.business_name', 1)}
                linkType={false}
                record={record}
                reference="order"
                source="order_id"
              >
                <ReferenceField
                  addLabel={false}
                  linkType={false}
                  reference="customer"
                  source="customer_id"
                >
                  <TextField source="business_name" />
                </ReferenceField>
              </ReferenceField>
            )
          }
        />
      }
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <ObjectStateSelect
            source="object_state_id"
            choices={CandidateOrder.OBJECT_STATE_ID.values}
            readOnly
          />
          {permissions && permissions.canList('candidate') && (
            <ReferenceField
              label="resources.candidate.fields.last_name"
              linkType={false}
              reference="candidate"
              source="candidate_id"
            >
              <TextField source="last_name" />
            </ReferenceField>
          )}
          {permissions && permissions.canList('candidate') && (
            <ReferenceField
              label="resources.candidate.fields.first_name"
              linkType={false}
              reference="candidate"
              source="candidate_id"
            >
              <TextField source="first_name" />
            </ReferenceField>
          )}
          <NumberField source="order_id" />
          {permissions && permissions.canList('order') && (
            <ReferenceField
              label="resources.order.fields.job_title"
              linkType={false}
              reference="order"
              sortable={false}
              source="order_id"
            >
              <TextField source="job_title" />
            </ReferenceField>
          )}
          {permissions && permissions.canList('order') && permissions.canList('customer') && (
            <ReferenceField
              label={translate('resources.customer.fields.business_name', 1)}
              linkType={false}
              reference="order"
              sortable={false}
              source="order_id"
            >
              <ReferenceField
                addLabel={false}
                linkType={false}
                reference="customer"
                source="customer_id"
              >
                <TextField source="business_name" />
              </ReferenceField>
            </ReferenceField>
          )}
          <DateField showTime source="real_meeting_date" />
          <DateField source="customer_file_sent_date" />
          <DateField source="customer_interview_date" />
          <DateField source="customer_second_interview_date" />
          <DateField showTime source="coaching_meeting_date" />
          <DateField source="job_start_date" />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

CandidateOrderList.propTypes = {
  basePath: PropTypes.string,
  hasEdit: PropTypes.bool,
  options: PropTypes.object,
  permissions: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

export default translate(CandidateOrderList);
