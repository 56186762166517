import { resolveBrowserLocale } from 'react-admin';
import { APP_VERSION, REFRESH_APP_VERSION_THROTTLE } from '../settings';

/**
 * Handle all session parameters.
 */
export class Session {
  /**
   * Initialize session.
   */
  static init() {
    Session.addRefreshThrottleEvent();
    Session.checkAppVersion();
  }

  /**
   * Clear session parameters.
   * This method doesn't clear parameters set in sessionStorage (except app version).
   */
  static close() {
    sessionStorage.removeItem('version');
    localStorage.clear();
  }

  /**
   * Clear all session parameters, including parameters in sessionStorage.
   */
  static destroy() {
    this.close();
    sessionStorage.clear();
  }

  /**
   * Set app version in session.
   * @param {string} version the new app version.
   */
  static setAppVersion(version) {
    localStorage.setItem('version', version);
    sessionStorage.setItem('version', version);
  }

  /**
   * Get app version stored in session.
   * Caution: can be different of app version set in app settings.
   * @returns {string}
   */
  static getAppVersion() {
    return sessionStorage.getItem('version') || localStorage.getItem('version');
  }

  /**
   * Check if current app version is the latest.
   * Load latest app version from server, and refresh if needed.
   * @param {boolean} periodicCheck set `true` if called from periodic check, `false` if this method is called at session start.
   *    Used for throttle check.
   */
  static checkAppVersion(periodicCheck = false) {
    const request = new Request('/version.txt', { method: 'GET' });
    fetch(request)
      .then(response => response.text())
      .then(body => {
        const newVersion = body.trim();

        if (periodicCheck) {
          Session.resetRefreshThrottle();
        }

        if (newVersion !== APP_VERSION && !Session.isRefreshThrottling(newVersion)) {
          Session.addRefreshThrottleEvent();
          window.location.reload();
        }
      });
  }

  /**
   * Remember that app was refreshed.
   * @see checkAppVersion
   */
  static addRefreshThrottleEvent() {
    sessionStorage.setItem('lastRefresh', new Date().getTime() / 1000);
  }

  /**
   * Check when was the last page refresh, and determine if previous auto-refresh was too closed.
   * This is to avoid infinite auto-refresh.
   * @param {string} newVersion the new app version fetched.
   * @param {number} delay the delay (in seconds) to wait before two app auto-refresh.
   * @return {boolean} `true` if throttle limit has been reached, and refresh should be delayed/reported. Else `false`.
   * @see checkAppVersion
   */
  static isRefreshThrottling(newVersion, delay = REFRESH_APP_VERSION_THROTTLE) {
    const lastRefresh = JSON.parse(sessionStorage.getItem('lastRefresh'));
    const now = new Date().getTime() / 1000; // get time in seconds and not in milliseconds

    if (lastRefresh && now - lastRefresh <= delay) {
      console.warn(
        'Throttle auto-refresh, current version is',
        APP_VERSION,
        'and new version is',
        newVersion
      );
      return true;
    }

    return false;
  }

  /**
   * Reset refresh throttler.
   * @see checkAppVersion
   */
  static resetRefreshThrottle() {
    sessionStorage.removeItem('lastRefresh');
  }

  /**
   * Check if current app is outdated.
   * If so, app should be reloaded.
   * @returns {string}
   * @see checkAppVersion
   */
  static isAppOutdated() {
    const version = Session.getAppVersion();
    return version && APP_VERSION && version !== APP_VERSION;
  }

  /**
   * Remove authentication token from session.
   */
  static clearToken() {
    localStorage.removeItem('token');
  }

  /**
   * Set authentication token in session.
   * @param {Object} token the structure containing the access token.
   */
  static setToken(token) {
    token.expires_at = new Date().getTime() / 1000 + token.expires_in;
    localStorage.setItem('token', JSON.stringify(token));
  }

  /**
   * Get access token.
   * @param {boolean} full set `true` to get full token structure, `false` to get only token value. Default `false`.
   * @returns {string|object} the access token, if any.
   */
  static getAccessToken(full = false) {
    const token = JSON.parse(localStorage.getItem('token'));
    return token ? (full ? token : token.access_token) : null;
  }

  /**
   * Check if access token is valid.
   * Token is invalid if not set or expired.
   * @returns {boolean}
   */
  static isAccessTokenValid() {
    const token = this.getAccessToken(true);
    return token && token.expires_at && token.expires_at >= new Date().getTime() / 1000;
  }

  /**
   * Clear user profile from session.
   */
  static clearUser() {
    localStorage.removeItem('user');
  }

  /**
   * Set a user in current session.
   * @param {Object} user the user to set.
   */
  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * Get the user profile from the current session.
   * @returns {Object} the user profile.
   */
  static getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * Get langs available for the application.
   */
  static getLangs() {
    return ['fr', 'en'];
  }

  /**
   * Get default locale (browser locale).
   * @returns {string} the locale
   */
  static getDefaultLocale() {
    return resolveBrowserLocale();
  }

  /**
   * Get the current locale.
   * If user never chose a locale, the default is returned.
   * @returns {string} the locale
   */
  static getCurrentLocale() {
    const locale = sessionStorage.getItem('locale');

    if (locale) {
      return locale;
    }

    return this.getDefaultLocale();
  }

  /**
   * Update the current locale and store it in current session.
   * @param {string} locale the new locale.
   */
  static setCurrentLocale(locale) {
    sessionStorage.setItem('locale', locale);
  }

  /**
   * Clear user permissions from the current session.
   */
  static clearPermissions() {
    localStorage.removeItem('permissions');
  }

  /**
   * Set user permissions in the current session.
   * @param {Object} permissions the permissions list.
   */
  static setPermissions(permissions) {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  /**
   * Get the current permissions from the current session.
   * @returns {Object} the permissions list.
   */
  static getPermissions() {
    return JSON.parse(localStorage.getItem('permissions')) || [];
  }

  /**
   * Redirect user to the login form.
   * @param {string} redirectUrl the url to redirect user to.
   */
  static redirectToLogin(redirectUrl = '#/login') {
    window.location.replace(redirectUrl);
  }
}
