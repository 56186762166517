import DownloadIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import { download } from '../../../actions/downloadActions';

class ReportingDownloadButton extends React.PureComponent {
  /**
   * Launch download action on click.
   */
  onClick = event => {
    event.stopPropagation();

    const { basePath, download, record, resource } = this.props;
    download('download', resource, record.id, undefined, basePath, false);
  };

  render() {
    const { icon, label } = this.props;

    return (
      <Button label={label} onClick={this.onClick}>
        {icon}
      </Button>
    );
  }
}

ReportingDownloadButton.propTypes = {
  download: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
};

ReportingDownloadButton.defaultProps = {
  icon: <DownloadIcon />,
  label: 'action.download',
};

export default connect(
  undefined,
  { download }
)(ReportingDownloadButton);
