import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Responsive,
  SimpleList,
  TextField,
} from 'react-admin';
import { Order } from '../../../models';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  defaultPerPage,
  DeleteConfirmButton,
} from '../../framework';
import { ObjectStateSelect } from '../../ObjectState';
import OrderExportBulkButton from './OrderExportBulkButton';
import OrderFilter from './OrderFilter';

const sort = { field: 'quotation_number', order: 'ASC' };
const getValue = attr => record => record[attr];

const OrderListBulkActions = ({ hasDelete, permissions, ...props }) => {
  const { resource } = props;

  return (
    <React.Fragment>
      {permissions.can(resource, 'download') && <OrderExportBulkButton {...props} />}
      {hasDelete && <BulkDeleteConfirmButton {...props} />}
    </React.Fragment>
  );
};
OrderListBulkActions.propTypes = {
  hasDelete: PropTypes.bool,
  permissions: PropTypes.object,
  resource: PropTypes.string,
};

/**
 * Mobile view.
 * A warning may appear in console.
 * ```
 * Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>
 * ``
 * This is because of the loader of the ReferenceField. While record is loading, a `<LinearProgress>` is displayed.
 */
export const MobileOrderList = ({ permissions, ...props }) => (
  <SimpleList
    {...props}
    primaryText={getValue('job_title')}
    secondaryText={record =>
      permissions &&
      permissions.canList('customer') && (
        <ReferenceField
          basePath={props.basePath}
          record={record}
          source="customer_id"
          reference="customer"
          linkType={false}
        >
          <TextField source="business_name" />
        </ReferenceField>
      )
    }
    tertiaryText={getValue('id')}
  />
);

MobileOrderList.propTypes = {
  basePath: PropTypes.string,
  permissions: PropTypes.object,
};

const OrderList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={
      <OrderListBulkActions hasDelete={props.options.hasDelete} permissions={permissions} />
    }
    exporter={false}
    filters={<OrderFilter permissions={permissions} />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sort}
    {...props}
  >
    <Responsive
      small={<MobileOrderList permissions={permissions} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <TextField source="id" />
          <ObjectStateSelect
            source="object_state_id"
            choices={Order.ORDER_STATE_ID.values}
            readOnly
          />
          <DateField source="ordered_at" />
          <TextField source="job_title" />
          {permissions && permissions.canList('customer') && (
            <ReferenceField source="customer_id" reference="customer">
              <TextField source="business_name" />
            </ReferenceField>
          )}
          <TextField source="quotation_number" />
          <TextField source="location" />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

OrderList.propTypes = {
  hasEdit: PropTypes.bool,
  permissions: PropTypes.object,
  options: PropTypes.object,
};

export default OrderList;
