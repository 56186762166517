import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CardActions, ListButton, ShowButton } from 'react-admin';

const sanitizeRestProps = ({
  basePath,
  className,
  record,
  hasCreate,
  hasEdit,
  hasShow,
  hasList,
  leftActions,
  rightActions,
  ...rest
}) => rest;

/**
 * Override default edit actions, replace DeleteButton by DeleteConfirmationButton.
 */
const CustomEditActions = ({
  basePath,
  className,
  data,
  hasList,
  hasShow,
  labels,
  leftActions,
  resource,
  rightActions,
  ...rest
}) => (
  <CardActions className={classnames(className)} {...sanitizeRestProps(rest)}>
    {leftActions &&
      leftActions.map((action, index) =>
        React.cloneElement(action, { key: index, basePath, record: data, resource })
      )}
    {hasShow && <ShowButton basePath={basePath} record={data} />}
    {hasList && <ListButton basePath={basePath} />}
    {rightActions &&
      rightActions.map((action, index) =>
        React.cloneElement(action, { key: index, basePath, record: data, resource })
      )}
  </CardActions>
);

CustomEditActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
    })
  ),
  leftActions: PropTypes.array,
  resource: PropTypes.string,
  rightActions: PropTypes.array,
};

export default CustomEditActions;
