import PropTypes from 'prop-types';
import React from 'react';
import { Create, SimpleForm, TextInput, translate } from 'react-admin';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { roleValidators } from '../../../validators';
import { CustomCreateActions } from '../../framework';
import { PermissionDatagrid } from '../../Permission';

const RoleCreate = ({ permissions, translate, ...props }) => (
  <Create {...props} actions={<CustomCreateActions />}>
    <SimpleForm redirect={REDIRECT_AFTER_CREATE}>
      <TextInput source="name" validate={roleValidators.name} />
      {permissions && permissions.canList('permission') && (
        <PermissionDatagrid
          addLabel
          label={translate('resources.permission.name', 2)}
          source="permissions"
        />
      )}
    </SimpleForm>
  </Create>
);

RoleCreate.propTypes = {
  permissions: PropTypes.object,
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default translate(RoleCreate);
