import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import { Link } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  });

const RedirectLink = ({ children, className, classes, to, target, ...props }) => {
  return (
    <Link className={classnames(classes.link, className)} to={to} target={target}>
      {React.cloneElement(children, {
        className: classes.link, // force color override for Typography components
        ...children.props,
        ...props,
      })}
    </Link>
  );
};

RedirectLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  target: PropTypes.string,
  to: PropTypes.string,
};

const enhance = compose(
  withStyles(styles),
  shouldUpdate((props, nextProps) => props.to !== nextProps.to || props.target !== nextProps.target)
);

export default enhance(RedirectLink);
