import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Component } from 'react';
import {
  crudGetManyAccumulate as crudGetManyAccumulateAction,
  crudGetMatchingAccumulate as crudGetMatchingAccumulateAction,
  getPossibleReferences,
  getPossibleReferenceValues,
  getReferenceResource,
  withTranslate,
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { referenceSource as defaultReferenceSource } from '../../../../actions';
import { getStatusForInput as getDataStatus } from './referenceDataStatus';

/**
 * Goal of this controller is to get reference record from its name and not from its id.
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/input/ReferenceInputController.tsx
 */

class UnconnectedReferenceInputController extends Component {
  constructor(props) {
    super(props);
    const { perPage, sort, filter } = props;
    this.state = { pagination: { page: 1, perPage }, sort, filter };
    this.debouncedSetFilter = debounce(this.setFilter.bind(this), 500);
  }

  componentDidMount() {
    this.fetchReferenceAndOptions(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.record || { id: undefined }).id !== (nextProps.record || { id: undefined }).id
    ) {
      this.fetchReferenceAndOptions(nextProps);
    } else if (this.props.input.value !== nextProps.input.value) {
      this.fetchReference(nextProps);
    } else if (
      !isEqual(nextProps.filter, this.props.filter) ||
      !isEqual(nextProps.sort, this.props.sort) ||
      nextProps.perPage !== this.props.perPage
    ) {
      this.setState(
        state => ({
          filter: nextProps.filter,
          pagination: {
            ...state.pagination,
            perPage: nextProps.perPage,
          },
          sort: nextProps.sort,
        }),
        this.fetchOptions
      );
    }
  }

  setFilter = filter => {
    if (filter !== this.state.filter) {
      this.setState({ filter: this.props.filterToQuery(filter) }, this.fetchOptions);
    }
  };

  setPagination = pagination => {
    if (pagination !== this.state.pagination) {
      this.setState({ pagination }, this.fetchOptions);
    }
  };

  setSort = sort => {
    if (sort !== this.state.sort) {
      this.setState({ sort }, this.fetchOptions);
    }
  };

  fetchReference = (props = this.props) => {
    const { crudGetManyAccumulate, input, reference } = props;
    const id = input.value;
    if (id) {
      crudGetManyAccumulate(reference, [id]);
    }
  };

  fetchOptions = (props = this.props) => {
    const {
      crudGetMatchingAccumulate,
      filter: filterFromProps,
      reference,
      referenceSource,
      resource,
      source,
    } = props;
    const { pagination, sort, filter } = this.state;

    crudGetMatchingAccumulate(reference, referenceSource(resource, source), pagination, sort, {
      ...filterFromProps,
      ...filter,
    });
  };

  fetchReferenceAndOptions(props) {
    this.fetchReference(props);
    this.fetchOptions(props);
  }

  render() {
    const {
      input,
      referenceRecord,
      matchingReferences,
      onChange,
      children,
      translate,
    } = this.props;
    const { pagination, sort, filter } = this.state;

    const dataStatus = getDataStatus({
      input,
      matchingReferences,
      referenceRecord,
      translate,
    });

    return children({
      choices: dataStatus.choices,
      error: dataStatus.error,
      isLoading: dataStatus.waiting,
      onChange,
      filter,
      setFilter: this.debouncedSetFilter,
      pagination,
      setPagination: this.setPagination,
      sort,
      setSort: this.setSort,
      warning: dataStatus.warning,
    });
  }
}

UnconnectedReferenceInputController.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  matchingReferences: null,
  perPage: 25,
  sort: { field: 'id', order: 'DESC' },
  referenceRecord: null,
  referenceSource: defaultReferenceSource, // used in tests
};

const makeMapStateToProps = () => (_, { target, targetFilter }) =>
  createSelector(
    [getReferenceResource, getPossibleReferenceValues, (_, props) => props.input.value],
    (referenceState, possibleValues, inputId) => {
      /**
       * Changed way to retrieve reference record.
       * Retrieve it by its name and not by its ID.
       */
      const referenceRecord =
        referenceState &&
        Object.values(referenceState.data).find(
          item =>
            item[target] === inputId &&
            Object.keys(targetFilter || {}).every(key => item[key] === targetFilter[key])
        );
      if (inputId && referenceRecord) {
        inputId = referenceRecord.id;
      }

      return {
        matchingReferences: getPossibleReferences(referenceState, possibleValues, [inputId]),
        referenceRecord,
      };
    }
  );

const ReferenceNameInputController = compose(
  withTranslate,
  connect(makeMapStateToProps(), {
    crudGetManyAccumulate: crudGetManyAccumulateAction,
    crudGetMatchingAccumulate: crudGetMatchingAccumulateAction,
  })
)(UnconnectedReferenceInputController);

ReferenceNameInputController.defaultProps = {
  referenceSource: defaultReferenceSource, // used in makeMapStateToProps
};

export default ReferenceNameInputController;
