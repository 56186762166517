import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, FunctionField, List, Responsive, SimpleList, TextField } from 'react-admin';
import { CustomerFilter } from '..';
import { Contact, Customer } from '../../../models';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  defaultPerPage,
  DeleteConfirmButton,
  RepeatedStringField,
} from '../../framework';
import { ObjectStateSelect } from '../../ObjectState';
import CustomerReportingBulkButton from './CustomerReportingBulkButton';

const getMainContactName = record => Contact.getFullName(Customer.getMainContact(record));
const getValue = attr => record => get(record, attr);
const getZipAndCity = record =>
  `${getValue('address_zipcode')(record)} ${getValue('address_city')(record)}`;

/**
 * Get list bulk actions.
 */
const getBulkActions = (permissions, props) => {
  const actions = [];

  if (permissions && permissions.can(props.resource, 'generate_reporting')) {
    actions.push(<CustomerReportingBulkButton key={0} {...props} />);
  }

  if (props.options.hasDelete) {
    actions.push(<BulkDeleteConfirmButton key={1} {...props} />);
  }

  return actions.length ? actions : false;
};

const sortOrder = { field: 'business_name', order: 'ASC' };

export const CustomerList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={getBulkActions(permissions, props)}
    filters={<CustomerFilter />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sortOrder}
    {...props}
  >
    <Responsive
      small={<SimpleList primaryText={getValue('business_name')} secondaryText={getZipAndCity} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <ObjectStateSelect
            source="object_state_id"
            choices={Customer.OBJECT_STATE_ID.values}
            readOnly
          />
          <TextField source="business_name" />
          <TextField source="address_city" />
          <RepeatedStringField source="locations" />
          <FunctionField label="resources.contact.fields.is_main" render={getMainContactName} />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

CustomerList.propTypes = {
  hasEdit: PropTypes.bool,
  permissions: PropTypes.object,
  options: PropTypes.object,
};
