import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Component } from 'react';
import {
  crudGetMany as crudGetManyAction,
  crudGetMatching as crudGetMatchingAction,
  getPossibleReferences,
  getPossibleReferenceValues,
  getReferenceResource,
  withTranslate,
} from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { referenceSource as defaultReferenceSource } from '../../../../actions';
import { isObject } from '../../../../utils/tools';
import { getStatusForArrayInput as getDataStatus } from './referenceDataStatus';

/**
 * Goal of this controller is to get reference record from its name and not from its id.
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/input/ReferenceArrayInputController.tsx
 */
export class UnconnectedReferenceArrayInputController extends Component {
  constructor(props) {
    super(props);
    const { perPage, sort, filter } = props;
    // stored as a property rather than state because we don't want redraw of async updates
    this.params = { pagination: { page: 1, perPage }, sort, filter };
    this.debouncedSetFilter = debounce(this.setFilter.bind(this), 500);
  }

  componentDidMount() {
    this.fetchReferencesAndOptions(this.props, {});
  }

  componentWillReceiveProps(nextProps) {
    let shouldFetchOptions = false;

    if (
      (this.props.record || { id: undefined }).id !== (nextProps.record || { id: undefined }).id
    ) {
      this.fetchReferencesAndOptions(nextProps);
    } else if (this.props.input.value !== nextProps.input.value) {
      this.fetchReferences(nextProps);
    } else {
      if (!isEqual(nextProps.filter, this.props.filter)) {
        this.params = { ...this.params, filter: nextProps.filter };
        shouldFetchOptions = true;
      }
      if (!isEqual(nextProps.sort, this.props.sort)) {
        this.params = { ...this.params, sort: nextProps.sort };
        shouldFetchOptions = true;
      }
      if (nextProps.perPage !== this.props.perPage) {
        this.params = {
          ...this.params,
          pagination: {
            ...this.params.pagination,
            perPage: nextProps.perPage,
          },
        };
        shouldFetchOptions = true;
      }
    }
    if (shouldFetchOptions) {
      this.fetchOptions();
    }
  }

  setFilter = filter => {
    if (filter !== this.params.filter) {
      this.params.filter = this.props.filterToQuery(filter);
      this.fetchOptions();
    }
  };

  setPagination = pagination => {
    if (pagination !== this.params.pagination) {
      this.params.pagination = pagination;
      this.fetchOptions();
    }
  };

  setSort = sort => {
    if (sort !== this.params.sort) {
      this.params.sort = sort;
      this.fetchOptions();
    }
  };

  fetchReferences = (nextProps, currentProps = this.props) => {
    const { crudGetMany, input, reference } = nextProps;
    const ids = input.value;
    if (ids) {
      if (!Array.isArray(ids)) {
        throw Error('The value of ReferenceArrayInput should be an array');
      }
      /*
       * ========================================
       * Get items name (objects instead of ids)
       * ========================================
       */
      const idsToFetch = difference(
        ids.map(item => (isObject(item) ? item.name : item)),
        (get(currentProps, 'input.value') || []).map(item => (isObject(item) ? item.name : item))
      );
      if (idsToFetch.length) crudGetMany(reference, idsToFetch);
    }
  };

  fetchOptions = (props = this.props) => {
    const {
      crudGetMatching,
      reference,
      source,
      resource,
      referenceSource,
      filter: defaultFilter,
    } = props;
    const { pagination, sort, filter } = this.params;
    crudGetMatching(reference, referenceSource(resource, source), pagination, sort, {
      ...defaultFilter,
      ...filter,
    });
  };

  fetchReferencesAndOptions(nextProps, currentProps = this.props) {
    this.fetchReferences(nextProps, currentProps);
    this.fetchOptions(nextProps);
  }

  render() {
    const { input, referenceRecords, matchingReferences, onChange, children, translate } =
      this.props;

    const dataStatus = getDataStatus({
      input,
      matchingReferences,
      referenceRecords,
      translate,
    });

    return children({
      choices: dataStatus.choices,
      error: dataStatus.error,
      isLoading: dataStatus.waiting,
      onChange,
      setFilter: this.debouncedSetFilter,
      setPagination: this.setPagination,
      setSort: this.setSort,
      warning: dataStatus.warning,
    });
  }
}

UnconnectedReferenceArrayInputController.defaultProps = {
  allowEmpty: false,
  filter: {},
  filterToQuery: searchText => ({ q: searchText }),
  matchingReferences: null,
  perPage: 25,
  sort: { field: 'id', order: 'DESC' },
  referenceRecords: [],
  referenceSource: defaultReferenceSource, // used in unit tests
};

const makeMapStateToProps =
  () =>
  (_, { target, targetFilter }) =>
    createSelector(
      [
        getReferenceResource,
        getPossibleReferenceValues,
        (_, { resource, input }) => {
          const { value: referenceIds } = input;

          if (!referenceIds) {
            return [];
          }

          if (Array.isArray(referenceIds)) {
            return referenceIds;
          }

          throw new Error(
            `<ReferenceArrayInput> expects value to be an array, but the value passed as '${resource}.${
              input.name
            }' is type '${typeof referenceIds}': ${referenceIds}`
          );
        },
      ],
      (referenceState, possibleValues, inputIds) => {
        if (Array.isArray(inputIds)) {
          /**
           * Inject current input value in possible values, when current value is not in current batch of suggestions.
           */
          const inputData = inputIds
            .map(val => Object.values(referenceState.data).find(item => item.name === val))
            .filter(Boolean);

          for (const data of inputData) {
            if (!possibleValues) {
              possibleValues = [];
            }

            if (!possibleValues.includes(data.id)) {
              possibleValues.push(data.id);
            }
          }
        }

        return {
          matchingReferences: getPossibleReferences(referenceState, possibleValues, inputIds),
          referenceRecords:
            /*
             * ============================================
             * Changed way to retrieve reference record.
             * Retrieve it by its name and not by its ID.
             * ============================================
             */
            referenceState &&
            inputIds.reduce((references, referenceId) => {
              const matchingReference = Object.values(referenceState.data).find(
                item =>
                  (item[target] === referenceId[target] || item[target] === referenceId) &&
                  Object.keys(targetFilter || {}).every(key => item[key] === targetFilter[key])
              );
              if (matchingReference) {
                references.push(matchingReference.name);
              }
              return references;
            }, []),
        };
      }
    );

const ReferenceArrayInputController = compose(
  withTranslate,
  connect(makeMapStateToProps(), {
    crudGetMany: crudGetManyAction,
    crudGetMatching: crudGetMatchingAction,
  })
)(UnconnectedReferenceArrayInputController);

ReferenceArrayInputController.defaultProps = {
  referenceSource: defaultReferenceSource, // used in makeMapStateToProps
};

export default ReferenceArrayInputController;
