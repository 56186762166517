import PropTypes from 'prop-types';
import React from 'react';
import { Edit, TabbedForm } from 'react-admin';
import { CustomEditActions, PageTitle, Toolbar } from '../../framework';
import SendMailButton from '../../Mail/SendMailButton';
import CustomerEditContactsTab from './CustomerEditContactsTab';
import CustomerEditGeneralTab from './CustomerEditGeneralTab';
import CustomerEditOrdersTab from './CustomerEditOrdersTab';

/**
 * Get available actions regarding permissions.
 * @param {object} permissions the permissions.
 * @param {string} resource the resource name.
 * @returns {Array} a list of buttons.
 */
const getEditActions = (permissions, resource) => {
  const actions = [];

  if (permissions && permissions.can(resource, 'send_email')) {
    actions.push(<SendMailButton key={0} />);
  }

  return actions;
};

export const CustomerEdit = ({ permissions, ...props }) => (
  <Edit
    actions={<CustomEditActions leftActions={getEditActions(permissions, props.resource)} />}
    title={<PageTitle source="business_name" {...props} />}
    undoable={false}
    {...props}
  >
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <CustomerEditGeneralTab contentClassName="tab-inline" label="form.customer.tabs.general" />
      <CustomerEditContactsTab label="form.customer.tabs.contacts" path="contacts" />
      {permissions && permissions.canList('order') && (
        <CustomerEditOrdersTab label="form.customer.tabs.orders" path="orders" />
      )}
    </TabbedForm>
  </Edit>
);

CustomerEdit.propTypes = {
  hasEdit: PropTypes.bool,
  options: PropTypes.object,
  permissions: PropTypes.object,
  resource: PropTypes.string,
};
