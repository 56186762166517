import { email, maxLength } from 'react-admin';
import { notEmpty } from './validators';

const emailOrEmailList = (value, allValues, props) => {
  if (value) {
    const emails = value.split(/[,;]/);
    const errors = emails.map(val => email()(val, allValues, props));
    if (errors.find(Boolean)) {
      return props.translate('validation.emailOrListEmail', { separators: ',' });
    }
  }

  return undefined;
};

export default {
  type: [notEmpty(), maxLength(32)],
  subject: [notEmpty(), maxLength(128)],
  body: [],
  cc: [emailOrEmailList, maxLength(64)],
  bcc: [emailOrEmailList, maxLength(64)],
};
