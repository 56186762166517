import React from 'react';
import { Route } from 'react-router-dom';
import { ResetPassword } from '../components/CustomLayout';

export default [
  <Route
    key={0}
    path="/auth/reset/:email/:token"
    component={ResetPassword}
    render={null}
    noLayout
  />,
];
