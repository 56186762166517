import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { APP_VERSION, AUTH, LOGIN_URL, LOGOUT_URL } from '../settings';
import { Session } from './session';
import { PermissionManager } from './permissions';

/**
 * Authentication service.
 * Transform a logn/password into a Bearer token to be used by APIs.
 */
export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;

    const request = new Request(LOGIN_URL, {
      method: 'POST',
      body: JSON.stringify({
        client_id: AUTH.CLIENT_ID,
        client_secret: AUTH.CLIENT_SECRET,
        grant_type: AUTH.GRAND_TYPE,
        username,
        password,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status === 503) {
          throw new Error('error.MaintenanceModeException');
        } else if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
        const { permissions, token, user } = response;
        Session.setAppVersion(APP_VERSION);
        Session.setPermissions(permissions);
        Session.setToken(token);
        Session.setUser(user);
      });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    const access_token = Session.getAccessToken();

    if (access_token) {
      const request = new Request(LOGOUT_URL, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        }),
      });

      // launch request but don't wait response
      fetch(request);
    }

    Session.close();
    return Promise.resolve();
  }

  // called when API returns an error
  if (type === AUTH_ERROR) {
    const { body, status } = params;

    if (status === 401 || status === 403) {
      Session.close();
      return Promise.reject();
    }

    if (body && body.exception === 'MaintenanceModeException') {
      // maintenance mode on server, logout user
      console.warn(`Application is in maintenance.`);
      Session.close();
      return Promise.reject();
    }

    return Promise.resolve();
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    if (Session.isAppOutdated()) {
      // version has been updated, force re-login.
      const version = Session.getAppVersion();
      console.warn(`New version ${APP_VERSION} released (was ${version}), sign-in again please.`);
      Session.destroy();
    }

    if (!Session.isAccessTokenValid()) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve(new PermissionManager());
  }

  return Promise.reject(`Unknown method ${type}`);
};
