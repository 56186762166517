import { CREATE, UPDATE } from 'react-admin';

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * @param {object} file the File structure representing the object given
 * @returns {Promise}
 * @see https://marmelab.com/react-admin/DataProviders.html#decorating-your-data-provider-example-of-file-upload
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result.split('base64,').pop());
    reader.onerror = reject;
  });

/**
 * For company create and update, convert uploaded image in base 64 and attach it to the request.
 * @param {object} requestHandler the original request handler.
 * @param {string} type the type of request.
 * @param {string} resource the resource affected by the request.
 * @param {object} params parameters sent in the request.
 * @returns {Promise}
 * @see https://marmelab.com/react-admin/DataProviders.html#decorating-your-data-provider-example-of-file-upload}
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
  if ([CREATE, UPDATE].includes(type)) {
    const FILE_ARRAY_SEPARATOR = '#';

    /**
     * List attributes names.
     * If repeated attribute, list them and concat index to attribute name.
     */
    const uploads = Object.keys(params.data).reduce((acc, attr) => {
      const newValues = [];

      if (params.data[attr] && typeof params.data[attr] === 'object') {
        if (params.data[attr].rawFile instanceof File) {
          newValues.push(attr);
        } else if (Array.isArray(params.data[attr])) {
          params.data[attr].forEach((item, index) => {
            if (item && typeof item === 'object' && item.rawFile instanceof File) {
              newValues.push(`${attr}${FILE_ARRAY_SEPARATOR}${index}`);
            }
          });
        }
      }

      return [...acc, ...newValues];
    }, []);

    if (uploads.length) {
      return Promise.all(
        uploads.map(attr => {
          /**
           * Transform string into file data.
           */
          const arrayAttr = attr.split(FILE_ARRAY_SEPARATOR);
          if (arrayAttr.length > 1) {
            return convertFileToBase64(params.data[arrayAttr[0]][arrayAttr[1]].rawFile);
          } else {
            return convertFileToBase64(params.data[attr].rawFile);
          }
        })
      )
        .then(base64DataList =>
          base64DataList.map((base64Data, index) => {
            /**
             * Build file structure before sending.
             */
            const arrayAttr = uploads[index].split(FILE_ARRAY_SEPARATOR);
            const objInRequest =
              arrayAttr.length > 1
                ? params.data[arrayAttr[0]][arrayAttr[1]]
                : params.data[uploads[index]];

            return {
              filename: objInRequest.rawFile.name,
              raw: base64Data,
              size: objInRequest.rawFile.size,
              type: objInRequest.rawFile.type,
            };
          })
        )
        .then(newFiles =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              ...newFiles.reduce(
                (acc, newFile, index) => {
                  const arrayAttr = uploads[index].split(FILE_ARRAY_SEPARATOR);
                  const attrToInject = arrayAttr[0];
                  let valueToInject = newFile;

                  if (arrayAttr.length > 1) {
                    valueToInject = params.data[attrToInject];
                    valueToInject[arrayAttr[1]] = newFile;
                  }

                  return { ...acc, [attrToInject]: valueToInject };
                },
                {}
              ),
              uploads,
            },
          })
        );
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
