import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, DateField, List, Responsive, SimpleList, TextField } from 'react-admin';
import { User } from '../../../models/user';
import {
  BooleanField,
  BulkDeleteConfirmButton,
  DefaultPagination,
  DeleteConfirmButton,
  EmailField,
  defaultPerPage,
} from '../../framework';
import { UserFilter } from '../UserFilter/UserFilter';

const getValue = attr => record => record[attr];
const bulkActions = <BulkDeleteConfirmButton />;

export const UserList = props => (
  <List
    {...props}
    bulkActionButtons={props.options.hasDelete ? bulkActions : false}
    filters={<UserFilter />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={User.sortOrder}
  >
    <Responsive
      small={<SimpleList primaryText={getValue('name')} secondaryText={getValue('email')} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <TextField source="name" />
          <EmailField source="email" />
          <BooleanField source="enabled" />
          <DateField source="updated_at" showTime />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

UserList.propTypes = {
  hasEdit: PropTypes.bool,
  permissions: PropTypes.object,
  options: PropTypes.object,
};
