import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

/**
 * Same component as react-admin.
 * Overriden because not exported.
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/ReferenceError.js
 */
const ReferenceError = ({ label, error }) => (
  <TextField error disabled label={label} value={error} margin="normal" />
);

ReferenceError.propTypes = {
  error: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ReferenceError;
