import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FunctionField } from 'react-admin';

const regex = /(\d{2}\s?)/gi;

const format = source => record => {
  const value = get(record, source) || '';
  const groups = value.match(regex) || [];
  return groups.join(' ').trim();
};

const PhoneField = ({ source, ...props }) => <FunctionField render={format(source)} {...props} />;

PhoneField.propTypes = {
  source: PropTypes.string,
};

export default PhoneField;
