import PropTypes from 'prop-types';
import React from 'react';
import { Datagrid, List, Responsive, SimpleList, TextField } from 'react-admin';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  defaultPerPage,
  DeleteConfirmButton,
} from '../../framework';
import RoleFilter from './RoleFilter';

const roleListSortOrder = { field: 'name', order: 'ASC' };
const roleFilters = <RoleFilter />;
const getName = record => record.name;
const bulkActions = <BulkDeleteConfirmButton />;

/**
 * Roles list.
 * @param {*} props list options.
 */
const RoleList = props => (
  <List
    {...props}
    sort={roleListSortOrder}
    filters={roleFilters}
    bulkActionButtons={props.options.hasDelete ? bulkActions : false}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
  >
    <Responsive
      small={<SimpleList primaryText={getName} linkType={props.hasEdit ? 'edit' : false} />}
      medium={
        <Datagrid rowClick={props.hasEdit ? 'edit' : undefined}>
          <TextField source="name" />
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

RoleList.propTypes = {
  hasEdit: PropTypes.bool,
  options: PropTypes.object,
};

export default RoleList;
