import { stringify } from 'query-string';
import { fetchUtils } from 'react-admin';

/**
 * @param {String} baseUrl
 * @param {String} endpoint
 * @param {Object} queryParams
 */
export const buildUrl = (baseUrl, endpoint, queryParams) => {
  let url = baseUrl;

  if (!baseUrl.endsWith('/')) {
    url = `${url}/`;
  }

  url = `${url}${endpoint.startsWith('/') ? endpoint.substring(1) : endpoint}`;

  if (queryParams) {
    url = `${url}?${stringify(queryParams)}`;
  }

  return url;
};

/**
 * @param {String} method
 * @param {String} url
 * @param {Object} data
 * @returns {Promise}
 */
export const fetchPublicApi = (method, url, data) => {
  const { fetchJson } = fetchUtils;

  const options = { method, body: JSON.stringify(data) };
  const fetchMethod = fetchJson;
  const fetchParams = [url, options];

  return fetchMethod(...fetchParams).then(({ json }) => json);
};
