import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { updateObjectStateId as updateObjectStateIdAction } from '../../actions/stateActions';
import { crudGetTransition as crudGetTransitionAction } from '../../actions/transitionActions';
import { PermissionManager } from '../../data-provider';
import { Confirm } from '../framework';

/**
 * Get className from a color name.
 * @param {string} key the color name.
 */
const getColorClassName = key => `labelColor${key}`;
const getChipClassName = key => `chipColor${key}`;

const styles = theme =>
  createStyles({
    menuItem: {
      fontSize: theme.typography.body1.fontSize,
    },
    menuItemError: {
      color: theme.palette.error.main,
    },
    selectInput: {
      fontSize: theme.typography.body1.fontSize,
    },
    ...Object.keys(theme.custom.stateLabel).reduce(
      (acc, key) => ({
        ...acc,
        [getChipClassName(key)]: {
          backgroundColor: theme.custom.stateLabel[key],
          color: theme.palette.getContrastText(theme.custom.stateLabel[key]),
        },
        [getColorClassName(key)]: {
          color: theme.custom.stateLabel[key],
        },
      }),
      {}
    ),
  });

class ObjectStateSelect extends React.Component {
  state = { confirm: false, confirmStep: null };

  componentDidMount() {
    this.fetchTransitions();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.resource !== prevProps.resource ||
      (!this.props.readOnly && prevProps.readOnly)
    ) {
      this.fetchTransitions();
    }
  }

  /**
   * Fetch state transitions from server.
   */
  fetchTransitions() {
    const { readOnly } = this.props;
    if (typeof this.props.transitions === 'undefined' && !readOnly) {
      // load transitions only if they have not been already loaded (already in state).
      this.props.crudGetTransition(this.props.resource);
    }
  }

  /**
   * Handle confirmation cancel.
   */
  handleStepClickCancel = () => {
    this.setState({ confirm: false });
  };

  /**
   * Handle confirmation.
   * Launch a request to server to update state_id of the current object.
   */
  handleStepClickConfirm = () => {
    const { basePath, record, resource, sourceId } = this.props;
    const step = Object.assign({}, this.state.confirmStep);
    const id = get(record, sourceId);

    this.setState({ confirm: false });
    this.props.updateObjectStateId(resource, id, step.id, record, basePath);
  };

  /**
   * Handle selection of a step.
   * @param {Event} event the event generated at step selection.
   */
  handleStepChange = event => {
    const { choices } = this.props;
    const step = choices.find(choice => choice.id === event.target.value);

    this.setState({ confirm: true, confirmStep: step });
  };

  /**
   * Get active step object.
   * @returns {object} the current active step.
   */
  getCurrentStep = () => {
    const { choices, record, source, step } = this.props;
    const value = typeof step === 'undefined' ? get(record, source) : step;

    return choices.find(choice => choice.id === value);
  };

  /**
   * Get ID of the current step.
   * @returns {string|number} the step ID.
   * @see getCurrentStep
   */
  getCurrentStepId = () => {
    const step = this.getCurrentStep();
    if (step) {
      return step.id;
    }
  };

  /**
   * Check if a step is enabled.
   * @param {object} step the current step to check.
   * @returns {boolean}
   */
  isStepEnabled = step => {
    const { disabledSteps, enabledSteps, readOnly, transitions } = this.props;
    const activeStepId = this.getCurrentStepId();

    if (Array.isArray(enabledSteps) && enabledSteps.includes(step.id)) {
      return true;
    }

    if (Array.isArray(disabledSteps) && disabledSteps.includes(step.id)) {
      return false;
    }

    if (readOnly || !transitions || activeStepId === step.id) {
      return false;
    }

    const stepTransitions = transitions[activeStepId];
    return stepTransitions && stepTransitions.includes(step.id);
  };

  render() {
    const {
      choices,
      classes,
      className,
      readOnly,
      record,
      source,
      translate,
      withColor,
    } = this.props;

    if (readOnly) {
      let selectFieldClasses = '';
      const { optionText, optionValue } = this.props;

      const value = get(record, source);
      const choice = choices.find(c => c[optionValue] === value);
      const i18nValue = choice ? translate(choice[optionText]) : '';

      if (withColor) {
        if (choice && choice.color) {
          selectFieldClasses = classes[getChipClassName(choice.color)];
        }
      }

      return <Chip label={i18nValue} className={classnames(className, selectFieldClasses)} />;
    }

    const { resource } = this.props;
    const { confirm, confirmStep } = this.state;
    const currentStep = this.getCurrentStep();
    const currentStepId = this.getCurrentStepId();
    const selectClasses = {
      root: classnames(
        classes.selectInput,
        withColor &&
          currentStep &&
          currentStep.color &&
          classes[getColorClassName(currentStep.color)],
        className
      ),
    };

    return (
      <React.Fragment>
        <Select onChange={this.handleStepChange} value={currentStepId} classes={selectClasses}>
          {choices.map(step => {
            const isEnabled = this.isStepEnabled(step);
            const menuClasses = {
              root: classes.menuItem,
              selected: step.error ? classes.menuItemError : undefined,
            };

            return (
              <MenuItem key={step.id} disabled={!isEnabled} value={step.id} classes={menuClasses}>
                {translate(step.name)}
              </MenuItem>
            );
          })}
        </Select>
        <Confirm
          open={confirm}
          onCancel={this.handleStepClickCancel}
          onConfirm={this.handleStepClickConfirm}
          content={translate('message.state_stepper_confirm', {
            name: confirmStep && translate(confirmStep.name),
          })}
          title={`resources.${resource}.fields.${source}`}
          translateContent={false}
        />
      </React.Fragment>
    );
  }
}

ObjectStateSelect.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      completed: PropTypes.bool,
      error: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  crudGetTransition: PropTypes.func.isRequired,
  disabledSteps: PropTypes.array,
  enabledSteps: PropTypes.array,
  label: PropTypes.string,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
  optionValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitions: PropTypes.object,
  translate: PropTypes.func.isRequired,
  updateObjectStateId: PropTypes.func.isRequired,
  withColor: PropTypes.bool,
};

ObjectStateSelect.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
  readOnly: false,
  record: {},
  sourceId: 'id',
  withColor: true,
};

/**
 * Load transitions from app state.
 * @param {*} state the app state.
 * @param {*} props the component properties.
 */
function mapStateToProps(state, props) {
  return { transitions: state.transition[props.resource] };
}

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {
      crudGetTransition: crudGetTransitionAction,
      updateObjectStateId: updateObjectStateIdAction,
    }
  ),
  withStyles(styles)
);

const ObjectStateSelectConnected = enhance(ObjectStateSelect);

const ObjectStateSelectWrapper = props => {
  const permissions = new PermissionManager();
  return (
    <ObjectStateSelectConnected
      {...props}
      readOnly={props.readOnly || !permissions.can(props.resource, 'set_object_state')}
    />
  );
};

ObjectStateSelectWrapper.propTypes = {
  readOnly: PropTypes.bool,
  resource: PropTypes.string,
};

export default ObjectStateSelectWrapper;
