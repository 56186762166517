import PropTypes from 'prop-types';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { Order } from '../../../models';

const OrderFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="id" label="resources.order.fields.id" alwaysOn />
    {permissions && permissions.canList('customer') && (
      <ReferenceInput source="customer_id" reference="customer" alwaysOn>
        <AutocompleteInput optionText="business_name" />
      </ReferenceInput>
    )}
    <TextInput source="quotation_number" />
    <DateInput source="ordered_at" />
    <BooleanInput source="use_deposit" />
    <BooleanInput source="deposit_payed" />
    <SelectInput source="object_state_id" choices={Order.ORDER_STATE_ID.values} />
  </Filter>
);

OrderFilter.propTypes = {
  permissions: PropTypes.object,
};

export default OrderFilter;
