import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import History from '@material-ui/icons/History';
import compose from 'recompose/compose';
import classnames from 'classnames';

import { Title, translate } from 'react-admin';

const styles = theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
        marginTop: '-3em',
      },
    },
    message: {
      textAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      margin: '0 1em',
    },
    messageBody: {
      opacity: 0.5,
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '1em',
    },
    picture: {
      filter: 'contrast(97%)',
      opacity: 0.8,
    },
  });

function goBack() {
  window.history.go(-1);
}

const NotFound = ({ classes, className, translate, title, ...rest }) => (
  <div className={classnames(classes.container, className)} {...rest}>
    <Title defaultTitle={title} />
    <div className={classes.message}>
      <img className={classes.picture} src="images/404.gif" alt={translate('ra.page.not_found')} />
      <div className={classes.messageBody}>
        <h1>¯\_(ツ)_/¯</h1>
        {translate('ra.message.not_found')}
      </div>
    </div>
    <div className={classes.toolbar}>
      <Button variant="raised" icon={<History />} onClick={goBack}>
        {translate('ra.action.back')}
      </Button>
    </div>
  </div>
);

NotFound.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
  translate
);

export default enhance(NotFound);
