/**
 * Check if a string value is empty.
 * Is considered empty:
 *   - null
 *   - undefined
 *   - ''
 *   - string composed only of spaces
 *   - empty array
 * @param {*} value the value to check.
 */
export const isEmpty = function isEmpty(value) {
  const emptyString =
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (typeof value === 'string' && value.trim().length === 0);

  const emptyArray = Array.isArray(value) && value.length === 0;

  return emptyString || emptyArray;
};

/**
 * Check if a value is not empty.
 * @param {string} value the value to check
 * @see isEmpty
 */
export const isNotEmpty = value => !isEmpty(value);

/**
 * Check if two lists are equals (sort matters).
 * @param {Array} list1 the first list to compare.
 * @param {Array} list2 the second list to compare.
 */
export const listEquals = (list1, list2) =>
  list1.length === list2.length && list1.every((value, i) => value === list2[i]);

/**
 * Check if two sequences (unordered list) are equals.
 * @param {Array} sequence1 the first sequence to compare.
 * @param {Array} sequence2 the second sequence to compare.
 */
export const sequenceEquals = (sequence1, sequence2) => {
  if (sequence1 === sequence2) {
    return true; // for undefined or null
  }

  if (!sequence1 || !sequence2) {
    return false;
  }

  const sorted1 = sequence1.slice();
  const sorted2 = sequence2.slice();

  return listEquals(sorted1.sort(), sorted2.sort());
};

/**
 * Filter duplicates values in a list and return a new list with unique values.
 * @param {Array} list the list to filter.
 * @returns {Array} the filtered list.
 */
export const uniquify = list => list.slice().filter((v, i, a) => a.indexOf(v) === i);

/**
 * Check if a variable is a native javascipt object (`{}`).
 * @param {*} value the variable to test.
 * @returns {boolean}
 * @see https://stackoverflow.com/a/16608074/9925718
 */
export const isObject = value =>
  // null and undefined have no constructors, (!!a) filters them out.
  !!value && value.constructor === Object;

/**
 * Check if a value is a primitive type.
 * @param {*} value the value to check.
 * @see https://stackoverflow.com/a/31538091/9925718
 */
export const isPrimitive = value => value !== Object(value);

/**
 * Get a list of sorted ids, regarding the input data.
 * Useful for datagrids.
 * @param {Array|Object} input the data from which to get ids.
 *   If it's an object the keys will be used as ids, if it's an array the keys will be retrieved from each value.
 * @param {Object} sort the sort criteria. Must be an object like `{ field: 'created_at', order: 'ASC' }`.
 * @param {string} sourceId the field to use to retrieve the `id` value.
 * @returns {Array} a list of ids.
 */
export const getSortedIds = (input, sort, sourceId = 'id') => {
  let keys = [];
  let data = {};

  (Array.isArray(input) ? input : Object.values(input)).forEach(item => {
    // build a list of key to preserve eventual integer keys (Object has always string keys)
    keys.push(item[sourceId]);
    data[item[sourceId]] = item;
  });

  return keys.sort((a, b) => {
    const x = data[a][sort.field];
    const y = data[b][sort.field];

    if (isNaN(x) || isNaN(y)) {
      return (
        (x || '').toString().localeCompare((y || '').toString()) * (sort.order === 'ASC' ? 1 : -1)
      );
    } else {
      return (Number(x) - Number(y)) * (sort.order === 'ASC' ? 1 : -1);
    }
  });
};
