import React from 'react';
import PropTypes from 'prop-types';
import { EditView } from 'react-admin';
import { EditWithController } from '../controller';

const EditWith = ({ count, methods, relations, ...props }) => (
  <EditWithController {...props} count={count} methods={methods} relations={relations}>
    {controllerProps => <EditView {...props} {...controllerProps} />}
  </EditWithController>
);

EditWith.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  methods: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  relations: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resource: PropTypes.string.isRequired,
  title: PropTypes.any,
};

export default EditWith;
