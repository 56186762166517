const placeholders = Object.freeze({
  '*': {
    '[recipient_name]': "Nom complet du destinataire de l'email",
    '[recipient_first_name]': "Prénom du destinataire de l'email",
    '[recipient_last_name]': "Nom du destinataire de l'email",
    '[recipient_title]': "Civilité du destinataire de l'email",
    '[user_name]': "Nom de l'expéditeur de l'email",
  },
  service_contract: {
    '[customer_name]': 'Nom du client',
  },
  reporting: {
    '[customer_name]': 'Nom du client',
  },
  candidate_welcome: {
    '[candidate_first_name]': 'Prénom du candidat',
    '[candidate_last_name]': 'Nom du candidat',
    '[candidate_answer_before]': 'Date de réponse (j+2)',
  },
});

export class Mail {
  /**
   * Get all tokens available for a given mail template.
   * @param {string} template the template type.
   * @return {Object}
   */
  static getTokensForTemplate = template =>
    Object.assign({}, placeholders[template], placeholders['*']);
}
