import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { translate } from 'react-admin';

const styles = theme =>
  createStyles({
    deleteConfirmButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

class Confirm extends React.PureComponent {
  /**
   * Close confirmation dialog when user clicks on Cancel or press `esc` key.
   * @param {Event} event the event generated byt the click action.
   */
  handleClickCancel = event => {
    event.stopPropagation();
    this.props.onCancel(event);
  };

  /**
   * Run delete action when user clicks on Ok or press `enter` key.
   * @param {Event} event the event generated byt the click action.
   */
  handleClickConfirm = event => {
    event.stopPropagation();
    this.props.onConfirm(event);
  };

  render() {
    const {
      cancel,
      classes,
      content,
      deleteAction,
      title,
      confirm,
      open,
      translate,
      translateButton,
      translateContent,
      translateTitle,
    } = this.props;

    const confirmButtonOptions = deleteAction
      ? { className: classnames('ra-delete-button', classes.deleteConfirmButton) }
      : { variant: 'raised', color: 'primary' };

    return (
      <Dialog
        open={open}
        onClose={this.handleClickCancel}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        {title && (
          <DialogTitle id="confirm-dialog-title">
            {translateTitle ? translate(title, { _: title }) : title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {translateContent ? translate(content, { _: content }) : content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClickCancel}>
            {translateButton ? translate(cancel, { _: cancel }) : cancel}
          </Button>
          <Button onClick={this.handleClickConfirm} {...confirmButtonOptions} autoFocus>
            {translateButton ? translate(confirm, { _: confirm }) : confirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

Confirm.propTypes = {
  cancel: PropTypes.string,
  classes: PropTypes.object.isRequired,
  content: PropTypes.node.isRequired,
  deleteAction: PropTypes.bool,
  confirm: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateButton: PropTypes.bool.isRequired,
  translateContent: PropTypes.bool.isRequired,
  translateTitle: PropTypes.bool.isRequired,
};

Confirm.defaultProps = {
  content: 'ra.message.are_you_sure',
  deleteAction: false,
  confirm: 'ra.action.confirm',
  cancel: 'ra.action.cancel',
  translateButton: true,
  translateContent: true,
  translateTitle: true,
};

const enhance = compose(
  translate,
  withStyles(styles)
);

export default enhance(Confirm);
