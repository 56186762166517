import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  FormTab,
  LongTextInput,
  ReferenceInput,
  Responsive,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { Customer, Invoice, Order, Tag } from '../../../models';
import { REDIRECT_AFTER_CREATE } from '../../../settings';
import { invoiceValidators, orderValidators } from '../../../validators';
import {
  CustomCreateActions,
  TagAutocompleteInput,
  ThousandsNumberInput,
  Toolbar,
} from '../../framework';
import { ObjectStateStepper } from '../../ObjectState';

const OrderCreate = ({ permissions, ...props }) => (
  <Create {...props} actions={<CustomCreateActions />}>
    <TabbedForm redirect={REDIRECT_AFTER_CREATE} toolbar={<Toolbar />}>
      <FormTab contentClassName="tab-inline" label="form.order.tabs.general">
        <Responsive
          small={
            <ObjectStateStepper
              source="object_state_id"
              choices={Order.ORDER_STATE_ID.values}
              orientation="vertical"
              step={Order.ORDER_STATE_ID.default}
              readOnly
            />
          }
          medium={
            <ObjectStateStepper
              source="object_state_id"
              choices={Order.ORDER_STATE_ID.values}
              step={Order.ORDER_STATE_ID.default}
              readOnly
            />
          }
          formClassName="ra-input__noflex"
          fullWidth
        />
        {permissions && permissions.canList('customer') && (
          <ReferenceInput
            formClassName="ra-input__flex-25"
            source="customer_id"
            reference="customer"
            sort={Customer.sortOrder}
            validate={orderValidators.customer_id}
          >
            <AutocompleteInput optionText="business_name" />
          </ReferenceInput>
        )}
        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="job_title"
          type={Tag.TYPE.JOB_NAME}
          validate={orderValidators.job_title}
        />
        <SelectInput
          choices={Order.DOMAINS_LEVEL.values}
          defaultValue={Order.DOMAINS_LEVEL.default}
          formClassName="ra-input__flex-25"
          source="job_qualification"
          validate={orderValidators.job_qualification}
        />
        <DateInput
          formClassName="ra-input__flex-25"
          source="job_start_date"
          validate={orderValidators.job_start_date}
        />
        <ThousandsNumberInput
          formClassName="ra-input__flex-25"
          source="salary_min"
          validate={orderValidators.salary_min}
        />
        <ThousandsNumberInput
          formClassName="ra-input__flex-25"
          source="salary_max"
          validate={orderValidators.salary_max}
        />
        <TagAutocompleteInput
          formClassName="ra-input__flex-25"
          source="location"
          type={Tag.TYPE.LOCATION}
          validate={orderValidators.location}
        />
        <DateInput
          formClassName="ra-input__flex-25"
          source="ordered_at"
          validate={orderValidators.ordered_at}
        />
        <BooleanInput
          formClassName="ra-input__flex-25 ra-input__v-align"
          source="offer_registred"
          validate={orderValidators.offer_registrerd}
        />
        <BooleanInput
          formClassName="ra-input__flex-25 ra-input__v-align"
          source="use_deposit"
          validate={orderValidators.use_deposit}
        />
        <FormDataConsumer formClassName="ra-input__flex-25 ra-input__v-align">
          {({ formData, ...rest }) =>
            formData &&
            formData.use_deposit && (
              <BooleanInput
                {...rest}
                source="deposit_payed"
                validate={orderValidators.deposit_payed}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer formClassName="ra-input__flex-25">
          {({ formData, ...rest }) =>
            formData && formData.use_deposit && formData.deposit_payed ? (
              <TextInput
                {...rest}
                source="deposit_invoice_number"
                validate={orderValidators.deposit_invoice_number}
              />
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          formClassName="ra-input__flex-25"
          source="quotation_number"
          validate={orderValidators.quotation_number}
        />
        <LongTextInput
          formClassName="ra-input__flex-25"
          source="quotation_comments"
          validate={orderValidators.quotation_comments}
        />
      </FormTab>

      <FormTab label="form.order.tabs.invoices" path="invoices">
        <ArrayInput
          className="array-input__simple-form-iterator--inline"
          fullWidth
          source="invoices"
        >
          <SimpleFormIterator>
            <TextInput
              label="resources.invoice.fields.number"
              source="number"
              validate={invoiceValidators.number}
            />
            <SelectInput
              label="resources.invoice.fields.object_state_id"
              source="object_state_id"
              choices={Invoice.INVOICE_STATE_ID.values}
              validate={invoiceValidators.object_state_id}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

OrderCreate.propTypes = {
  permissions: PropTypes.object,
};

export default OrderCreate;
