import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FileField } from 'react-admin';
import { buildUploadUrl } from '../../../utils/images';

/**
 * Transform url into object
 * @param {string} record the current url.
 * @param {string} source the attribute to set.
 */
const generateFakeFile = (record, source) => {
  const url = buildUploadUrl(record);
  const title = record
    .split('/')
    .slice(-1)
    .pop();
  return { [source]: url, rawFile: { preview: url }, title };
};

const styles = theme =>
  createStyles({
    link: {
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  });

/**
 * File field with consolidated url.
 */
const UploadedFileField = ({ classes, className, record, ...props }) => {
  const fakeRecord =
    typeof record === 'string' ? generateFakeFile(record, props.source) : Object.assign({}, record);

  return (
    <FileField {...props} record={fakeRecord} className={classnames(className, classes.link)} />
  );
};

UploadedFileField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  record: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  source: PropTypes.string,
};

export default withStyles(styles)(UploadedFileField);
