import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Reporting } from '../../../models/reporting';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { executeManyMethodPost } from '../../../actions/methodActions';

const styles = theme =>
  createStyles({
    root: {
      color: theme.palette.type === 'dark' && theme.palette.text.primary,
    },
  });

class SendReportingBulkButton extends React.Component {
  /**
   * Handle click.
   */
  handleClick = () => {
    const { basePath, executeManyMethodPost, resource, selectedIds } = this.props;

    executeManyMethodPost('sendEmail', resource, selectedIds, {}, {}, basePath, false, {
      notification_success: 'notification.email_sent',
    });
  };

  /**
   * Check if button should be disabled or not.
   * @returns {object}
   */
  isDisabled = () => {
    const { data, selectedIds, translate } = this.props;
    let disabled = false;
    let helpText;

    const notSendable = selectedIds.filter(id => !Reporting.canSendReporting(data[id]));

    if (notSendable.length > 0) {
      disabled = true;
      helpText = translate('action.no_send_reporting_bulk', {
        smart_count: notSendable.length,
        items: notSendable.map(key => data[key].id).join(', '),
      });
    }

    return { disabled, helpText };
  };

  render() {
    const { disabled, helpText } = this.isDisabled();
    const { classes, label } = this.props;

    const button = (
      <Button className={classes.root} disabled={disabled} label={label} onClick={this.handleClick}>
        {this.props.icon}
      </Button>
    );

    return helpText ? (
      <Tooltip title={helpText}>
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  }
}

SendReportingBulkButton.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  icon: PropTypes.element,
  label: PropTypes.string,
};

SendReportingBulkButton.defaultProps = {
  data: {},
  icon: <SendIcon />,
  label: 'action.send_reporting',
};

function mapStateToProps(state, props) {
  const { resource, selectedIds } = props;
  const data = state.admin.resources[resource].data;

  return {
    data: selectedIds.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {}),
  };
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(
    mapStateToProps,
    { executeManyMethodPost }
  )
);

export default enhance(SendReportingBulkButton);
