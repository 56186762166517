import React from 'react';
import { Filter, SearchInput } from 'react-admin';

const DegreeFilter = props => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
);

export default DegreeFilter;
