import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Responsive,
  SimpleList,
  TextField,
} from 'react-admin';
import {
  BulkDeleteConfirmButton,
  DefaultPagination,
  defaultPerPage,
  DeleteConfirmButton,
} from '../../framework';
import ReportingDownloadButton from './ReportingDownloadButton';
import ReportingFilter from './ReportingFilter';
import SendReportingBulkButton from './SendReportingBulkButton';

/**
 * Bulk buttons.
 */
const ReportingListBulkActions = ({ hasDelete, permissions, ...props }) => (
  <React.Fragment>
    {permissions && permissions.can(props.resource, 'send_email') && (
      <SendReportingBulkButton {...props} />
    )}
    {hasDelete && <BulkDeleteConfirmButton {...props} />}
  </React.Fragment>
);

ReportingListBulkActions.propTypes = {
  hasDelete: PropTypes.bool,
  permissions: PropTypes.object,
  resource: PropTypes.string,
};

const sort = { field: 'created_at', order: 'DESC' };
const getValue = attr => record => record[attr];

const ReportingList = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={
      <ReportingListBulkActions permissions={permissions} hasDelete={props.options.hasDelete} />
    }
    filters={<ReportingFilter />}
    pagination={<DefaultPagination />}
    perPage={defaultPerPage}
    sort={sort}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={getValue('id')}
          secondaryText={record => <DateField record={record} source="sent_at" showTime />}
          tertiaryText={record => <ReportingDownloadButton record={record} />}
        />
      }
      medium={
        <Datagrid>
          {permissions && permissions.canList('customer') && (
            <ReferenceField source="customer_id" reference="customer">
              <TextField source="business_name" />
            </ReferenceField>
          )}
          <DateField source="sent_at" showTime />
          <DateField source="created_at" showTime />
          {permissions && permissions.can(props.resource, 'download') && (
            <ReportingDownloadButton />
          )}
          {props.options.hasDelete && <DeleteConfirmButton label="" />}
        </Datagrid>
      }
    />
  </List>
);

ReportingList.propTypes = {
  hasEdit: PropTypes.bool,
  options: PropTypes.object,
  permissions: PropTypes.object,
  resource: PropTypes.string,
};

export default ReportingList;
