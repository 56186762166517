import { CRUD_UPDATE } from 'react-admin';
import { OBJECT_STATE_ID_UPDATE } from '.';

/**
 *
 * @param {string} resource the resource name.
 * @param {string|number} id the record id.
 * @param {string|number} newStateId the ID of the new state to set for the record.
 * @param {object} previousData the previous record data.
 * @param {string} basePath base path for record.
 * @param {object} options other parameters.
 */
export const updateObjectStateId = (
  resource,
  id,
  newStateId,
  previousData,
  basePath,
  options = {}
) => ({
  type: CRUD_UPDATE,
  payload: {
    id,
    data: { object_state_id: newStateId },
    previousData,
  },
  meta: {
    resource: resource,
    fetch: OBJECT_STATE_ID_UPDATE,
    basePath,
    onSuccess: {
      notification: {
        body: options.notification_success || 'ra.notification.updated',
        level: 'info',
        messageArgs: {
          smart_count: 1,
        },
      },
      redirectTo: options.redirectTo || false,
      basePath,
      refresh: options.refresh || true,
    },
    onFailure: {
      notification: {
        body: options.notification_error || 'ra.notification.http_error',
        level: 'warning',
      },
    },
  },
});
