import { createStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Link } from 'react-admin';

const styles = createStyles(theme => ({
  marginTop: {
    marginTop: '1em',
  },
}));

const AddCandidateCustomerButton = ({ classes, label, record }) => (
  <Button
    className={classes.marginTop}
    component={Link}
    label={label}
    to={{
      pathname: '/candidate_customer/create',
      state: { record: { candidate_id: record.id } },
      search: `?candidate_id=${record.id}`,
    }}
  >
    <AddIcon />
  </Button>
);

AddCandidateCustomerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  record: PropTypes.object.isRequired,
};

AddCandidateCustomerButton.defaultProps = {
  label: 'form.candidate.add_candidate_customer_button',
};

export default withStyles(styles)(AddCandidateCustomerButton);
