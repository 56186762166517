import { maxLength, maxValue, minValue, number } from 'react-admin';
import { date, notEmpty } from './validators';

export default {
  object_state_id: [number()],
  customer_id: [notEmpty(), number()],
  quotation_number: [notEmpty(), maxLength(64)],
  quotation_comments: [maxLength(512)],
  ordered_at: [date()],
  offer_registred: [number()],
  use_deposit: [number()],
  deposit_payed: [number()],
  deposit_invoice_number: [notEmpty(), maxLength(32)],
  invoices: [],
  job_title: [notEmpty(), maxLength(256)],
  job_qualification: [number()],
  job_start_date: [date()],
  salary_min: [notEmpty(), number(), minValue(0), maxValue(999999999)],
  salary_max: [number(), minValue(0), maxValue(999999999)],
  location: [notEmpty(), maxLength(256)],
  cancel_date: [date()],
  cancel_reason: [maxLength(256)],
  upload_files: [],
};
