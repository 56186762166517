import React from 'react';
import { Filter, SearchInput } from 'react-admin';

const ProfessionalPositionFilter = props => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
);

export default ProfessionalPositionFilter;
