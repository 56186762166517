import { DOMAINS_LEVEL, DOMAINS_LEVELS } from './tag';

const OBJECT_STATE_ID = Object.freeze({
  DRAFT: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  CANCELLED: 3,
});

export class Order {
  static OBJECT_STATE_ID = OBJECT_STATE_ID;

  static ORDER_STATE_ID = Object.freeze({
    values: [
      {
        id: OBJECT_STATE_ID.DRAFT,
        name: 'form.order.state.draft',
        color: 'misty',
      },
      {
        id: OBJECT_STATE_ID.IN_PROGRESS,
        name: 'form.order.state.inProgress',
        color: 'orange',
      },
      {
        id: OBJECT_STATE_ID.DONE,
        name: 'form.order.state.done',
        color: 'lime',
      },
      {
        id: OBJECT_STATE_ID.CANCELLED,
        name: 'form.order.state.cancelled',
        color: 'red',
      },
    ],
    default: 0,
  });

  static DOMAINS_LEVELS = DOMAINS_LEVELS;
  static DOMAINS_LEVEL = DOMAINS_LEVEL;
}
