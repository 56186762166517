import { createStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { cloneDeep, get, set } from 'lodash';
import PropTypes from 'prop-types';
import React, { Children, cloneElement, PureComponent } from 'react';
import { ArrayField, ChipField, SingleFieldList } from 'react-admin';

const styles = createStyles(theme => ({
  row: { flexDirection: 'row' },
  column: { flexDirection: 'column' },
}));

const sanitizeRestProps = ({ allowEmpty, translateChoice, ...rest }) => rest;

/**
 * Usage:
 *     <RepeatedStringField source="tags" />
 *
 * Override component used to represent a child item.
 *     <RepeatedStringField source="tags">
 *         <TextField />
 *     </RepeatedStringField>
 */
class RepeatedStringField extends PureComponent {
  /**
   * Format record from strings list to objects list, to be readable by SingleFieldList.
   * @returns {object} the new record.
   */
  formatRecord = () => {
    const { childrenSource, record, source } = this.props;

    const newRecord = cloneDeep(record);
    const initialValue = get(record, source) || [];
    set(newRecord, source, initialValue.map(value => ({ [childrenSource]: value })));
    return newRecord;
  };

  render() {
    const { children, childrenSource, classes, className, orientation, ...props } = this.props;

    return (
      <ArrayField
        {...sanitizeRestProps(props)}
        record={this.formatRecord()}
        className={classnames(orientation === 'row' ? classes.row : classes.column, className)}
      >
        <SingleFieldList>
          {cloneElement(Children.only(children), { source: childrenSource })}
        </SingleFieldList>
      </ArrayField>
    );
  }
}

RepeatedStringField.propTypes = {
  children: PropTypes.element.isRequired,
  childrenSource: PropTypes.string.isRequired,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  orientation: PropTypes.oneOf(['row', 'column']),
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

RepeatedStringField.defaultProps = {
  children: <ChipField />,
  childrenSource: 'name',
  linkType: false,
  orientation: 'row',
};

export default withStyles(styles)(RepeatedStringField);
