import PropTypes from 'prop-types';
import React from 'react';
import { Labeled, LinearProgress } from 'react-admin';
import ReferenceError from './ReferenceError';

/*
 * ===================================
 * Identical to react-admin ReferenceArrayInputView.
 * @see https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/ReferenceArrayInput.js
 * ===================================
 */

const sanitizeRestProps = ({
  alwaysOn,
  basePath,
  component,
  crudGetMany,
  crudGetMatching,
  defaultValue,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  locale,
  meta,
  optionText,
  optionValue,
  perPage,
  record,
  referenceSource,
  resource,
  allowEmpty,
  source,
  textAlign,
  translate,
  translateChoice,
  ...rest
}) => rest;

export const ReferenceArrayInputView = ({
  allowEmpty,
  basePath,
  children,
  choices,
  className,
  error,
  input,
  isLoading,
  isRequired,
  label,
  meta,
  onChange,
  options,
  resource,
  setFilter,
  setPagination,
  setSort,
  source,
  translate,
  warning,
  ...rest
}) => {
  const translatedLabel = translate(label || `resources.${resource}.fields.${source}`, {
    _: label,
  });

  if (isLoading) {
    return (
      <Labeled
        label={translatedLabel}
        source={source}
        resource={resource}
        className={className}
        isRequired={isRequired}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  if (error) {
    return <ReferenceError label={translatedLabel} error={error} />;
  }

  return React.cloneElement(children, {
    allowEmpty,
    basePath,
    choices,
    className,
    error,
    input,
    isRequired,
    label: translatedLabel,
    meta: {
      ...meta,
      helperText: warning || false,
    },
    onChange,
    options,
    resource,
    setFilter,
    setPagination,
    setSort,
    source,
    translateChoice: false,
    limitChoicesToValue: true,
    ...sanitizeRestProps(rest),
    ...children.props,
  });
};

ReferenceArrayInputView.propTypes = {
  allowEmpty: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element,
  choices: PropTypes.array,
  className: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  warning: PropTypes.string,
};
