import React from 'react';
import { Pagination } from 'react-admin';

export const defaultPerPage = 25;
const resultsPerPage = [defaultPerPage, 50, 100];

export const DefaultPagination = props => (
  <Pagination rowsPerPageOptions={resultsPerPage} {...props} />
);

export default DefaultPagination;
