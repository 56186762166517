export default {
  forgotten_password: 'Mot de passe oublié ?',
  forgotten_password_form: {
    title: 'Réinitialisation du mot de passe',
    description:
      "Veuillez indiquer l'adresse email que vous utilisez pour vous connecter. Vous recevrez un email permettant de réinitialiser votre mot de passe.",
    submit: 'Réinitialiser',
  },
  forgotten_password_success:
    'La demande de réinitialisation a été prise en compte. Vérifiez vos emails.',
  reset_password_form: {
    title: 'Réinitialisez votre mot de passe',
    description: 'Saisissez un nouveau mot de passe pour votre compte utilisateur.',
    password_confirmation: 'Confirmation du mot de passe',
    submit: 'Réinitialiser',
  },
  reset_password_success: 'Le mot de passe a été réinitialisé',
  reset_password_error: 'Erreur lors de la réinitialisation du mot de passe',
};
