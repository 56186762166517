import ReportIcon from '@material-ui/icons/ViewList';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { executeManyMethodPost } from '../../../actions/methodActions';

const styles = theme =>
  createStyles({
    root: {
      color: theme.palette.type === 'dark' && theme.palette.text.primary,
    },
  });

class CustomerReportingBulkButton extends React.Component {
  /**
   * Generate customer reportings when user clicked on the button.
   */
  handleClick = () => {
    const { basePath, executeManyMethodPost, resource, selectedIds } = this.props;

    executeManyMethodPost(
      'generate_reporting',
      resource,
      selectedIds,
      {},
      {},
      basePath,
      '/reporting',
      {
        notification_success: 'notification.created_many',
      }
    );
  };

  render() {
    const { classes, label } = this.props;

    return (
      <Button className={classes.root} label={label} onClick={this.handleClick}>
        {this.props.icon}
      </Button>
    );
  }
}

CustomerReportingBulkButton.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string,
};

CustomerReportingBulkButton.defaultProps = {
  icon: <ReportIcon />,
  label: 'action.create_reporting',
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(
    undefined,
    { executeManyMethodPost }
  )
);

export default enhance(CustomerReportingBulkButton);
